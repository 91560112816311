<div class="card p-2">
    <div class="player" *ngIf="config">
        <div class="img">
            <div class="timeInterval" *ngIf="from && to"> {{getTime(from) | date: 'HH:mm:ss' : zone}} - {{getTime(to) | date: 'HH:mm:ss' : zone}}</div>
            <!--<div class="timeInterval" *ngIf="from && to"> {{getTime(from) | date: 'HH:mm:ss' : zone}} - {{getTime(to) | date: 'HH:mm:ss' : zone}}</div>-->
            <div class="text-center" *ngIf="hitmapUrl">
                <img *ngIf="!errorImageMessage" src="{{hitmapUrl}}" (load)="loadImg($event)" (error)="errorImege($event)" >
                <div *ngIf="errorImageMessage" class="alert alert-danger">{{errorImageMessage}}</div>
            </div>
            <app-loader *ngIf="loading"></app-loader>
        </div>
        <div class="control">
            <div class="timeline" #timeline [id]="'playerTime_' + id">
                <div #intervaDrop class="interval"></div>
            </div>
            <div class="left-right btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-secondary btn-sm" (click)="step(-1)"> < </button>
                <button type="button" class="btn btn-secondary btn-sm" (click)="step(1)"> > </button>
            </div>
            <div class="time start">{{getTime(start) | date : 'HH:mm:ss' : zone}}</div>
            <div class="time end">{{getTime(end) | date : 'HH:mm:ss' : zone}}</div>
            <div *ngIf="hasProjection" class="d-flex justify-content-center">

                <div class=" form-check m-2">
                    <input class="form-check-input"
                           type="checkbox"
                           [checked]="localConfig.highlight_dots"
                           [id]="'heatmap_highlight_dots_' + id"
                           (change)="changeParams($event, 'highlight_dots')">
                    <label class="form-check-label" [for]="'heatmap_highlight_dots_' + id" >
                        Highlight dots
                    </label>
                </div>

                <div class="form-check m-2">
                    <input class="form-check-input"
                           type="checkbox"
                           [checked]="localConfig.projection_source"
                           [id]="'heatmap_projection_source_' + id"
                           (change)="changeParams($event, 'projection_source')">
                    <label class="form-check-label" [for]="'heatmap_projection_source_' + id" >
                        Projection source
                    </label>
                </div>


            </div>
        </div>
    </div>
</div>
