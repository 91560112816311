import {CoreBaseService} from '@app/_core/base/service';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {Model} from '@app/_models';
import {DialogService} from '@app/_services';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TemplateRef} from '@angular/core';

export class CoreBaseItemConroller <T extends Model> {

    api: CoreBaseService<any>;
    aRoute: ActivatedRoute;
    router: Router;
    dialogService: DialogService;
    data: T;

    loading = false;
    routerItemId = 'itemId';
    error:  any;
    modalRef: BsModalRef;
    saving = false;

    constructor(api, aRoute, router, dialogService?: DialogService,
                // modalService?: BsModalService
    ) {
        this.api = api;
        this.aRoute = aRoute;
        this.router = router;
        this.dialogService = dialogService;
        // this.modalService = modalService;
    }

    init() {
        this.error = null;
        this.aRoute.paramMap.subscribe(params => {
            this.getItemData( params.get( this.routerItemId ) );
        });
    }

    getItemData( itemId? , update?) {
        if ( itemId === 'create' ) {
            this.setItem();
            this.asForm();
            this.edit(true);
        } else {
            this.loading = !update ? true : false;
            this.error = null;
            this.api.getById( itemId )
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    })
                )
                .subscribe(
                    (r) => {
                        this.setItem(r);
                    },
                    (err) => {
                        this.error = err;
                        this.setError(err);
                    }
                );
        }
    }

    asForm() {}

    edit(v = false) {
        this.saving = false;
        this.data.edit = v;
    }

    setItem( data? ) {}

    setError( error? ) {}

    save( data? ) {
        data = data ? data : this.data;
        this.loading = true;
        this.error = null;
        this.api.save( data )
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                (r) => {
                    this.setItem(r);
                    this.saveSuccess(r);
                },
                (err) => {
                    this.error = err;
                    this.saveError(err);
                }
            );
    }

    delete() {
        this.loading = true;
        this.error = null;
        this.api.delete(this.data.id)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }
            ))
            .subscribe(
                r => {
                    this.deleteSucces(r);
                },
                err => {
                    this.setError(err);
                    this.error = err;
                }
            );
    }

    deleteConfirm(event = {}, title = 'Delete user', mess = 'Are you sure want to delete this user') {

        if ( this.dialogService ) {

            this.dialogService.confirmShow( title, `${mess} ${this.data.name}`)
                .subscribe( r => {
                    if (r) {
                        this.delete();
                    }
                });
        }
    }

    openModal (template: TemplateRef<any>) {
        if ( this.dialogService ) {
            this.modalRef = this.dialogService.templateShow(template);
        }
    }

    goto(data?) {
        const params = ['..'];
        if ( data ) { params.push(data.id); }
        this.router.navigate(params, {relativeTo: this.aRoute});
    }

    saveSuccess(r?) {
        this.api.extAction.next({type: 'updateList'});
    }

    saveError(err?) {}

    deleteSucces(r?) {
        this.api.extAction.next({type: 'updateList'});
        this.goto();
    }

    deleteError() {}

    canselEdit() {
        if ( this.data.new ) {
            this.goto();
        } else {
            this.edit(false);
        }
    }
}
