import {Model} from './model';

export class CurrentUser extends Model {
    id: number;
    name: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token: string;
    admin: boolean;
    user: string;
    public: string;
}
