<div *ngIf="data && data.inferenceStreams && data.inferenceStreams.length; else listEmpty ">
    <div *ngFor="let img of data.activeStreams" class="card p-2">
    <!--<div *ngFor="let img of data.inferenceStreams" class="card p-2">-->
        <h3>Stream {{img.display_name || img.key}}</h3>
        <div class="d-flex flex-column justify-content-center" *ngIf="img.source_type == 'file'">
            <h4>Source videos</h4>
            <!--<div class="mb-3">-->
                <!--<label for="formFile" class="form-label">Video file</label>-->
                <!--<input class="form-control" type="file" id="formFile" (change)="uploadVideoFile($event, img.key)">-->
            <!--</div>-->
            <table *ngIf="vildeoList && vildeoList[img.key]" class="table">
                <thead>
                <tr>
                    <th>name</th>
                    <th>update</th>
                    <th>start</th>
                    <th>end</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let v of vildeoList[img.key]">
                    <td style="vertical-align: middle;">{{v.file_name}}</td>
                    <td style="vertical-align: middle;">{{v.upload_time | date:'short'}}</td>
                    <td style="vertical-align: middle;">{{v.start_time | date:'short'}}</td>
                    <td style="vertical-align: middle;">{{v.end_time | date:'short'}}</td>
                    <td><button class="btn" (click)="playVideo(img.key, v)">play</button> </td>
                </tr>
                </tbody>

            </table>
            <div class="form-inline justify-content-center">
                <label class="mr-2">Add video</label>
                <div>

                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="customFile" (change)="uploadVideoFile($event, img.key)">
                        <label class="custom-file-label" for="customFile">Choose video file</label>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="uploadingError">{{uploadingError.error.message}}</div>
        </div>
        <!--<div *ngIf="data.type == 'videofile'">-->
            <!--<label>Video file</label>-->
            <!--<input type="file" (change)="uploadVideoFile($event, img.key)">-->
        <!--</div>-->

        <div class="d-flex justify-content-center" *ngIf="videoUrl" >

            <div class=" m-3" >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{aVideo.file_name}}</h5>
                        <button type="button" class="close" (click)="videoUrl = null">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <video controls>
                            <source [src]="videoUrl">
                        </video>
                    </div>

                </div>
            </div>
        </div>


        <!--<div *ngIf="videoUrl" class="d-flex flex-column">-->
            <!--<div class="w-auto m-3">-->

                <!--<div class="d-flex justify-content-between">-->
                    <!--<div></div>-->
                    <!--<button class="btn " (click)="videoUrl = null">close</button>-->
                <!--</div>-->
                <!--<video controls>-->
                    <!--<source [src]="videoUrl">-->
                <!--</video>-->
            <!--</div>-->
        <!--</div>-->

        <div *ngIf="(!img.params || !img.params.archive); else linkVideo">
            <h4>{{img.display_name || img.key}}</h4>
            <div class="text-center">
                <img [src]="getLink(img, ts)" style="width: 640px; max-width: 100%;" >
            </div>
        </div>
        <ng-template #linkVideo>
            <h4><a [href]="img.params.archive" target="_blank">{{img.params.archive}}</a></h4>
            <video class="w-100" controls="controls">
                <source [src]="img.params.archive">
            </video>
        </ng-template>
    </div>
    <app-loader  *ngIf="loading" [progress]="uploadProgress" [action]="'uploading'"></app-loader>
</div>



<ng-template #listEmpty><div class="alert alert-secondary">Stream empty</div></ng-template>
