import {Model } from './model';
import {PaginateRequest } from './paginate-request';

import {Validators} from '@angular/forms';

export class GroupModel extends Model {
    id: number;
    display_name:	string; // example: Displayed Group Name
    manage_token:	string;
    organization_name:	string; //example: group_name
    read_token:	string;

    formConfig(): any {
        return {
            display_name: [this.display_name, Validators.required]
        };
    }

    toJSON(): any {
        return {
            id: this.id,
            display_name: this.display_name,

        };
    }

}

export class GroupModelDealer extends GroupModel {
    id: number;
    display_name:	string; // example: Displayed Group Name
    manage_token:	string;
    organization_name:	string; //example: group_name
    read_token:	string;

    formConfig(): any {
        return {
            display_name: [this.display_name, Validators.required],
            manage_token: [this.manage_token],
            organization_name: [this.organization_name, Validators.required],
            read_token: [this.read_token]
        };
    }

    toJSON(): any {
        return {
            id: this.id,
            display_name: this.display_name,
            manage_token: this.manage_token,
            organization_name: this.organization_name,
            read_token: this.read_token
        };
    }

}

export class GroupModelList extends PaginateRequest<GroupModel> {}
