import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServingPlayerComponent } from './player/player.component';
import { ServingPlayerProgressComponent } from './player/progress/progress.component';
import {CoreModule} from '@app/_core/core.module'

@NgModule({
  declarations: [
    ServingPlayerComponent,
    ServingPlayerProgressComponent
  ],
  imports: [
    CommonModule,
    CoreModule
  ],
  exports: [ServingPlayerComponent]
})
export class ServingModule { }
