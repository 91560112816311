<div class="card">
    <div class="card-header d-flex flex-row justify-content-between">
        <div>Stream params</div>
        <button (click)="addStream(null, $event)" class="btn btn-primary">+ add key</button>
    </div>
    <tabset #staticTabs class="m-1">
        <tab
                *ngFor="let param of getControls(); let i = index;"
                heading="{{param.controls.key.value}}"
                [removable]="f.streams.controls.length > 1"
                (removed)="deleteRow('streams', i)"
        >
            <div >

                <div [formGroup]="param"  class="card-body" >

                    <div class="form-check">
                        <input class="form-check-input"
                               type="checkbox"
                               [id]="'disabled' + i"
                               [formControlName]="field('disabled', param) "
                               (blur)="apply()"
                        >
                        <label class="form-check-label" [for]="'disabled' + i">
                            Disabled
                        </label>
                    </div>

                    <!--<div class="form-check" >-->
                        <!--<label class="" [for]="'disabled' + i">-->
                            <!--Disabled-->
                        <!--</label>-->
                        <!--<div class='d-flex align-items-center'>-->
                            <!--<input class=""-->
                                   <!--type="checkbox"-->
                                   <!--[formControlName]="field('disabled', param) "-->
                                   <!--[id]="'disabled' + i"-->
                                   <!--(blur)="apply()"-->
                            <!--&gt;-->
                        <!--</div>-->
                    <!--</div>-->


                    <div class="form-group row">
                        <label for="streamParamskey" class="col-sm-2 col-form-label">Key</label>
                        <input type="text"
                               formControlName="key"
                               id="streamParamskey"
                               class="form-control col-sm-10"
                               [ngClass]="{ 'is-invalid': submitted && param.controls.key.errors }"
                            (blur)="apply()"
                        />
                        <div *ngIf="submitted && param.controls.key.errors" class="invalid-feedback">
                            <div *ngIf="param.controls.key.errors.required">output is required</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="streamParamsType" class="col-sm-2 col-form-label">Source type</label>
                        <select
                                [formControlName]="field('source_type', param) "
                               id="streamParamsType"
                               class="form-control col-sm-10"
                               [ngClass]="{ 'is-invalid': submitted && param.controls.key.errors }"
                                (blur)="apply()"
                                (change)="apply()"
                               required
                        >
                            <option *ngFor="let v of types" [value]="v">{{v}}</option>
                        </select>
                        <div *ngIf="submitted && param.controls.source_type.errors" class="invalid-feedback">
                            <div *ngIf="param.controls.source_type.errors.required">type is required</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="streamParamskey" class="col-sm-2 col-form-label">Display name</label>
                        <input type="text"
                               [formControlName]="field('display_name', param) "
                               id="streamParamskey"
                               class="form-control col-sm-10"
                               (blur)="apply()"
                        />
                        <!--<div *ngIf="submitted && param.controls.key.errors" class="invalid-feedback">-->
                            <!--<div *ngIf="param.controls.key.errors.required">output is required</div>-->
                        <!--</div>-->
                    </div>
                    <div class="form-group row">
                        <label for="streamParamsinput" class="col-sm-2 col-form-label">Input</label>
                        <input type="text" formControlName="input" id="streamParamsinput" class="form-control col-sm-10" (blur)="apply()"/>
                    </div>
                    <div class="form-group row">
                        <label for="streamParamsoutput" class="col-sm-2 col-form-label">Output</label>
                        <input type="text" formControlName="output" id="streamParamsoutput" class="form-control col-sm-10" (blur)="apply()"/>
                    </div>
                    <div class="form-group row">
                        <label for="streamParamsDesc" class="col-sm-2 col-form-label">Description</label>
                        <input type="text" formControlName="description" id="streamParamsDesc" class="form-control col-sm-10" (blur)="apply()" />
                    </div>
                    <app-form-controll-object formControlName="params" (change)="apply()"></app-form-controll-object>
                 </div>
            </div>
        </tab>
    </tabset>
</div>
