import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SettingsComponent } from './settings.component';
import { AppSettingsRoutingModule} from './settings.module.routing';
import { SettingsUsersComponent} from './users/users.component';
import { SettingsGroupsComponent} from './groups/groups.component';
import { SettingsUsersItemComponent} from './users/item/item.component';
import { SettingsGroupsItemComponent } from './groups/item/item.component';
import {CoreModule} from '@app/_core/core.module';

import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SettingsServingComponent } from './serving/serving.component';


@NgModule({
    declarations: [SettingsComponent, SettingsUsersComponent, SettingsGroupsComponent, SettingsUsersItemComponent, SettingsGroupsItemComponent, SettingsServingComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AppSettingsRoutingModule,
        CoreModule,
        FormsModule,
        BsDropdownModule.forRoot(),
        // BsDropdownModule
    ]
})
export class SettingsModule { }
