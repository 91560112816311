import {Model} from '@app/_models/model';
import {FormGroup, ValidationErrors, Validators} from '@angular/forms';

export class ServingsModel extends Model {
    config: any;
    containersCount: number;
    description:	string;
    disabled:	boolean;
    gpuUsed:	number;
    health:	string;
    inferenceForm:	any[];
    inferenceValues: any;
    inferenceStreams:	any[];
    name:	string;
    status:	string;
    group_id: number;
    organization_name:	string;
    type:	string;
    stream_type: string;
    public: boolean;
    display_name: string;
    streams_meta: any [];
    stream_url: string;

    key: string;

    stream_type_list = ['detector', 'heatmap', 'trackmap'];

    _configFilter: any;

    locaStorageVariable = 'streamConfig';

    constructor(props?) {
        super(props);
        if(!this.stream_type) {
            this.stream_type = this.stream_type_list[0];
        }
        let cf = this.getCF();
        this._configFilter = cf[this.id];
    }

    set_stream_type (data: string) {
        return data.toLowerCase();
    }

    formConfig(): any {
        return {
            display_name: [this.display_name, Validators.required],
            name: [this.name, [Validators.required, Validators.pattern("^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$")]],
            group_id: [this.group_id, Validators.required],
            inferenceValues: new FormGroup({}),
            type: [this.type, Validators.required],
            stream_type: [this.stream_type, Validators.required],
            stream_url: [this.stream_url],
            description: [this.description]
        };
    }

    toJSON(): any {
        return {
            config: this.config,
            containersCount: this.containersCount,
            description:	this.description,
            disabled:	this.disabled,
            gpuUsed:	this.gpuUsed,
            health:	this.health,
            inferenceForm:	this.inferenceForm,
            inferenceValues: this.inferenceValues,
            inferenceStreams:	this.inferenceStreams,
            name:	this.name,
            status:	this.status,
            group_id: this.group_id * 1,
            organization_name:	this.organization_name,
            type:	this.type,
            stream_type:	this.stream_type,
            public: this.public,
            display_name: this.display_name,
            stream_url: this.stream_url

        };
    }

    get activeStreams() {
        // debugger
        return this.inferenceValues.streams.filter((v) => { return v.source_type !=="file" && !v.disabled});
        // return this.inferenceStreams.filter( (v) => { return !v.disabled});
    }

    getCF() {
        let cl: any = localStorage.getItem(this. locaStorageVariable);
        if(cl) {
            cl = JSON.parse(cl);
        } else {
            cl = {};
        }
        return cl;
    }

    set configFilter(v) {
        let cl = this.getCF();
        this._configFilter = v;
        cl[this.id] = this._configFilter;
        localStorage.setItem( this.locaStorageVariable, JSON.stringify(cl));
    }

    get configFilter () {
        return this._configFilter;
    }
}

export class ServingsModelList extends Model {

    items: ServingsModel[];
    options: any;
    error: any;
}

export class ServingForm extends Model {
    // form: any;
    defaultValues: any;
}

export class ServingsStreamFileModel extends Model {
    id:             number;
    file_name:      string;
    Serving:        any; // *Serving json:"-" gorm:"ForeignKey:ID;AssociationForeignKey:ServingId;save_associations:false"
    serving_id:	    string;
    status:	        string;
    status_error:	string;
    upload_options:	any; //{...}
    upload_time:	string;

    _apiUrl =  (window as any).externalConfig.apiUrl;

    get linkDownload() {
        return `${this._apiUrl}serving/${this.serving_id}/files/${this.id}/get`;
    }
}


