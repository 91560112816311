import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SettingsComponent} from './settings.component';
import { SettingsUsersComponent} from '@app/settings/users/users.component';
import {SettingsUsersItemComponent} from '@app/settings/users/item/item.component';
import {SettingsGroupsComponent} from '@app/settings/groups/groups.component';
import {SettingsGroupsItemComponent} from '@app/settings/groups/item/item.component';
import {SettingsServingRegisterService} from '@app/_services/serving.register.service';
import {SettingsServingComponent} from '@app/settings/serving/serving.component';
// import {ServingsItemComponent} from './servings/item/item.component';
// import {ServingsItemFormComponent} from '@app/servings/item/form/form.component';
// import {ServingsItemStreamsComponent} from '@app/servings/item/streams/streams.component';
// import {DashboardsComponent} from '@app/dashboards/dashboards.component';
// import {ServingsItemLogComponent} from '@app/servings/item/log/log.component';
// import {ServinsItemDebugComponent} from '@app/servings/item/debug/debug.component';
// import { ServinsItemDashboardComponent} from './item/dashboard/dashboard.component';

export const routes: Routes = [
    // {
    //     path: '',
    //     component: SettingsComponent,
    // },
    {
        path: '',
        // path: ':itemId',
        component: SettingsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'users'
            },
            {
                path: 'users',
                component: SettingsUsersComponent,
                children: [
                    {
                        path: ':itemId',
                        component: SettingsUsersItemComponent
                    }
                ]
            },
            {
                path: 'groups',
                component: SettingsGroupsComponent,
                children: [
                    {
                        path: ':itemId',
                        component: SettingsGroupsItemComponent
                    }
                ]
            },
            {
                path: 'servings',
                component: SettingsServingComponent,
                // children: [
                //     {
                //         path: ':itemId',
                //         component: SettingsGroupsItemComponent
                //     }
                // ]
            },
            // {
            //     data: {
            //         parent: {type: 'serving', param: 'itemId'}
            //     },
            //     path: 'dashboard',
            //     component: ServinsItemDashboardComponent
            //     // component: DashboardsComponent
            // },
            // {
            //     path: 'streams',
            //     component: ServingsItemStreamsComponent
            // },
            // {
            //     path: 'log',
            //     component: ServingsItemLogComponent
            // },
            // {
            //     path: 'report',
            //     component: ServinsItemDebugComponent
            // }
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes)],
    exports: [ RouterModule ]
})
export class AppSettingsRoutingModule {}
