import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {ApiModel} from './_api_model';

export class Event extends ApiModel {
  id: string;
  title: string;
  start: any;
  end: any;

  apiPref = 'event';

  private _form: FormGroup;

  constructor ( data?:any ) {
    super();
    if ( data ) {
      Object.assign(this, data);
    }
    this.start = this.start ? new Date(this.start) : new Date();
    this.end = this.end ? new Date(this.end) : new Date();
  }

  formConfig() {
    return {
      title: [ this.title, Validators.required ],
      // start: [ new FormControl(new Date(this.start).toISOString().slice(0, -1)) ],
      start: [ this.start ],
      end: [ this.end ]
    };
  }

  getForm(): FormGroup {
    if ( !this._form )
      this._form = new FormBuilder().group(this.formConfig());
    return this._form;
  }

  updateDataForm() {
    for (let key in this._form.value) {
      this[key] = this._form.value[key];
    }
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      start: new Date(this.start).toISOString(),
      end: new Date(this.end).toISOString()
    };
  }
}
