import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { DatePipe } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import {DashboardsService} from './dashboards.service';
import pluginDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as _ from 'underscore';
import {finalize} from 'rxjs/operators';

import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {from} from 'rxjs';
// import {defineLocale} from 'ngx-bootstrap/bs-moment';
// import {}
// import {fr} from 'ngx-bootstrap/locale';

// interface Interface {
//
// }
interface  DashboardConfig {
	interval: string;
	from: string;
	to: string;
	serving: string[];
	streams: string[];
	start: any;
	type: any;
}


@Component({
	selector: 'app-dashboards',
	templateUrl: './dashboards.component.html',
	providers:[DatePipe]
})
export class DashboardsComponent implements OnInit, OnDestroy {

	public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
	@Input() parent: any;
	@Input() parentName: any;
	@Input() config: any;
	@Input() type: any;
	@Input() streams: any[];

	@Input() configMetrics: DashboardConfig;

	@Output() change: EventEmitter<any> = new EventEmitter<any>();

	streamsList: any;

	dropdownSettings: IDropdownSettings = {
		singleSelection: false,
		idField: 'item_id',
		textField: 'item_text',
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		itemsShowLimit: 6,
		allowSearchFilter: true
	};
	groups: any;

	groupsSelected: any;
	metricsList = [];
	metricsListSelected = [];
	viewMetrics = {};
	loading = false;
	complite = false;

	mByType = {
		detector: {
			list: [
				// { item_id: 'app-dashboards-gender-age-count', item_text: 'age count' },
				{ item_id: 'app-dashboards-people-count-block', item_text: 'people count' },
				// { item_id: 'app-dashboards-gender-age-count-pie', item_text: 'age count pie' },
				// { item_id: 'app-dashboards-gender-age-timeline', item_text: 'age timeline' },
				// { item_id: 'app-dashboards-gender-age-timeline_group', item_text: 'age timeline group' },
				// { item_id: 'app-dashboards-gender-age-timeline-single', item_text: 'age timeline single' },
				// { item_id: 'app-dashboards-gender-age-attention-timeline', item_text: 'attention timeline' },
				// { item_id: 'app-dashboards-gender-age-attention-pie', item_text: 'attention pie' },
				// { item_id: 'Audience Attention Span to Advertisement', item_text: 'Audience Attention Span to Advertisement' },
				// { item_id: 'Audience Attention Span to Advertisement - Pie Chart', item_text: 'Audience Attention Span to Advertisement - Pie Chart' },
				{ item_id: 'Traffic Timeline', item_text: 'Traffic Timeline' },
				// { item_id: 'Audience appearance - Pie chart', item_text: 'Audience appearance - Pie chart' },
				{ item_id: 'Audience appearance', item_text: 'Audience appearance' },
				// { item_id: 'Traffic table', item_text: 'Traffic table' }
			],
			selected: [
				{ item_id: 'app-dashboards-people-count-block', item_text: 'People count' },
				// { item_id: 'app-dashboards-gender-age-count', item_text: 'age count' },
				// { item_id: 'app-dashboards-gender-age-count-pie', item_text: 'age count pie' },
				// { item_id: 'app-dashboards-gender-age-timeline_group', item_text: 'age timeline group' },
				// { item_id: 'app-dashboards-gender-age-timeline-single', item_text: 'age timeline single' },
				// { item_id: 'app-dashboards-gender-age-attention-timeline', item_text: 'attention timeline' },
				// { item_id: 'app-dashboards-gender-age-attention-pie', item_text: 'attention pie' },
				// { item_id: 'Audience Attention Span to Advertisement', item_text: 'Audience Attention Span to Advertisement' },
				// { item_id: 'Audience Attention Span to Advertisement - Pie Chart', item_text: 'Audience Attention Span to Advertisement - Pie Chart' },
				{ item_id: 'Traffic Timeline', item_text: 'Traffic Timeline' },
				// { item_id: 'Audience appearance - Pie chart', item_text: 'Audience appearance - Pie chart' },
				{ item_id: 'Audience appearance', item_text: 'Audience appearance' },
				// { item_id: 'Traffic table', item_text: 'Traffic table' }
				]
		},
		heatmap: {
			list: [
				{ item_id: 'app-dashboards-people-timeline', item_text: 'people timeline' },
				// { item_id: 'app-dashboards-heatmap-timeline', item_text: 'heatmap timeline' },

			],
			selected:[
				{ item_id: 'app-dashboards-people-timeline', item_text: 'people timeline' },
				// { item_id: 'app-dashboards-people-count', item_text: 'people count' },

				// { item_id: 'app-dashboards-heatmap-timeline', item_text: 'heatmap timeline' },

			]
		},
		trackmap: {
			list: [
				{ item_id: 'app-dashboards-people-timeline', item_text: 'people timeline' },
				// { item_id: 'app-dashboards-trckmap-timeline', item_text: 'trckmap timeline' },

			],
			selected:[
				{ item_id: 'app-dashboards-people-timeline', item_text: 'people timeline' },
				// { item_id: 'app-dashboards-trckmap-timeline', item_text: 'trckmap timeline' },

			]
		}
	};





	constructor(private api: DashboardsService, private aRoute: ActivatedRoute, private datePipe: DatePipe) {
	}

	ngOnInit(): void {
		if (this.streams) {
			this.streamsList = {};
			let hasStream = false;
			let sc = ( this.configMetrics && this.configMetrics.streams ) ? this.configMetrics.streams : [];
			let all = sc.length == 0 ? true : false;
			_.each(this.streams, (v) => {
				this.streamsList[v.key] = ( all || sc.indexOf(v.key) != -1 ) ? true : false;
				hasStream = true;
			});
			if(!hasStream) {
				delete this.streamsList;
			}
		}

		if(!this.type) {
			this.type = 'detector';
		}
		if (!this.config ) {
		    if(this.configMetrics) {
		        this.config = this.configMetrics;
            } else {
                this.config = {};
            }
        }
		if (this.parent && this.parent.groups) {
			this.groups = {};
			this.groupsSelected = {};
			_.each(this.parent.groups, (v: any, k) => {
				this.groups[k] = v.map((i) => { return {item_id: i.id, item_text: i.name}; });
				this.groupsSelected[k] = v.map((i) => { return {item_id: i.id, item_text: i.name}; });
			} );
		}

		this.metricsList = this.mByType[this.type].list;
		this.metricsListSelected = this.mByType[this.type].selected;

		this.setViewMetrics(this.metricsListSelected);

		let p = !this.parent ? this.aRoute.snapshot.data['parent'] : this.parent;
		let pref =  this.parentName;
		if ( p && ! pref ) {
			p['itemId'] = this.aRoute.parent.snapshot.params[p.param ];
			pref = (p.type && p.itemId) ? `${p.type}/${p.itemId}` : null;
		}
		this.api.setPref(pref);
	}

	streamChange(event, s) {
		this.streamsList[s.key] = event.currentTarget.checked;
		this.streamChangeAddConfig();
	}

	streamChangeAddConfig() {
		if(this.streamsList) {

			let sl = new Array();
			_.each(this.streamsList, (v, k) => {
				if (v) {
					sl.push(k);
				}
			});
			sl.push('____empty____');
			this.configMetrics.streams = sl;
		}
	}

	setViewMetrics(list?) {
		let l = list ? list : this.metricsListSelected;
		this.viewMetrics = {};
		for ( let item of l) {
			this.viewMetrics[item.item_id] = item.item_text;
		}
	}

	ngOnDestroy() {}

	setConfig(ev) {
		// debugger
		this.configMetrics = _.clone(ev.data);
		this.streamChangeAddConfig();
        this.change.emit({action: 'configMetrics', data: this.configMetrics});
    }
}
