<div class="flex-column" *ngIf="pieData">
    <h5>{{title}}</h5>
    <div class="col" >
        <div class="card mb-0"  >
            <!--<div class="card-header">-->
                <!--Attention {{pieData.count || 0}}-->
            <!--</div>-->
            <div class="card-body">
                <div class="chart-wrapper">
                    <canvas
                            style="min-height: 150px"
                            baseChart class="chart"
                            [data]="pieData.data"
                            [labels]="pieLabels"
                            [options]="pieOptionsMale"
                            [chartType]="pieChartType"
                            (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="small mb-3">
        {{desc}}
        <!--The attention span of the audience who are looking at a set advertisement measured by 3 variables, less than 10 seconds, 10-20 seconds, and more than 20 seconds. this pie chart is a total count of the recorded attention span.-->
    </div>
</div>
<app-loader *ngIf="loading" class="over"></app-loader>
