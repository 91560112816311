<div *ngIf="data && !data.edit ">
    <div class="clearfix">
        <h4 class="float-left">User {{data.name}} ({{data.login}})</h4>
        <button class="float-right btn btn-default" (click)="edit(true)">edit</button>
        <button class="float-right btn btn-default" (click)="deleteConfirm()">delete</button>
    </div>

    <div class="align-items-center d-flex justify-content-between mb-2">
        <h6 class="float-left">User groups</h6>
        <button class="btn btn-sm  ml-3" (click)="openModal(addGroup)">add group</button>
    </div>
    <app-settings-groups  [params]="{for_user: data.id}" [action]="{type: 'delete', data: data.id}"></app-settings-groups>


    <ng-template #addGroup>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add user to group</h5>
            <button type="button" class="close" (click)="modalRef.hide()" >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-settings-groups  [params]="{not_for_user:data.id}" [action]="{type: 'add', data: data.id}"></app-settings-groups>
        </div>
    </ng-template>

</div>
<div *ngIf="data && data.edit">
    <h5>{{data.new ? 'Create' : 'Edit' }} user</h5>
    <form [formGroup]="data.form" (ngSubmit)="saveData()">
        <div class="form-group row">
            <label class="col-sm-2 col-form-label col-form-label-sm">Login</label>
            <div class="col-sm-10">
                <input class="form-control form-control" formControlName="login" required>
                <div *ngIf="data.form.controls.login.invalid && saving"
                     class="alert alert-danger">
                    <div *ngIf="data.form.controls.login.errors.required">
                        login is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label col-form-label-sm">Name</label>
            <div class="col-sm-10">
                <input class="form-control form-control" formControlName="name" required>
                <div *ngIf="data.form.controls.name.invalid && saving"
                     class="alert alert-danger">
                    <div *ngIf="data.form.controls.name.errors.required">
                        name is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label col-form-label-sm" >Password</label>
            <div class="col-sm-10">
                <input  class="form-control form-control" type="password" formControlName="password" required>
                <div *ngIf="data.form.controls.password.invalid && saving"
                     class="alert alert-danger">
                    <div *ngIf="data.form.controls.password.errors.required">
                        password is required.
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="form-group">-->
            <!--<label>service</label>-->
            <!--<input formControlName="service">-->
        <!--</div>-->

        <div class="alert alert-danger" *ngIf="error" >
            {{error | json}}
        </div>

        <div class="d-flex justify-content-end">
            <button (click)="canselEdit()" class="btn btn-default">cancel</button>
            <button type="submit" class="btn btn-primary">{{data.new ? 'create' : 'save' }}</button>
        </div>
    </form>
</div>
<app-loader *ngIf="loading" class="over"></app-loader>

