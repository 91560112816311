<div class="card">
    <div class="card-body">
        <h4 class="card-header">Settings</h4>
        <ul class="nav nav-tabs d-block mt-3 ">
            <li class="nav-item">
                <a [routerLink]="['users']" class="nav-link" [routerLinkActive]="'active'">Users</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['groups']" class="nav-link" [routerLinkActive]="'active'">Groups</a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['servings']" class="nav-link" [routerLinkActive]="'active'">Servings</a>
            </li>
        </ul>

        <div class="m-3">
            <router-outlet ></router-outlet>
        </div>
    </div>
</div>
