export class VideoFileTimelineInterval {
  id: any;
  class: any;
  start: number;
  end: number;
  value: any;
}

export class VideoFileTimeline {
  id: any;
  name: string;
  title: string;
  timeline: VideoFileTimelineInterval[];
  order: any;
}
