import {Component, OnInit, TemplateRef} from '@angular/core';
import {CoreBaseListConroller} from '@app/_core/base/list-controller';
import {ServingRegisterModel, ServingRegisterAssignModel} from '@app/_models';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsGroupsService} from '@app/_services/groups.service';
import {SettingsServingRegisterService} from '@app/_services/serving.register.service';
import {DialogService} from '@app/_services';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-serving',
  templateUrl: './serving.component.html',
  styleUrls: ['./serving.component.scss']
})
export class SettingsServingComponent extends CoreBaseListConroller<ServingRegisterModel> implements OnInit {
    api: SettingsServingRegisterService;
    assModel: ServingRegisterAssignModel;
    groupLoading: boolean;
    groups: any[];
    assignDataLoading: boolean;

  constructor(
      api: SettingsServingRegisterService,
      aRoute: ActivatedRoute,
      router: Router,
      dialogService: DialogService,
      private  groupsApi: SettingsGroupsService
  ) {
    super(api, aRoute, router, dialogService);
  }

    ngOnInit(): void {
        super.ngOnInit();
        this.getGroups();
    }

    assignDataForm(item: any, template: TemplateRef<any>) {
      this.assModel = new ServingRegisterAssignModel({channel_name: item.name, name: item.name});
      this.openModal(template);
    }

  getGroups() {
      this.groupLoading = true;
      this.groupsApi.getList()
          .pipe(
              finalize(() => {
                this.groupLoading = false;
              })
          )
          .subscribe(
          (r: any) => {
              this.groups = r.items;
          },
          (err) => {}
      )
  }

    assignData() {
      if (!this.assModel.form.valid) {
          return
      }
      this.assignDataLoading = true;
      this.assModel.updateDataForm();
      this.api.servingAssign(this.assModel)
          .pipe(
              finalize(() => {
                  this.assignDataLoading = false;
              })
          )
          .subscribe(
          (r) => {
              debugger;
              this.modalRef.hide();
              this.getList();
          },
        (err) => {debugger}
          );
    }

  actionDo(item, event) {
    event.stopPropagation();
    if(this.action) {
      switch (this.action.type) {
        case 'add':
          this.groupsApi.addUser(item.id, this.action.data)
              .subscribe(
                  (r) => {
                    this.api.extAction.next({type: 'updateList'});
                  },
                  (err) => {}
              );
          break;
        case 'delete':
          this.groupsApi.deleteUser(item.id, this.action.data)
              .subscribe(
                  (r) => {
                    this.api.extAction.next({type: 'updateList'});
                  },
                  (err) => {}
              );
          break;
      }
    }
  }

}
