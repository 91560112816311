import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "underscore";

@Pipe({ name: 'object' })
export class ObjectPipe implements PipeTransform {
    transform(val) {
        if (_.isArray(val))
            return val.join('; ');
        if ( _.isObject(val)){
            return val.toString();
        }
        return val;

    }
}
