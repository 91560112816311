import {Component, Input, OnInit} from '@angular/core';
import {DashboardsService} from '@app/dashboards/dashboards.service';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

import { DatePipe } from '@angular/common';
import {DashboardsBase} from '@app/dashboards/items/dashboards_base';

@Component({
    providers: [DatePipe],
    selector: 'app-dashboards-gender-age-timeline-single',
    templateUrl: './gender-age-timeline-single.component.html',
    styleUrls: ['./gender-age-timeline-single.component.scss']
})
export class GenderAgeTimelineSingleComponent extends DashboardsBase implements OnInit {

    graphData = {};
    graphLabel = [];
    listError: any;
    complite = false;

    constructor( api: DashboardsService, private datePipe: DatePipe) {
        super(api);
    }

    ngOnInit() {
        this.init();
    }

    init() {
        // this.getDataIndex('gender-age-timeline', {interval: '5min'});
        this.getDataIndex('gender-age-timeline');
    }

    prepareResponce(r, index?, data?) {
        switch (index) {
            case 'gender-age-timeline':
                this.prepare(r);
                this.complite = true;
                break;
        }
    }

    prepare(r) {
        let index = {};
        let data = {};
        let label;
        for (let item of r.classes) {
            index[item.id] = item;
            let d = r.timeline.filter( i => {
                return i.class == item.id;
            });

            // console.info(item.id, d);

            if ( !data[item.group[0].id] ) data[item.group[0].id] = [];
            let count = 0;
            for (let v of d) count += v.value;

            data[item.group[0].id].push([{
                data: d.map( i => { return i.value; }),
                label: item.group[0].title + ' ' + item.group[1].title,
                count: count
            }]);
            label = d.map( i => this.datePipe.transform(new Date(i.startTime ), 'short'));
        }
        this.graphData = data;
        this.graphLabel = label;
    }

    // public  mainChartOptions = {
    //   responsive: true,
    //   legend: {
    //     display: true
    //   }
    //
    // };


    //
    // public mainChartOptions_: any = {
    //   tooltips: {
    //     enabled: false,
    //     custom: CustomTooltips,
    //     intersect: true,
    //     mode: 'index',
    //     position: 'nearest',
    //     callbacks: {
    //       labelColor: function(tooltipItem, chart) {
    //         return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
    //       }
    //     }
    //   },
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   scales: {
    //     xAxes: [{
    //       gridLines: {
    //         drawOnChartArea: false,
    //       },
    //       ticks: {
    //         callback: function(value: any) {
    //           return value.charAt(0);
    //         }
    //       }
    //     }],
    //     yAxes: [{
    //       ticks: {
    //         beginAtZero: true,
    //         maxTicksLimit: 5,
    //         stepSize: Math.ceil(250 / 5),
    //         max: 250
    //       }
    //     }]
    //   },
    //   elements: {
    //     line: {
    //       borderWidth: 2
    //     },
    //     point: {
    //       radius: 0,
    //       hitRadius: 2,
    //       hoverRadius: 4,
    //       hoverBorderWidth: 3,
    //     }
    //   },
    //   legend: {
    //     display: false
    //   }
    // };

    public mainChartColours: Array<any> = [
        { // brandInfo
            backgroundColor: hexToRgba(getStyle('--info'), 10),
            borderColor: getStyle('--info'),
            pointHoverBackgroundColor: '#fff'
        },
        { // brandSuccess
            backgroundColor: 'transparent',
            borderColor: getStyle('--success'),
            pointHoverBackgroundColor: '#fff'
        },
        { // brandDanger
            backgroundColor: 'transparent',
            borderColor: getStyle('--danger'),
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5]
        }
    ];
    public mainChartLegend = true;
    public mainChartType = 'line';


    public lineChart2Options: any = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        plugins: {
            datalabels: {
                // color: 'white',
                display: false,
                font: {
                    weight: 'bold'
                },
                position: 'outside'
            }
        },
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                gridLines: {
                    color: 'transparent',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    fontSize: 2,
                    fontColor: 'transparent',
                }

            }],
            yAxes: [{
                display: false,
                ticks: {
                    display: false,
                    // min: 1 - 5,
                    // max: 34 + 5,
                }
            }],
        },
        elements: {
            line: {
                tension: 0.00001,
                borderWidth: 1
            },
            point: {
                radius: 2 ,
                hitRadius: 4,
                hoverRadius: 4,
            },
        },
        legend: {
            display: false
        }
    };
    public lineChart2Colours: Array<any> = [
        { // grey
            // backgroundColor: getStyle('--info'),
            backgroundColor: 'rgba(255,255,255,.35)',
            borderColor: 'rgba(255,255,255,.55)'
        }
    ];
    public lineChart2Legend = false;
    public lineChart2Type = 'line';

}
