import { Component, OnInit, Input } from '@angular/core';
export * from './item/item.component';
import {StreamService} from '@app/pages/stream/stream.service';
import {StreamBaseComponent} from './stream_base.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html'
})
export class StreamComponent extends StreamBaseComponent {

  constructor(api: StreamService, public router: Router, aRouter: ActivatedRoute) {
    super(api, router, aRouter);
  }

  // api: StreamService, private modalService: BsModalService, public router: Router, aRouter: ActivatedRoute) {
  // super(api, router, aRouter);

}
