import { Component, OnInit, OnDestroy, Input, TemplateRef } from '@angular/core';
import {PersonsService} from '@app/pages/persons/persons.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'underscore';
import {finalize} from 'rxjs/operators';
import {DialogService} from '@app/_services/dialog,service';


import 'brace';

import 'brace/mode/text';
import 'brace/theme/github';

import 'brace/theme/clouds';
import 'brace/mode/javascript';

import { AceComponent, AceDirective, AceConfigInterface } from 'ngx-ace-wrapper';


@Component({
	selector: 'app-person-item-meta',
	template: `
        <div class="d-flex justify-content-between mb-3" >
            <h4 class="col-auto pl-0">Info</h4>
            <button (click)="edit()" class="btn btn-secondary">Edit</button>
        </div>

        <div *ngIf="asEdit">
			<div style="min-height: 400px; border: 1px solid #ccc;">
                <ace
                        style="height: 398px;"
                        fxFlex="auto"
                        [config]="config"
                        [(value)]="sData">
                </ace>
            </div>
			<div class="d-flex justify-content-between">
				
				<div ></div>
				<div>
                    <button class="btn" (click)="edit()">cancel</button>
                    <button class="btn" (click)="save()">save</button>
                </div>
			</div>
			
        </div>

        <div *ngIf="data && !asEdit">
			<div *ngFor="let item of data | keyvalue" >
				<b>{{item.key}}</b>: {{item.value | object}}
			</div>
        </div>
		<div class="alert alert-warning" *ngIf="error">{{error}}</div>
	`,
})
export class PersonItemMetaComponent implements OnInit, OnDestroy {

	@Input() itemId: string;
	filterEvent: any;
	extAction = new Subject();
	paramsSubscription: Subscription;
	modalRef: BsModalRef;
	// selectedFile: ImageSnippet;
	imageError: any;
	loading = false;
	apiEvent: Subject<any>;
	error: any;

	data: any;
	sData = '';
	asEdit = false;
	public config: AceConfigInterface = {
		mode: 'text',
		theme: 'github',
		readOnly : false,
		focusTimeout: 100
	};

	constructor( private activatedRoute: ActivatedRoute, private api: PersonsService, router: Router, private modalService: BsModalService, private dialogService: DialogService) {
		this.itemId = activatedRoute.parent.snapshot.params.itemId;
		this.paramsSubscription = router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.init(true);
			}
		});
	}

	init(update) {

		if ( update ) { this.itemId = this.activatedRoute.parent.snapshot.params.itemId; }
		this.getData();
	}

	edit() {
		this.sData = JSON.stringify(this.data,null, '\t');
		this.asEdit = !this.asEdit;
	}

	save() {
		try {
			this.data = JSON.parse(this.sData);
			this.error = null;
			this.loading = true;
			this.api.setMeta(this.itemId, this.data)
				.pipe(
					finalize(() => {
						this.loading = false;
					})
				)
				.subscribe(
					r => {
						this.data = r;
						this.edit();
					},
					err => {
						this.error = err;
					}
				);
		} catch (e) {
			this.error = 'Error parse json format. For example {"nummber" : 1, "string" : "str", "array" : [ "string", 1 ] }';
		}
	}

	ngOnInit() {
		this.init( this.itemId ? false : true );
	}

	getData() {
		this.error = null;
		this.loading = true;
		this.api.getMeta(this.itemId)
			.pipe(
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe(
				r => {
					this.data = r;
				},
				err => {
					this.imageError.push({
							action: 'get list',
							error: err
						}
					);
				}
			);
	}

	ngOnDestroy(): void {
		this.paramsSubscription.unsubscribe();
	}

	deleteKey(key) {
		delete this.data[key];
	}
}
