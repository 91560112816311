import { Component, OnInit } from '@angular/core';
import {ServingsComponent} from '../../servings/servings.component';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-servings-main-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class ServingsMainDashboardComponent extends ServingsComponent implements OnInit {

  groupList = [];

  constructor(
      api: ServingsService,
      aRoute: ActivatedRoute,
      router: Router) {
    super(api, aRoute, router);
  }

  ngOnInit() {
    // this.getList();
  }

  prepareListResponce() {
    this.groupList = this.list.items.map( (i) => { return {id: i.id, name: i.name}; } );
  }

}
