import { Component, OnInit } from '@angular/core';
import {SettingsUsersService} from '@app/_services/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '@app/_services';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {GroupModel, GroupModelDealer} from '@app/_models';
import {SettingsGroupsService} from '@app/_services/groups.service';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ConfigService} from '@app/_services/config.service';
import * as _ from '@app/dashboards/filter/filter.component';
import has = Reflect.has;

@Component({
    selector: 'app-settings-groups-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
    // providers: [BsDropdownModule]
})
export class SettingsGroupsItemComponent extends CoreBaseItemConroller<GroupModel> implements OnInit{


    data: GroupModel;
    cloudDealer: any;
    hasConfig = false;

    constructor(
        api: SettingsGroupsService,
        aRoute: ActivatedRoute,
        router: Router,
        dialogService: DialogService,
        private apiConfig: ConfigService
        ) {
        super(api, aRoute, router, dialogService);


    }

    ngOnInit() {
        this.apiConfig.config.subscribe((config) => {
            if ( config && config.cloudDealer) {
                this.cloudDealer = config.cloudDealer;
            }
            this.hasConfig = true;
            this.init();
        });
        if ( this.hasConfig ) {
            this.init();
        }
    }


    // init() {
    //     super.init();
    // }

    setItem(data?) {
        this.data = this.cloudDealer && this.cloudDealer.enabled ? new GroupModelDealer(data) : new GroupModel(data);
    }

    saveData() {
        this.saving = true;
        if ( this.data.form.valid ) {
            this.data.updateDataForm();
            this.save(this.data);
        }
    }

    saveSuccess(r?) {
        this.data.edit = false;
        this.goto(this.data);
        super.saveSuccess();
    }

    handleKeyUp(e) {
        // debugger;
        if(e.keyCode === 13){
            this.saveData();
        }
    }
}
