<!--<app-dashboards-->
        <!--*ngIf="config"-->
        <!--[parentName]="'serving/' + data.id"-->
        <!--[config]="config"-->
        <!--[type]="data.stream_type"-->
        <!--[configMetrics]="api.currentItem.configFilter"-->
        <!--[streams]="data.activeStreams"-->
        <!--(change)="changeDashboard($event)"-->
<!--&gt;</app-dashboards>-->
<!--<app-loader *ngIf="loading"></app-loader>-->


<div *ngIf="api.currentItem"  class="flex-md-row row flex-column-reverse " >
    <div class="col-md-9 col">
        <div class="alert alert-danger" *ngIf="error">{{error.message}}</div>
        <app-dashboards-new
                *ngIf="cConfig"
                [config] = "cConfig"
                [parentName]="'serving/' + data.id"
                [type] = "api.currentItem.app" >

        </app-dashboards-new>

    </div>
    <div class="col-md-3  col toc" >
        <div class="card">
            <div class="card-body" *ngIf="range">
                <app-core-filter
                        [options]="fOptions"
                        [config]='fConfig'
                        [storageName]="'chanell' + api.currentItem.id"
                        (change)="filterChange($event)"
                ></app-core-filter>
            </div>
        </div>
    </div>
</div>
