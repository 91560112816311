import { Injectable } from '@angular/core';
import {CoreBaseService} from '../base/service';
// import {ServingsStreamFileModel} from '../model';
import {HttpClient} from '@angular/common/http';
import {Model} from '@app/_models/model';


export class CorePersonModel extends Model {
    age:	number;
    anger_rate:	number;
    attention_rate:	number;
    body_images:	any[];
    class_id:	string;
    duration:	number;
    emotion:	string;
    end:	string;
    face_images:	any[];
    gender:	string;
    good_face_images:	any[];
    happy_rate:	number;
    name:	string;
    neutral_rate:	number;
    sad_rate:	number;
    serving:	string;
    start:	string;
    surprise_rate:	number;

}

@Injectable({
    providedIn: 'root'
})
export class CorePersonsService extends CoreBaseService<CorePersonModel> {
    _pref = '/persons';

    constructor(http: HttpClient) {
        super(http);
    }
}
