import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from '@app/_helpers';
import { FormsModule } from '@angular/forms';
import {LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from '@coreui/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

import { DefaultLayoutComponent } from './containers';
const APP_CONTAINERS = [
    DefaultLayoutComponent
];

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';
import { RegisterComponent } from './views/register/register.component';

import {ServingModule} from './serving/serving.module';
import {DashboardsModule} from './dashboards/dashboards.module';
import {SettingsModule} from '@app/settings/settings.module';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule, BsModalRef} from 'ngx-bootstrap/modal';
import {BsDatepickerModule, DatepickerModule} from 'ngx-bootstrap/datepicker';

import {ServingPlayerComponent} from './serving/player/player.component';
import { PlayerComponent } from './test/player/player.component';

import {UserHeaderComponent} from '@app/containers/user/header.component'

// import {TransferState} from '@angular/platform-browser';
// import {HttpClient} from '@angular/common/http';
// import { SvgServerLoader, AngularSvgIconModule } from 'angular-svg-icon';
// export function svgLoaderFactory(http: HttpClient, transferState: TransferState) {
//     return new SvgServerLoader('browser/assets/icons', transferState);
// }

import { AngularSvgIconModule } from 'angular-svg-icon';

import { ChartsModule } from 'ng2-charts';
import {ServingsModule} from '@app/servings/servings.module';


import {
    HomeComponent,
    VideosComponent,
    VideoItemComponent,
    PersonsComponent,
    PersonsItemComponent,
    EventsComponent,
    EventsItemComponent,
    PersonsEventComponent,
    VideosEventComponent,
    EventsItemVideoComponent,
    EventsItemPersonComponent,
    PersonItemEventsComponent,
    PersonItemImagesComponent,
    PersonItemMetaComponent,
    EventsPersonComponent,
    PersonsFilterComponent,
    UsersComponent,
    UserItemComponent,

    StreamEventComponent,
    StreamComponent,
    StreamItemComponent,
    EventsItemStreamComponent

} from './pages';

const APP_PAGES = [
    HomeComponent,
    VideosComponent,
    PersonsComponent,
    VideoItemComponent,
    PersonsItemComponent,
    EventsComponent,
    EventsItemComponent,
    PersonsEventComponent,
    VideosEventComponent,
    EventsItemVideoComponent,
    EventsItemPersonComponent,
    PersonItemEventsComponent,
    PersonItemImagesComponent,
    PersonItemMetaComponent,
    EventsPersonComponent,
    PersonsFilterComponent,
    UsersComponent,
    UserItemComponent,

    StreamEventComponent,
    StreamComponent,
    StreamItemComponent,
    EventsItemStreamComponent,

];


// import * as APP_PAGES from './pages';


import {fakeBackendProvider} from '@app/_helpers/fake-backend';
import {CoreModule} from '@app/_core/core.module';
import { PaginationComponent } from './_core/pagination/pagination.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { LoaderComponent } from './_core/loader/loader.component';
import { ConfirmDialogComponent } from './_core/confirm-dialog/confirm-dialog.component';

import { AceModule } from 'ngx-ace-wrapper';
import { ACE_CONFIG } from 'ngx-ace-wrapper';
import { AceConfigInterface } from 'ngx-ace-wrapper';
import { PermissionErrorComponent } from './views/error/permission.error.component';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
};

@NgModule({
    declarations: [
        PlayerComponent,
        AppComponent,
        P404Component,
        P500Component,
        LoginComponent,
        LogoutComponent,
        RegisterComponent,
        // PaginationComponent,
        ...APP_CONTAINERS,
        ...APP_PAGES,
        ConfirmDialogComponent,
        UsersComponent,
        UserHeaderComponent,
        PermissionErrorComponent
    ],
    imports: [
        AceModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        PerfectScrollbarModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        AppHeaderModule,
        AppFooterModule,
        AppSidebarModule,
        ServingModule,
        ChartsModule,
        DashboardsModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        // DatepickerModule.forRoot(),
        CoreModule,
        ReactiveFormsModule,
        AppRoutingModule,
        BsDatepickerModule.forRoot(),
        BrowserAnimationsModule
        // ,AngularSvgIconModule
        ,AngularSvgIconModule.forRoot()
        , ServingsModule
        , SettingsModule
    ],
    // exports: [
    //   ...APP_PIPE
    // ],
    entryComponents: [ ConfirmDialogComponent, UserItemComponent ],
    bootstrap: [AppComponent],
    providers: [
        BsModalRef,
        // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: ACE_CONFIG,
            useValue: DEFAULT_ACE_CONFIG
        }
        // fakeBackendProvider
    ],
    // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
