import {Component, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from './users.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserItemComponent} from './item/item.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import * as _ from 'underscore';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
    list: any;
    page: number;
    error: any;
    loading: boolean;
    private modalRef: BsModalRef;
    paramsSubscription: Subscription;
    private querySubscription: Subscription;
    currentId: string;

    constructor(
        private api: UsersService,
        private modalService: BsModalService,
        private router: Router,
        private aRouter: ActivatedRoute
    ) {

        if ( router ) {
            this.paramsSubscription = router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.getCurrentId();
                }
            });
        }

        if ( aRouter ) {
            this.querySubscription = aRouter.queryParams.subscribe(
                (queryParam: any) => {
                    this.page = queryParam['page'];
                    this.getList();
                }
            );
        }
    }

    ngOnInit() {
        this.getList();
    }

    ngOnDestroy(): void {
        if ( this.paramsSubscription ) {
            this.paramsSubscription.unsubscribe();
        }
        if ( this.querySubscription ) {
            this.querySubscription.unsubscribe();
        }
    }

    getCurrentId() {
        if ( this.aRouter.snapshot.children && this.aRouter.snapshot.children[0] ) {
            this.currentId = this.aRouter.snapshot.children[0].params.itemId;
        } else {
            this.currentId = null;
        }
    }

    getList () {
        let params = {}; // = this.filter ? this.filter : {};
        params['page'] = this.page ? this.page : 1;

        params = _.extend({}, params);
        // params = _.extend({}, this.serach, params);

        this.loading = true;
        this.api.getAll(params)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                r => {
                    this.list = r;
                },
                (err) => {
                    this.error = err;
                }
        );
    }

    goto(item) {
        this.router.navigate(['/users', item]);
    }

    itemInfo (item?) {
        this.modalRef = this.modalService.show(UserItemComponent,
            {initialState: {
                    item: item
                }});
        // return this.modalRef.content.onClose;
    }
}
