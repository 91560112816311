import {Injectable, TemplateRef} from '@angular/core';
import {ConfirmDialogComponent} from "@app/_core/confirm-dialog/confirm-dialog.component";
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Injectable({ providedIn: 'root' })
export class DialogService {

    private modalRef: BsModalRef;

    constructor(
        private modalService: BsModalService
    ) {
    }

    confirmShow (title, message) {
        this.modalRef = this.modalService.show(ConfirmDialogComponent,
            {initialState: {
                    title: title,
                    message: message
                    // message: `Are you sure want to delete this event ${this.item.title}`
                }});
        return this.modalRef.content.onClose;
    }

    messageShow( title, message ) {
        this.modalRef = this.modalService.show(ConfirmDialogComponent,
            {initialState: {
                    title: title,
                    message: message,
                    type: 'message'
                }});
        return this.modalRef.content.onClose;
    }

    templateShow(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
        return this.modalRef;
    }

}
