import {Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy} from '@angular/core';
import {StreamService} from '@app/pages/stream/stream.service';
import {Stream} from '@app/_models';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'underscore';
import {finalize} from 'rxjs/operators';

import {CoreBaseItem} from '@app/_core/base/item';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {FormArray} from '@angular/forms';
import {CoreBaseService} from '@app/_core/base/service';
import {DialogService} from '@app/_services';

@Component({
    selector: 'app-stream-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class StreamItemComponent extends CoreBaseItem implements OnInit, OnChanges, OnDestroy {

    @Input() item: Stream;
    @Input() parent: any;
    @Input() modalRef: BsModalRef;

    extAction:  Subject<any> ;
    stremaUrls = [];
    api: StreamService;
    streamActionError: any;
    streamStatusError: boolean;
    updateTimeImg: any;

    constructor( api: StreamService, private activatedRoute: ActivatedRoute, private modalService: BsModalService, private dialogService: DialogService, private router: Router) {
        super(api);
    }

    ngOnInit() {
        this.error = null;
        this.extAction = this.api.extAction;
        if ( !this.item ) {
            this.activatedRoute.paramMap.subscribe(params => {
                const id = params.get( "streamId" );
                this.loadItem(id);
            });
        } else {
            this.getStreamInfo();
            if ( this.item.isNew || this.item.asEdit ) {
                this.item.asEdit = false;
                this.asEdit();
            }
        }
    }

    ngOnDestroy(): void {
        if(this.isEdit) this.isEdit = false;
        if ( this.updateTimeImg ) clearTimeout(this.updateTimeImg);
        this.api.current = null;
    }

    createItem(data): Stream {
        return new Stream(data);
    }

    saveSuccess(r) {
        this.item = this.createItem(r);
        this.asEdit();
        if ( this.extAction ) {
            this.extAction.next({action: 'itemSave', data: this.item});
        }
        if ( this.modalRef ) {
            this.modalRef.hide();
        }
        this.getStreamInfo();
            }

    loadItemSuccess() {
        this.api.current = this.item;
        this.getStreamInfo();
        if ( this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.edit ) {
            this.asEdit();
            this.router.navigate([], {relativeTo: this.activatedRoute});
        }

    }

    deleteSuccess(r) {
        if ( this.extAction ) {
            this.extAction.next({action: 'itemDelete', data: this.item});
        }
        this.router.navigate([ '../../stream' ], {relativeTo: this.activatedRoute});
    }

    cancel() {
        this.asEdit();
        if (this.modalRef) this.modalRef.hide();
    }

    init() {
        this.stremaUrls = this.item.stream_params.map( (p, i) => this.getStreamInit(p, i));
        this.updateTimeImg = _.delay(
            () => {
                this.updateStream();
            }, 60000);
    }

    ngOnChanges(changes: SimpleChanges): void {
        for ( let c in changes ) {
            switch (c) {
                case 'item':
                    if ( this.isEdit ) this.asEdit();
                    this.item = new Stream(this.item);
                    this.init();
                    break;
            }
        }
    }

    updateStream() {
        if ( this.updateTimeImg ) clearTimeout(this.updateTimeImg);
        this.stremaUrls.forEach( i => { i.link = this.getStreamUrl(i.key); });
        this.updateTimeImg = _.delay(
            () => {
                this.updateStream();
            }, 60000);

    }

    getStreamInit(item, i) {
        return {
            key: item.key,
            link: this.getStreamUrl(item.key),
            active: i === 0 ? true : false,
            config: item
        };
    }

    getStreamUrl(key) {
        const ts = Date.now();
        return this.api.getUrl(`${this.item.id}/${key}/mjpeg?${ts}`);
    }

    errorStream(ev) {
        debugger
    }

    formArr(type: string, control?) {
        const g = control ? control : this.form;
        return g.get(type) as FormArray;
    }

    addNewRow(type:string, event, control?) {
        if(event) event.stopPropagation();
        const item = type === 'stream_params' ? this.item.set_stream_params().getForm() : this.item.createItem();
        this.formArr(type, control).push(item);
        return false;
    }

    deleteRow(type: string, index: number, control?) {
        this.formArr(type, control).removeAt(index);
    }

    getStreamInfo( id? ) {
        id = id || this.item.id;
        if ( id ) {
            this.streamActionError = null;
            this.streamStatusError = false;
            this.loading = true;
            this.api.serving_info( id )
                .pipe(finalize(() => {
                    this.loading = false;
                    this.init();
                }))
                .subscribe(
                    r => {
                        this.streamStatusError = false;
                    },
                    err => {
                        this.streamStatusError = true;
                    }
                );
        }
    }

    streamAction(action: string) {
        this.loading = true;
        const a = `serving_${action}`;
        this.streamActionError = null;


        this.api[a]( this.item.id )
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe(
                r => {
                    switch (action) {
                        case 'start':
                        case 'restart':
                            this.streamStatusError = false;
                            break;
                        case 'stop':
                            this.streamStatusError = true;
                            break;
                    }
                    // this.responseTest = r;
                },
                err => {
                    this.streamActionError = err;
                }
            );
    }

    seleceStream(ev) {
        debugger
    }
}
