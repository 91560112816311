import {Component, Input, OnInit} from '@angular/core';
import {DashboardsService} from '@app/dashboards/dashboards.service';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

import { DatePipe } from '@angular/common';
import {DashboardsBase} from '@app/dashboards/items/dashboards_base';
import * as _ from 'underscore';

@Component({
  providers: [DatePipe],
  selector: 'app-dashboards-gender-age-timeline',
  templateUrl: './gender-age-timeline.component.html',
  styleUrls: ['./gender-age-timeline.component.scss']
})
export class GenderAgeTimelineComponent extends DashboardsBase implements OnInit {
    @Input() bygender: string;
    @Input() index = 'gender-age-timeline';

  graphData = [];
  graphDataBy =  {};
  graphLabel = [];
  // listError: any;
  complite = false;

  constructor( api: DashboardsService, private datePipe: DatePipe) {
    super(api);
  }

  ngOnInit() {
    this.init();
  }

  init() {
      // this.getDataIndex(this.index, {interval: '5min'});
      this.getDataIndex(this.index);
  }

  prepareResponce(r, index?, data?) {
      this.prepare(r);
      this.complite = true;
      // switch (index) {
      //     case 'index':
      //         this.prepare(r);
      //         this.complite = true;
      //         break;
      // }
  }

  prepare(r) {
    let index = {};
    let data = [];
    let dataGender = {};
    let label;
	this.graphData = [];
	this.graphDataBy = {};


    for (let item of r.classes) {
      index[item.id] = item;
      let d = r.timeline.filter( i => {
        return i.class == item.id;
      });

      if ( this.bygender ) {
// debugger
		  if ( ! this.graphDataBy[item.group[0].id] )  this.graphDataBy[item.group[0].id] = [];
		  let count = 0;
		  for (let v of d) count += v.value;

		  this.graphDataBy[item.group[0].id].push({
			  data: d.map( i => { return i.value; }),
			  label: item.group[0].title + ' ' + item.group[1].title,
			  count: count
		  });
	  } else {
		  this.graphData.push({
			  data: d.map( i => { return i.value; }),
			  label: item.group[0].title + ' ' + item.group[1].title
		  });
	  }
      label = d.map( i => this.datePipe.transform(new Date(i.startTime ), 'short'));
    }
    this.graphLabel = label;
      const optin = _.clone(this.mainChartOptions);
      optin['scales']['xAxes'][0]['time'] =  {
          unit: 'second',
          unitStepSize: ((Date.parse(r.parameters.to) - Date.parse(r.parameters.from)) / 5000) - 1
      };
      this.mainChartOptions = optin;
  }

  // mainChartOptions = {
  //   responsive: true,
  //   legend: {
  //     display: true
  //   },
  //
	//   plugins: {
	// 	  datalabels: {
	// 		  // color: 'white',
	// 		  display: false,
	// 		  font: {
	// 			  weight: 'bold'
	// 		  },
	// 		  position: 'outside'
	// 	  }
	//   }
  // };



  public mainChartOptions_: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips,
      intersect: true,
      mode: 'index',
      position: 'nearest',
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function(value: any) {
            return value.charAt(0);
          }
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          stepSize: Math.ceil(250 / 5),
          max: 250
        }
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };

  public mainChartColours: Array<any> = [
    {
      backgroundColor: 'transparent',
      borderColor: '#1f1498',
      pointHoverBackgroundColor: '#fff'
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#2982cc',
      pointHoverBackgroundColor: '#fff'
    },
	{
      backgroundColor: 'transparent',
      borderColor: '#f6960b',
      pointHoverBackgroundColor: '#fff'
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#d93737',
      pointHoverBackgroundColor: '#fff'
    },
	{
      backgroundColor: 'transparent',
      borderColor: '#1f1498',
      pointHoverBackgroundColor: '#fff',
		borderWidth: 1,
		borderDash: [8, 5]
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#2982cc',
      pointHoverBackgroundColor: '#fff',
		borderWidth: 1,
		borderDash: [8, 5]
    },
	{
      backgroundColor: 'transparent',
      borderColor: '#f6960b',
      pointHoverBackgroundColor: '#fff',
		borderWidth: 1,
		borderDash: [8, 5]
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#d93737',
      pointHoverBackgroundColor: '#fff',
		borderWidth: 1,
		borderDash: [8, 5]
    }
  ];


	public mainChartColours_: Array<any> = [
		{ // brandInfo
			backgroundColor: hexToRgba(getStyle('--info'), 10),
			borderColor: getStyle('--info'),
			pointHoverBackgroundColor: '#fff'
		},
		{ // brandSuccess
			backgroundColor: 'transparent',
			borderColor: getStyle('--success'),
			pointHoverBackgroundColor: '#fff'
		},
		{ // brandDanger
			backgroundColor: 'transparent',
			borderColor: getStyle('--danger'),
			pointHoverBackgroundColor: '#fff',
			borderWidth: 1,
			borderDash: [8, 5]
		}
	];



	public mainChartLegend = true;
  public mainChartType = 'line';


}
