<div *ngIf="item">
    <div *ngIf=" !asEdit ">
        <div class="row" >
            <h2 class="col">User {{ item.name }} ({{item.service || item.login}})</h2>
            <div class="btn-group" dropdown class="col-md-auto">
                <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle"><i class="fa fa-ellipsis-v fa-lg mt-4"></i> menu<span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                    <li role="menuitem"><a class="dropdown-item" (click)="edit()">edit</a></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="delete()">delete</a></li>
                </ul>
            </div>
        </div>

        <app-events-person [filter]="filterEvent" *ngIf="filterEvent" [env]="'delete'" [parentItem]="item.id" [parentType]="'user'"></app-events-person>


    </div>
    <form [formGroup]="itemForm" (ngSubmit)="save()" *ngIf=" asEdit ">
        <div class="modal-body" >
            <div class="form-group">
                <label for="login">Login</label>
                <input type="text" formControlName="login" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.login.errors }" [disabled]="!item.isNew"/>
                <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
                    <div *ngIf="f.login.errors.required">login is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="login">Name</label>
                <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">login is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="text" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">password is required</div>
                </div>
            </div>
            <div class="alert-danger" *ngIf="error">{{error}}</div>
        </div>
        <div class="modal-footer" >
            <button type="submit" class="btn btn-secondary" >save</button>
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
        </div>
    </form>
</div>
