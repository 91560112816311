import { Component, OnInit } from '@angular/core';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {UserModel} from '@app/_models';
import {SettingsUsersService} from '@app/_services/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '@app/_services';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-setting-users-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class SettingsUsersItemComponent extends CoreBaseItemConroller<UserModel> implements OnInit {

    addBlock = false;

    constructor(
        api: SettingsUsersService,
        aRoute: ActivatedRoute,
        router: Router,
        dialogService: DialogService,
        // modalService?: BsModalService
    ) {
        super(api, aRoute, router, dialogService
            // , modalService
        );
    }

    ngOnInit() {
        this.init();
    }

    setItem(data?) {
        this.data = new UserModel(data);
    }

    saveData() {
        this.saving = true;
        if ( this.data.form.valid ) {
            this.data.updateDataForm();
            this.save(this.data);
        }
    }

    saveSuccess(r?) {
        this.data.edit = false;
        this.goto(this.data);
        super.saveSuccess();
    }


}
