import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import {EventsService} from '@app/pages/events/events.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Event} from '@app/_models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
    selector: 'app-person-item-events',
    template: `
      <div>
          <app-events-person [filter]="filterEvent" *ngIf="filterEvent" [env]="'delete'" [parentItem]="itemId" [parentType]="'person'"></app-events-person>
      </div>
    `
})
export class PersonItemEventsComponent implements OnInit, OnDestroy {

    itemId: string;
    filterEvent: any;
    extAction = new Subject();
    paramsSubscription: Subscription;
    modalRef: BsModalRef;

    constructor( private activatedRoute: ActivatedRoute, private api: EventsService, router: Router, private modalService: BsModalService) {
        this.itemId = activatedRoute.parent.snapshot.params.itemId;
        this.paramsSubscription = router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.init();
            }
        });
    }

    init() {
        this.itemId = this.activatedRoute.parent.snapshot.params.itemId;
        this.filterEvent = {person_id: this.itemId};
        // this.filterNotPersons = {not_event_id: this.itemId};
    }

    ngOnInit() {
        this.init();
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    action(ev) {
        // debugger;
        switch (ev.action) {
            case 'delete':
                this.api.deletePerson( this.itemId, ev.data.id ).subscribe(
                    r => {
                        this.extAction.next({ev});
                    },
                    err => {
                        debugger;
                    }
                );
                break;
            case 'add':
                this.api.addPerson( this.itemId, ev.data.id ).subscribe(
                    r => {
                        this.extAction.next({ev});
                    },
                    err => {
                        debugger;
                    }
                );
                break;
        }
    }

    ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

}
