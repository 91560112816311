import { Component, OnInit, Input } from '@angular/core';
import {CoreBaseListConroller} from '@app/_core/base/list-controller';
import {GroupModel, GroupModelList} from '@app/_models';
// import {SettingsUsersService} from '@app/settings/users/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsGroupsService} from '@app/_services/groups.service';
import {SettingsUsersService} from '@app/_services/users.service';

@Component({
    selector: 'app-settings-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})
export class SettingsGroupsComponent extends CoreBaseListConroller<GroupModel> implements OnInit {

    // @Input() params: any;
    // @Input() action: any;

    api: SettingsGroupsService;
    list: GroupModelList;

    constructor(
        api: SettingsGroupsService,
        aRoute: ActivatedRoute,
        router: Router) {
        super(api, aRoute, router);

    }

    // ngOnInit() {
    //     super.ngOnInit();
    // }

    actionDo(item, event) {
        event.stopPropagation();
        if(this.action) {
            switch (this.action.type) {
                case 'add':
                    this.api.addUser(item.id, this.action.data)
                        .subscribe(
                        (r) => {
                            this.api.extAction.next({type: 'updateList'});
                        },
                        (err) => {}
                    );
                    break;
                case 'delete':
                    this.api.deleteUser(item.id, this.action.data)
                        .subscribe(
                        (r) => {
                            this.api.extAction.next({type: 'updateList'});
                        },
                        (err) => {}
                    );
                    break;
            }
        }
    }
}
