import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {UserModel, PaginateRequest} from '../_models';
import {CoreBaseService} from '../_core/base/service';
import {Observable, Subject} from 'rxjs';
import {share} from 'rxjs/operators';
import {GroupModel} from '@app/_models';

@Injectable({
    providedIn: 'root'
})

export class SettingsGroupsService extends CoreBaseService<GroupModel> {

    _pref = 'groups';
    current: any;
    config: any;
    public extAction = new Subject();
    // currentItem: ServingsModel;

    constructor(http: HttpClient) {
        super(http);
    }

    addUser(groupId, userId) {
        return this.http.put(this.getUrl(groupId) + `/users/${userId}`, {});
    }

    deleteUser(groupId, userId) {
        return this.http.delete(this.getUrl(groupId) + `/users/${userId}`);
    }
}
