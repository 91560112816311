import { Component, OnInit } from '@angular/core';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {ServingsModel} from '@app/servings/model';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import * as _ from 'underscore';

@Component({
    selector: 'app-servings-item-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class ServingsItemFormComponent extends CoreBaseItemConroller<ServingsModel> implements OnInit {


    data: ServingsModel;
    value: any = {};
    form: FormGroup;
    inferenceForm: any;

    constructor(
        api: ServingsService,
        aRoute: ActivatedRoute,
        router: Router) {
        super(api, aRoute, router);
    }

    ngOnInit() {
        this.form = new FormGroup({}, { updateOn: 'blur' });
        this.data = this.api.currentItem;
        if (this.data.type === 'file') {
            this.inferenceForm = _.filter(this.data.inferenceForm, (v) => { return ( v.label !== "Streams" ); });

            // debugger

        } else {
            this.inferenceForm = this.data.inferenceForm;
        }
        // debugger
    }

    send( event) {
        // debugger;
        // console.info(form.value, this.form.value);
        this.data.inferenceValues = this.form.value;
        this.save();
    }
    saveSuccess(r?) {
        this.api.currentItem = this.data;
        super.saveSuccess(r);
    }


    // async getFormConfig() {
    //     let stream_type;
    //     if(this.data.form && this.data.form.value.stream_type) {
    //         stream_type = this.data.form.value.stream_type;
    //     }
    //
    //     await this.api.getForm(stream_type).subscribe(
    //         (r: any) => {
    //             this.formConfig = r;
    //             if ( !this.data.inferenceForm ) { this.data.inferenceForm = r.form; }
    //             if ( !this.data.inferenceValues ) { this.data.inferenceValues = r.defaultValues; }
    //             if ( this.data.edit ) {
    //                 this.data.getForm();
    //             }
    //             this.complite += 1;
    //         },
    //         (err) => {
    //             this.complite += 1;
    //             this.error = err;
    //         }
    //     );
    //     return this.formConfig;
    // }

}
