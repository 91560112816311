import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ServingsService} from '@app/servings/servings.service';
import {ServingsModel, ServingForm} from '@app/servings/model';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {SettingsGroupsService} from '@app/_services/groups.service';
import {CurrentUser, GroupModelList} from '@app/_models';
import {AuthenticationService, DialogService} from '@app/_services';
import {ConfigService} from '@app/_services/config.service';
import {finalize} from 'rxjs/operators';
import {FormGroup, ValidationErrors} from '@angular/forms';

@Component({
    selector: 'app-servings-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class ServingsItemComponent extends CoreBaseItemConroller<ServingsModel> implements OnInit, OnDestroy {
    @ViewChild('chanelToken', { static: true }) private chanelToken: TemplateRef<any>;
    data: ServingsModel;
    api: ServingsService;
    config: any;
    formConfig: ServingForm;
    groupApi: SettingsGroupsService;
    groups: any; // GroupModelList;
    types = ['stream', 'file'];
    // types = ['stream', 'file', 'videofile'];
    submited = false;
    authUser: AuthenticationService;
    hasConfig: boolean;
    cloudDealer: boolean;
    complite = 0;
    channelKey:string;

    namePattern = '^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$';

    formError = false;

    tabs_description = {
        dashboard: {
            title: 'Statistics',
            link: 'dashboard'
        },
        files: {
            title: 'Video Files',
            link: 'files'
        },
        streams: {
            title: 'Video Streams',
            link: 'streams'
        },
        form: {
            title: 'Configuration',
            link: 'form'
        },
        report: {
            title: 'Details Report',
            link: 'report'
        },
        log: {
            title: 'Log',
            link: 'log'
        },
        source: {
            title: 'Source',
            link: 'source'
        },
        trackmap: {
            title: 'Trackmap',
            link: 'trackmap'
        },
        heatmap: {
            title: 'Heatmap',
            link: 'heatmap'
        }
    };

    // tabs_stream_types = {
    //     'Detector': ['dashboard', '_video', 'report', 'form', 'log'],
    //     'Trackmap':['dashboard', '_video', 'trackmap', 'form', 'log'],
    //     'Heatmap': ['dashboard', '_video', 'heatmap', 'form', 'log']
    // };

    tabs_stream_types = {
        'detector': ['dashboard', '_video', 'report'],
        'trackmap':['dashboard', '_video', 'trackmap'],
        'heatmap': ['dashboard', '_video', 'heatmap']
    };

    _tabs: any;

    currentUser: CurrentUser;

    constructor(
        api: ServingsService,
        adRoute: ActivatedRoute,
        router: Router,
        groupApi: SettingsGroupsService,
        dialogService: DialogService,
        public  configService: ConfigService,
        authUser?: AuthenticationService
    ) {
        super(api, adRoute, router, dialogService);
        this.groupApi = groupApi;
        this.authUser = authUser;
        this.authUser.currentUser.subscribe((user) => {
            this.currentUser = user; //new CurrentUser(user);
        });

        this.configService.config.subscribe((config) => {
            if ( config && config.cloudDealer) {
                this.cloudDealer = config.cloudDealer.enabled;
            }
            this.hasConfig = true;
            this.init();
        });
    }

    getTabs() {
        if(!this._tabs) {
            let tabs = this.tabs_stream_types[this.data.stream_type];

            let v_index = tabs.indexOf('_video');
            if(v_index != -1) {
                tabs[v_index] = this.data.type =='file' ? 'files' : 'streams';
            }
            // if(this.data.type) {
            //
            //
            // }
            this._tabs = tabs;
        }
        return this._tabs;
    }

    init() {
        this.types = this.cloudDealer ?  ['stream', 'file'] : [ 'stream'];
        // this.types = this.cloudDealer ?  ['stream', 'file', 'videofile'] : [ 'stream', 'videofile'];
        super.init();
    }

    ngOnInit() {
        this.configService.createCS();

        if ( this.hasConfig ) {
            this.init();
        }
    }

    setItem(data?) {

        super.setItem(data);
        this.data  = new ServingsModel(data);
        if(data && data.key) {
            this.channelKey = data.key;
            this.openModal(this.chanelToken);
        }
        this.api.currentItem = this.data;
    }

    setError(error?) {
        debugger
    }

    ngOnDestroy(): void {
        this.api.config = null;
        this.api.currentItem = null;
    }

    edit() {
        if (this.data.edit) {
            this.data.edit = false;
            return;
        }
        if ( !this.formConfig ) {
            this.complite -= 1;
            this.getFormConfig();
        }
        if ( !this.groups) {
            this.complite -= 1;
            this.getGroups();
        }

        if (!this.data.type) {
            this.data.type = this.types[0];
        }
        this.data.getForm();
        this.data.edit = true;
        this.configService.removeCS();
    }

    async getFormConfig() {
        let stream_type;
        if(this.data.form && this.data.form.value.stream_type) {
            stream_type = this.data.form.value.stream_type;
        }

        await this.api.getForm(stream_type).subscribe(
            (r: any) => {
                this.formConfig = r;
                if ( !this.data.inferenceForm ) { this.data.inferenceForm = r.form; }
                if ( !this.data.inferenceValues ) { this.data.inferenceValues = r.defaultValues; }
                if ( this.data.edit ) {
                    this.data.getForm();
                }
                this.complite += 1;
            },
            (err) => {
                this.complite += 1;
                this.error = err;
            }
        );
        return this.formConfig;
    }

    async   getGroups () {
            await this.groupApi.getList().subscribe(

                (r) => {
                    this.groups = r;
                    this.complite += 1;
                    if ( this.groups.items.length ) {
                        if ( !this.data.group_id ) { this.data.group_id = this.groups.items[0].id; }
                        if ( this.data.edit ) { this.data.getForm(true); }
                    }


                },
                (err) => {
                    this.complite += 1;
                    this.error = err;
                }
            );
            return this.groups;
    }

    saveData() {
        this.submited = true;
        this.formError = false;
        if ( this.data.form.valid ) {
            this.data.updateDataForm();
            this.save(this.data);
        } else {
            console.log(getFormValidationErrors(this.data.form));
            this.formError =true;
        }
    }

    keyDownFunction(event) {
        // debugger;
        if (event.keyCode === 13) {
            this.saveData();
            // alert('you just pressed the enter key');
            // rest of your code
        }
        return false;
    }

    saveSuccess(r?) {
        this.data.edit = false;
        // this.goto(this.data);
        const params = ['..'];
        if ( this.data ) { params.push(this.data.id); }
        this.router.navigate(params, {skipLocationChange: true, relativeTo: this.aRoute});
        super.saveSuccess();
    }

    publish(ev) {
        ev.stopPropagation();
        this.data.public = !this.data.public;
        this.save(this.data);
        return false;
    }

    hideKeyModel() {
        this.channelKey = null;
        this.modalRef.hide();
    }

    uploadfile(event, key: string) {
        debugger
        const file = event.currentTarget.files[0] as File;
        this.loading = true;
        this.api.videoFielUpload(this.data, key, file).pipe(
            finalize(() => {this.loading = false; })
        )
            .subscribe(
                (res) => {
                    debugger;
                },
                (err) => {
                    debugger
                });
    }
}

export function getFormValidationErrors(form: FormGroup) {

    const result = [];
    Object.keys(form.controls).forEach(key => {

        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors) {
            Object.keys(controlErrors).forEach(keyError => {
                result.push({
                    'control': key,
                    'error': keyError,
                    'value': controlErrors[keyError]
                });
            });
        }
    });

    return result;
}
