import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormArray, FormGroup, Validators, FormBuilder, FormControl} from '@angular/forms';
import {Model} from '@app/_models/model';
import * as _ from 'underscore';

@Component({
    selector: 'app-form-controll-stream',
    templateUrl: './stream.component.html',
    styleUrls: ['./stream.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormControllStreamComponent),
            multi: true,
        }
    ],
})

export class FormControllStreamComponent implements ControlValueAccessor {
    form: FormGroup;
    @Input() options: any ;


    types = ['stream', 'file'];

    constructor (private fb: FormBuilder) {
        this.form = this.fb.group({
            streams: this.fb.array([]),
        }, {updateOn: 'blur'});
    }

    writeValue(value) {
        if (value) {
            for (let v of value) {
                this.addStream(v);
            }
        }
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    get f() {
        return this.form.controls;
    }

    getControls() {
        return this.f.streams['controls'];
    }

    formArr(type: string, control?) {
        const g = control ? control : this.form;
        return g.get(type) as FormArray;
    }

    addStream(val?: any, event?) {
        if (event) { event.stopPropagation();}
        // val = val ? val : {description: '', input : 'rtmp://127.0.0.1/live/live',  key: 'live', output: 'null', param: {}};
        const streams = this.form.controls.streams as FormArray;
        if ( !val ) {
            if(streams) {
                const streamsVal = streams.value;
                val = _.clone(streamsVal[streamsVal.length -1]);
                val.key = val.key + '-copy'
            } else {
                val = {description: '', input : 'rtmp://127.0.0.1/live/live',  key: 'live', output: 'null', param: {}};
            }
        }
        streams.push(this.fb.group(val, {updateOn: 'blur'}));
        this.apply();
    }

    deleteRow(type: string, index: number, control?) {
        this.formArr(type, control).removeAt(index);
        this.apply();
    }

    apply() {
        this.onChange(this.f.streams.value);
        this.onTouched();
    }

    field(name, group: FormGroup) {
        if( !group.contains(name)){
            let v;
            switch (name) {
                case 'disabled':
                  v = false;
                  break;
                case 'display_name':
                    v = group.value.key;
                    break;
                case 'type':
                    // v = group.value.key;
                    break;
                case 'source_type':
                    // v = group.value.key;
                    break;
                default:
                    v = '';
            }
            group.addControl(name, new FormControl(v));
        }

        if(name == 'type' && (!group.value[name] || group.value[name] === '')) {
            group.value[name] = this.types[0];
        }
        if(name == 'source_type' && (!group.value[name] || group.value[name] === '')) {
            group.value[name] = this.types[0];
        }
        return name;
    }

}
