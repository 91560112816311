import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import {EventsService} from '@app/pages/events/events.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Stream} from '@app/_models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

/* http://localhost:4201/#/persons/ab283ce4-d414-49fe-922d-687dd51b107b */

@Component({
    selector: 'app-event-item-stream',
    template: `
        <!--<div class="d-flex justify-content-between mb-3">-->
            <!--<span class="col-auto"></span>-->
            <!--<button type="button" class="btn btn-primary col-auto" (click)="openModal(addEventPerson)" >add stream</button>-->
        <!--</div>-->
        <div>
            <app-stream-event [parent]="{type: 'events', data: itemId}"  title="event persons"  ></app-stream-event>
            <!--<app-stream-event [parent]="{type: 'events', data: itemId}"  title="event persons" [extAction]="extAction" ></app-stream-event>-->
        </div>
        <!--<ng-template #addEventPerson>-->
            <!--<div class="modal-body" >-->
                <!--<app-stream-item [item]="{isNew: true}" [modalRef]="modalRef" [extAction]="extAction" ></app-stream-item>-->
            <!--</div>-->
        <!--</ng-template>-->
    `
})
export class EventsItemStreamComponent implements OnInit, OnDestroy {

    itemId: string;
    // extAction = new Subject();
    paramsSubscription: Subscription;
    modalRef: BsModalRef;

    constructor( private activatedRoute: ActivatedRoute, private api: EventsService, router: Router, private modalService: BsModalService) {
        this.itemId = activatedRoute.parent.snapshot.params.itemId;
        this.paramsSubscription = router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.init();
            }
        });
    }

    init() {
        this.itemId = this.activatedRoute.parent.snapshot.params.itemId;
    }

    ngOnInit() {
        this.init();
    }

    openModal(template: TemplateRef<any>) {
        // this.env = 'list';
        this.modalRef = this.modalService.show(template);
    }

    ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

}
