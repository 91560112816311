<div class="card">
    <div class="card-body">
        <div class="card-title">
            <h5>
                Files
                <button class="btn btn-sm btn-primary float-right" (click)="openModal(addVideo)">add file</button>
            </h5>

        </div>

        <table *ngIf="list" class="table">
            <thead>
            <th class='clickabel' (click)="listOrder('file_name')">File <svg-icon *ngIf="params.order === 'file_name' && !params.desc" src="assets/icon/sort-ascending.svg" [svgStyle]="{ 'width.px':16 }" ></svg-icon><svg-icon  *ngIf="params.order === 'file_name' && params.desc" src="assets/icon/sort-descending.svg" [svgStyle]="{ 'width.px':16 }" ></svg-icon></th>
            <th class='clickabel' (click)="listOrder('upload_time')">Time  <svg-icon *ngIf="params.order === 'upload_time' && !params.desc" src="assets/icon/sort-ascending.svg" [svgStyle]="{ 'width.px':16 }" ></svg-icon><svg-icon  *ngIf="params.order === 'upload_time' && params.desc" src="assets/icon/sort-descending.svg" [svgStyle]="{ 'width.px':16 }" ></svg-icon></th>
            <th style="text-align: right" >Status</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of list.items" (click)="viewVideoFile(item, viewVideo)" class="clickabel">
                    <td>{{item.file_name}}</td>
                    <td>{{item.upload_time | date:'short'}}</td>
                    <td>
                        <span tooltip="{{tooltip(item)}}" class=" status {{item.status}} float-right" >{{item.status}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <app-pagination *ngIf="list"   (action)="getList($event.data)" [page]="list.page" [count]="list.count" [limit]="list.limit"></app-pagination>
    </div>
</div>



<ng-template #viewVideo>
    <div class="modal-header" *ngIf="currentVideo">
        <h5 class="modal-title" id="exampleModalLabel">{{currentVideo.file_name}}</h5>
        <button type="button" class="close" (click)="closeModal('view')" >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <video *ngIf="currentVideo" class="w-100" id="videoElement" #videoElement controls="controls" style="height: 350px" (playing)="onPlayingVideo($event)">
            <source [src]="currentVideo.linkDownload">
        </video>
    </div>
</ng-template>

<ng-template #addVideo>
    <div class="modal-header" *ngIf="currentVideo">
        <h5 class="modal-title" id="exampleModalLabel">Add video file.</h5>
        <button type="button" class="btn btn-secondary" class="close" (click)="closeModal('add')" >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="uploadFile( $event )" class="card-body">
            <h5 class="card-title">Upload video file</h5>
            <div class="">
                <div class="form-group form-row">
                    <label class="col-2 col-form-label col-form-label">video file</label>
                    <div class="col-10">
                        <input class="form-control" type="file" (change)="setUploadFile($event.target.files)">
                    </div>
                </div>
                <div class="form-group form-row">
                    <label class="col-2 col-form-label col-form-label"></label>
                    <div class="col-10 form-check checkbox">
                        <input class="form-check-input" id="check" type="checkbox" [checked]="hasDate" (change)="initDate($event)">
                        <label class="form-check-label" for="check">user start video</label>
                    </div>
                </div>
                <div class="form-group form-row " *ngIf="hasDate">
                    <label class="col-2 col-form-label col-form-label">Date start video</label>
                    <div class="col-10">

                        <input type="text"
                               placeholder="Datepicker"
                               class="form-control"
                               bsDatepicker
                               (bsValueChange)="changeBsDate($event)"
                               [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'MM/DD/YYYY h:mm a'  }">
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary col-12 " type="submit" [disabled]="uploading || !newVideo.file" >upload <span *ngIf="uploadProgress">{{uploadProgress.progress}} %</span></button>
                </div>
            </div>
            <div *ngIf="uploadingError" class="alert alert-danger">{{uploadingError | json}}</div>
        </form>
        <app-loader class="over" *ngIf="uploading"></app-loader>
    </div>
</ng-template>

<app-loader *ngIf="loading" class="over"></app-loader>
