import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { DatePipe } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import {DashboardsService} from './dashboards.service';
import { Chart } from 'chart.js';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as _ from 'underscore';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

interface  DashboardConfig {
	interval: string;
	from: string;
	to: string;
	serving: string[];
	streams: string[];
	start: any;
	type: any;
}

class confingParamsDashboard {
	from: any;
	to: any;
	interval: string;
	only_classes: string;
	streams: any;

	constructor (data) {
		if(data.dateInterval) {
			if(_.isArray(data.dateInterval)) {
				this.from = new Date(data.dateInterval[0]).toISOString();
				this.to = new Date(data.dateInterval[1]).toISOString();
			} else {
				this.from = new Date(Date.now() - data.dateInterval).toISOString();
				this.to = new Date(Date.now()).toISOString();
			}
		}

		if(data.start && data.duration) {
			this.from = new Date(data.start + data.timeInterval[0] * 1000).toISOString();
			this.to =  new Date(data.start + data.timeInterval[1] * 1000).toISOString();
		}
		this.interval = data.interval;
		this.streams = data.streams;
		if(this.streams) {
			if(this.streams.indexOf('___empty___' ) == -1) {
				this.streams.push('___empty___');
			}
		}
		if(!_.isUndefined(data.only_classes)) {
			this.only_classes = data.only_classes;
		}
	}

	toJSON() {
		let json = {
			from: this.from,
			to: this.to,
			interval: this.interval,

		};
		if(!_.isUndefined(this.streams)) {
			json['streams'] = this.streams;
		}
		if(!_.isUndefined(this.only_classes)) {
			json['only_classes'] = this.only_classes;
		}
		return json;
	}
}


@Component({
	selector: 'app-dashboards-new',
	templateUrl: './dashboards-new.component.html',
	providers:[DatePipe]
})
export class DashboardsNewComponent implements OnInit, OnDestroy, OnChanges {

	public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
	@Input() parent: any;
	@Input() parentName: any;
	@Input() config: any;
	@Input() type: any;
	@Input() streams: any[];

	@Input() configMetrics: confingParamsDashboard; //DashboardConfig;

	@Output() change: EventEmitter<any> = new EventEmitter<any>();

	streamsList: any;

	dropdownSettings: IDropdownSettings = {
		singleSelection: false,
		idField: 'item_id',
		textField: 'item_text',
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		itemsShowLimit: 6,
		allowSearchFilter: true
	};
	groups: any;

	groupsSelected: any;
	metricsList = [];
	metricsListSelected = [];
	viewMetrics = {};
	loading = false;
	complite = false;

	mByType = {
		detector: {
			list: [
				{ item_id: 'app-dashboards-people-count-block', item_text: 'people count' },
				{ item_id: 'Traffic Timeline', item_text: 'Traffic Timeline' },
				{ item_id: 'Audience appearance', item_text: 'Audience appearance' },
			],
			selected: [
				{ item_id: 'app-dashboards-people-count-block', item_text: 'People count' },
				{ item_id: 'Traffic Timeline', item_text: 'Traffic Timeline' },
				{ item_id: 'Audience appearance', item_text: 'Audience appearance' },
				]
		},
		heatmap: {
			list: [
				{ item_id: 'app-dashboards-people-timeline', item_text: 'people timeline' },
			],
			selected:[
				{ item_id: 'app-dashboards-people-timeline', item_text: 'people timeline' },
			]
		},
		trackmap: {
			list: [
				{ item_id: 'app-dashboards-people-timeline', item_text: 'people timeline' },
			],
			selected:[
				{ item_id: 'app-dashboards-people-timeline', item_text: 'people timeline' },
			]
		}
	};

	constructor(private api: DashboardsService, private aRoute: ActivatedRoute, private datePipe: DatePipe) {
	}

	ngOnInit(): void {
		// if (this.streams) {
		// 	this.streamsList = {};
		// 	let hasStream = false;
		// 	let sc = ( this.configMetrics && this.configMetrics.streams ) ? this.configMetrics.streams : [];
		// 	let all = sc.length == 0 ? true : false;
		// 	_.each(this.streams, (v) => {
		// 		this.streamsList[v.key] = ( all || sc.indexOf(v.key) != -1 ) ? true : false;
		// 		hasStream = true;
		// 	});
		// 	if(!hasStream) {
		// 		delete this.streamsList;
		// 	}
		// }
		//
		// if(!this.type) {
		// 	this.type = 'Detector';
		// }
		// if (!this.config ) {
		//     if(this.configMetrics) {
		//         this.config = this.configMetrics;
        //     } else {
        //         this.config = {};
        //     }
        // }
		// if (this.parent && this.parent.groups) {
		// 	this.groups = {};
		// 	this.groupsSelected = {};
		// 	_.each(this.parent.groups, (v: any, k) => {
		// 		this.groups[k] = v.map((i) => { return {item_id: i.id, item_text: i.name}; });
		// 		this.groupsSelected[k] = v.map((i) => { return {item_id: i.id, item_text: i.name}; });
		// 	} );
		// }
		//
		// this.metricsList = this.mByType[this.type].list;
		// this.metricsListSelected = this.mByType[this.type].selected;
		//
		// this.setViewMetrics(this.metricsListSelected);
		//



		let p = !this.parent ? this.aRoute.snapshot.data['parent'] : this.parent;
		let pref =  this.parentName;
		if ( p && ! pref ) {
			p['itemId'] = this.aRoute.parent.snapshot.params[p.param ];
			pref = (p.type && p.itemId) ? `${p.type}/${p.itemId}` : null;
		}
		this.api.setPref(pref);
	}

	streamChange(event, s) {
		this.streamsList[s.key] = event.currentTarget.checked;
		this.streamChangeAddConfig();
	}

	streamChangeAddConfig() {
		if(this.streamsList) {

			let sl = new Array();
			_.each(this.streamsList, (v, k) => {
				if (v) {
					sl.push(k);
				}
			});
			sl.push('____empty____');
			this.configMetrics.streams = sl;
		}
	}

	setViewMetrics(list?) {
		let l = list ? list : this.metricsListSelected;
		this.viewMetrics = {};
		for ( let item of l) {
			this.viewMetrics[item.item_id] = item.item_text;
		}
	}

	ngOnDestroy() {}

	setConfig(ev) {
		this.configMetrics = _.clone(ev.data);
		this.streamChangeAddConfig();
        this.change.emit({action: 'configMetrics', data: this.configMetrics});
    }

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.config) {
			this.viewMetrics = {};
			if(this.config.metrics) {
				_.each(this.config.metrics, (v: string) => {
					this.viewMetrics[v] = true;
				})
			}
			if(_.keys(this.config).length) {
				this.configMetrics = new confingParamsDashboard(this.config);
			}

			// debugger
		}
	}
}
