import { Component, OnInit } from '@angular/core';
import {CoreBaseListConroller} from '@app/_core/base/list-controller';
import {UserModel, UserModelList} from '../../_models';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsUsersService} from '@app/_services/users.service';
import {SettingsGroupsService} from '@app/_services/groups.service';

@Component({
    selector: 'app-settings-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class SettingsUsersComponent extends CoreBaseListConroller<UserModelList> implements OnInit {

    constructor(
        api: SettingsUsersService,
        aRoute: ActivatedRoute,
        router: Router,
        private  groupsApi: SettingsGroupsService) {
        super(api, aRoute, router);
    }

    actionDo(item, event) {
        event.stopPropagation();
        if(this.action) {
            switch (this.action.type) {
                case 'add':
                    this.groupsApi.addUser(item.id, this.action.data)
                        .subscribe(
                            (r) => {
                                this.api.extAction.next({type: 'updateList'});
                            },
                            (err) => {}
                        );
                    break;
                case 'delete':
                    this.groupsApi.deleteUser(item.id, this.action.data)
                        .subscribe(
                            (r) => {
                                this.api.extAction.next({type: 'updateList'});
                            },
                            (err) => {}
                        );
                    break;
            }
        }
    }

}
