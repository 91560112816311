import {FormGroup} from '@angular/forms';
import {Model} from '@app/_models/model';
import {finalize} from 'rxjs/operators';
import {CoreBaseService} from '@app/_core/base/service';

export  class CoreBaseItem {
    form: FormGroup;
    item: any;
    isEdit: boolean;
    loading = false;
    error: any;
    api: CoreBaseService<any>;
    submitted: boolean;

    constructor( api ) {
        this.api = api;
    }

    get f() { return this.form.controls; }

    save () {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.item.updateDataForm();

        this.loading = true;
        this.error = null;
        this.api.save(this.item)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                r => {
                    this.saveSuccess(r);
                },
                err => {
                    this.error = err;
                }
            );
    }

    createItem(data) {
        return new Model(data);
    }

    delete () {
        this.error = null;
        this.loading = true;
        this.api.delete(this.item.id)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                r => {
                    this.deleteSuccess(r);
                },
                err => {
                    this.error = err;
                }
            );
    }

    deleteSuccess (r) {}
    saveSuccess(r) {
        this.item = this.createItem(r);
        this.asEdit();
    }

    loadItem(id) {
        return this.api.getById( id )
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                (r) => {
                    this.item = this.createItem(r);
                    this.loadItemSuccess();
                },
                (err) => {
                    this.error = err;
                }
            );
    }

    loadItemSuccess() {

    }

    asEdit() {
        this.isEdit = !this.isEdit;
        if ( this.isEdit ) {
            this.form = this.item.getForm();
            console.log(this.form);
        } else {
            this.form = null;
        }
    }



}
