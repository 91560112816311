import { Component, OnInit, Input } from '@angular/core';
export * from './item/item.component';
import {VideoFile, PaginateRequest} from '@app/_models';
import {VideosService} from '@app/pages/videos/videos.service';

import {VideosBaseComponent} from './videos_base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {EventsService} from '@app/pages/events/events.service';

@Component({
    selector: 'app-videos-event',
    templateUrl: './videos_event.component.html'
})
export class VideosEventComponent extends VideosBaseComponent {
    @Input() parent: any;

    constructor( api: VideosService, roter: Router, aRouter: ActivatedRoute) {
        super(api, roter, aRouter);
    }

    ngOnInit() {
        super.ngOnInit();
        // this.api.parent = this.parent;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.api.parent = null;
    }

    setCutterntVideo(item, dropdown?) {
        this.currentVideo = item;
    }
}
