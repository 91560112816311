import { Component, OnInit } from '@angular/core';
import {ServingsService} from '../servings.service';
import {ServingsModel, ServingsModelList} from '../model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CoreBaseListConroller} from '@app/_core/base/list-controller';
import {AuthenticationService} from '@app/_services';
import {CurrentUser} from '@app/_models';

@Component({
  selector: 'app-servings',
  templateUrl: './servings.component.html',
  styleUrls: ['./servings.component.scss']
})
export class ServingsComponent extends CoreBaseListConroller<ServingsModelList> implements OnInit {
    public  authUser: AuthenticationService;
    currentUser: CurrentUser;
  constructor(
      api: ServingsService,
      aRoute: ActivatedRoute,
      router: Router,
      authUser?: AuthenticationService) {
      super(api, aRoute, router);
      if(authUser) {
          this.authUser = authUser;
          this.authUser.currentUser.subscribe((user) => {
              this.currentUser = user; //new CurrentUser(user);
          });
      }
  }

  ngOnInit() {
    super.ngOnInit();
    //   this.paramsSubscription = this.router.events.subscribe(
    //       (event) => {
    //           if (event instanceof NavigationEnd) {
    //               this.getCurrent();
    //           }
    //       }
    //   );
    // this.getCurrent();
    // if ( !this.current ) this.getList();
  }

}
