import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/_services';
import { map, catchError } from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {CurrentUser} from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | any {
        const currentUser = this.authenticationService.currentUserValue;
        // debugger
        if (currentUser && currentUser.admin) {
            return of(true);
        }
        // debugger
        if ( document.cookie.indexOf(this.authenticationService.sessVariable) !== -1) {

            return this.authenticationService.getInfo().pipe(
                map( (user: CurrentUser) => {
                    if ( user && user.admin ) {
                        return true;
                    } else {
                        return this.noUser(state);
                    }
                }),
                catchError((err) => {
                    this.noUser(state);
                    return of(false);
                })
            );
        }
        return this.noUser(state);
    }

    noUser (state) {
        debugger;
        this.router.navigate(['/permission_error'], { queryParams: { returnUrl: state.url }});
        return false;

    }
}
