import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Person, PaginateRequest, Image} from '@app/_models';
import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

import * as _ from 'underscore';


@Injectable({
    providedIn: 'root'
})

export class PersonsService {
    apiUrl =  (window as any).externalConfig.apiUrl;
    private pref = 'persons';
    events = new Subject();

    constructor( private http: HttpClient ) { }

    private getUrl(params?: string ) {
        return `${this.apiUrl}${this.pref}` + ( params ? `/${params}` : '');
    }

    getAll(params) {
        var p = this.httpParams(params);
        var gp:any ;

        if ( p ) {
          gp = {params: p};
        }
        return this.http.get<PaginateRequest<Person>>(this.getUrl(), gp);
    }

    getById(id: string) {
        return this.http.get<Person>(this.getUrl(`${id}`));
    }
    //
    // update(item: Person) {
    //     // this.events.next({action: 'update', data: item});
    //     return this.http.put(this.getUrl(`${item.id}`), item).pipe(
    //         tap(data => {
    //             this.events.next({action: 'update', data: item});
    //         })
    //     );
    // }

    delete(item: Person | string ) {
        // this.events.next({action: 'delete', data: {id: id}});
        const id = item instanceof Person ? item.id : item;
        return this.http.delete(this.getUrl(`${id}`))
            .pipe(
                tap(data => {
                    this.events.next({action: 'delete', data: item});
                })
            );
    }

    save(item: Person) {
        if ( item.id ) {
            return this.http.put<Person>(this.getUrl(`${item.id}`), item.toJSON())
                .pipe(
                    tap(data => {
                        this.events.next({action: 'update', data: data});
                    }
                )
            );
        } else  {
            return this.http.post<Person>(this.getUrl(), item.toJSON())
                .pipe(
                    tap(data => {
                            this.events.next({action: 'create', data: data});
                        }
                    )
                );

        }
    }

    getImages(item: Person | string, params?: any) {
        var p = this.httpParams(params);
        var gp:any ;
        if ( p ) {
              gp = {params: p};
        }
        const id = item instanceof Person ? item.id : item;
        return this.http.get<any[]>(this.getUrl(id) + '/images', gp );
    }


    getMeta(item: Person | string) {
        // var p = this.httpParams(params);
        // var gp:any ;
        // if ( p ) {
        //     gp = {params: p};
        // }
        const id = item instanceof Person ? item.id : item;
        return this.http.get<any>(this.getUrl(id) + '/meta');
    }

    setMeta(item: Person | string,  meta: any) {
        // var p = this.httpParams(params);
        // var gp:any ;
        // if ( p ) {
        //     gp = {params: p};
        // }
        const id = item instanceof Person ? item.id : item;
        return this.http.post(this.getUrl(id) + '/meta', meta);
    }


    httpParams(p?: any) {
    if ( p ) {
        let params = new HttpParams();
        for ( let i in p ) {
            if ( p[i] !== null && p[i] !== undefined  && p[i] !== '' ) {
                params = params.append(i, p[i]);
                }
            }
            return params;
        }
        return null;
    }

    public uploadImage(item: Person | string, image: File) {
        const formData = new FormData();
        const id = item instanceof Person ? item.id : item;
        formData.append('image', image);
        return this.http.post<Image>(this.getUrl(id) + '/images' , formData);
    }

    public deleteImage(item: Person | string, img) {
        const id = item instanceof Person ? item.id : item;
        return this.http.delete(this.getUrl(id) + `/images/${img}` );
    }
}
