import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ServingsModel} from '@app/servings/model';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {CoreBaseService} from '@app/_core/base/service';
import {HttpEventType} from '@angular/common/http';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
declare var Dragdealer: any;

@Component({
    selector: 'app-servings-item-heatmap-page',
    templateUrl: './heatmap-page.component.html',
    styleUrls: ['./heatmap-page.component.scss']
})
export class ServingsItemHeatmapPageComponent extends CoreBaseItemConroller<ServingsModel> implements OnInit, AfterViewInit {

    config: any = null;
    cConfig: any = null;
    data: ServingsModel;
    fOptions: any;
    fConfig: any;
    range: any;

    api: ServingsService;
    av = false;
    show = false;
    contentType: string;

    constructor(
        api: ServingsService,
        aRoute: ActivatedRoute,
        router: Router,
        private cd: ChangeDetectorRef) {
        super(api, aRoute, router);
    }

    ngOnInit() {
        this.contentType = this.aRoute.snapshot.data.type;
        this.data = this.api.currentItem;
        this.getRange(this.data.id);
    }

    getRange(id) {
        this.loading = true;
        this.api.getRange(id)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                (r: any) => {
                    this.setRange(r);
                    // this.range = [r.from, r.to];
                    // this.fOptions = this.filterOptions();
                    // this.fConfig= this.filterConfig();
                    // this.config = r;
                },
                (err) => {
                    this.setRange(this.api.getRangeByError());
                    // this.config = {};
                }
            );
    }

    setRange(r) {
        this.range = [r.from, r.to];
        this.fOptions = this.filterOptions();
        this.fConfig= this.filterConfig();
        this.config = r;
    }

    ngAfterViewInit(): void {
    }

    filterChange(event) {
        switch (event.action) {
            case 'filterAplay':
            case 'filterChange':
                let c = {};
                _.each(this.fOptions, (v: any) => {
                    c[v.name] = event.data[v.name] || v.default;
                });
                this.cConfig = c;
                break;
        }
        console.log(event);
    }

    filterOptions() {
        let intervals  = [
            {
                name: '30 seconds',
                value: 30000
            },
            {
                name: '1 minute',
                value: 60000
            },
            {
                name: '5 minutes',
                value: 5 * 60000
            },
            {
                name: '15 minutes',
                value: 15 * 60000
            },
            {
                name: '30 minutes',
                value: 30 * 60000
            },
            {
                name: '1 hour',
                value: 60 * 60000
            },
            {
                name: '2 hours',
                value: 2 * 60 * 60000
            },
            {
                name: '3 hours',
                value: 3 * 60 * 60000
            },
            {
                name: 'All',
                value: 'all'
            },
        ];

        let inter = intervals;

        return [
            {
                name: 'streams',
                title: 'Streams',
                type: 'array',
                value: this.data.activeStreams.map((v) => { return {value: v.key, label: v.key}}),
                default: this.data.activeStreams.map((v) => { return v.key})
            },
            {
                name: 'dateInterval',
                title: 'Date interval',
                type: 'dateInterval',
                value: this.range,
                default: this.range
            },
            {
                name: 'intervalms',
                title: 'Union interval',
                type: 'number',
                default: intervals[intervals.length - 1].value,
                value: intervals
            },
            {
                name: 'apply',
                type: 'button',
                title: 'Apply',
                value: 'apply'
            }
        ]
    }

    filterConfig() {
        return _.extend(
            this.cConfig
        );
    }

    canProjection(data) {
        return (data && data.params && data.params.projection_id) ? true : false;
    }
}
