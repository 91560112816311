import { Component, OnInit, Input } from '@angular/core';
import {PersonsService} from '@app/pages/persons/persons.service';
export * from '@app/pages/persons/item/item.component';
import {PaginateRequest, Person} from '@app/_models';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';


import {PersonsBase} from './persons_base';

@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss']
})
export class PersonsComponent extends PersonsBase {

  constructor(api: PersonsService, aRoute: ActivatedRoute, router: Router) {
    super(api, aRoute, null, router);
  }

}
