import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export class Person {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  position: string;
  company: string;
  event_id: string;

  private _form: FormGroup;

  formConfig() {
    return {
      name: [this.name, Validators.required],
      // firstName: [this.firstName],
      // lastName: [this.lastName],
      position: [this.position],
      company: [this.company],
    };
  }

  getForm(): FormGroup {
    if ( !this._form )
      this._form = new FormBuilder().group(this.formConfig());
    return this._form;
  }

  updateDataForm() {
    for (let key in this._form.value) {
      this[key] = this._form.value[key];
    }
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      firstName: this.firstName,
      lastName: this.lastName,
      position: this.position,
      company: this.company,
      event_id: this.event_id
    }
  }
}
