import { OnInit, Input, SimpleChanges, OnChanges, OnDestroy, Directive } from '@angular/core';
export * from './item/item.component';
import {VideoFile, PaginateRequest} from '@app/_models';
import {VideosService} from '@app/pages/videos/videos.service';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Directive()
export class VideosBaseComponent implements OnInit, OnChanges, OnDestroy {

    currentVideo: VideoFile;
    listClass = [];
    list: any; //PaginateRequest<VideoFile[]>;
    parent: any;
    error: any;
    api: VideosService;
    apiPref: string;
    loading = false;

    router: Router;
    aRouter: ActivatedRoute;

    constructor( api: VideosService, router: Router, aRouter: ActivatedRoute) {
        this.api = api;
        this.apiPref = api.pref;
        this.router = router;
        this.aRouter = aRouter;
    }

    ngOnInit() {
        if(!this.parent) this.getList();
    }

    ngOnDestroy(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
        for (let c in changes ) {
            switch ( c ) {
                case 'parent':
                    this.getList();
                    break;
            }
        }
    }

    getList(params?) {
        this.api.pref = this.parent ? `${this.parent.type}/${this.parent.data}/` : '';
        // this.currentVideo = null;
        this.loading = true;
        this.api.getAll(params)
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe(
                (r) => {
                    this.list = r;
                },
                (err) => {
                    this.error = err;
                }
            );
    }

    extAction(event) {
        switch (event.type) {
            case 'changePage':
                this.getList({page: event.data});
                break;
        }
    }

    goToItem (item, params?, ev?) {
        if ( ev ) ev.stopPropagation();
        if ( item ) {
            const p = [item.id];
            this.router.navigate(p, {queryParams: params, relativeTo: this.aRouter});
        }
        else
            this.router.navigate([ '../videos' ], {relativeTo: this.aRouter});
    }
}
