import { Injectable } from '@angular/core';
import {CoreBaseService} from '../../../_core/base/service';
import {ServingsStreamFileModel} from '../../model';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ServingsFilesService extends CoreBaseService<ServingsStreamFileModel> {
    _pref = '/files';

    constructor(http: HttpClient) {
        super(http);
    }
}
