import {FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import * as _ from 'underscore';


export class Model {

    id: any;
    isNew: boolean; // if need generete new item
    asEdit: boolean;
    name: string;
    _form: FormGroup;

    constructor (data?) {
        this.update(data);
        // if (data) {
        //     _.each(data, (v, k) => {
        //         this._setVal(k, v);
        //     });
        // }
    }

    update(data) {
        if (data) {
            _.each(data, (v, k) => {
                this._setVal(k, v);
            });
        }
    }

    get new () {
        return this.id ? false : true;
    }

    set edit (v) {
        this.asEdit = v ? true : false;
        if( !this.asEdit ) {
            this._form = null;
        }
    }

    get edit() {
        return this.asEdit;
    }

    get form (): FormGroup {
        if (!this._form) { this._form = this.getForm(); }
        return this._form;
    }

    formConfig():any {}

    toJSON():any {}

    getForm( clear?:boolean ): FormGroup {
        if ( !this._form || clear )
            this._form = new FormBuilder().group(this.formConfig());
        return this._form;
    }

    updateDataForm() {
        for (let key in this._form.value) {
            var v;
            if (_.isArray(this._form.value[key]) && !this['set_' + key]) {
                let a = {};
                for ( let i in this._form.value[key]){
                    let item = this._form.value[key][i];
                    if (item.label && item.label !== '' ) { a[item.label] = item.value;}
                }
                v = a;
            } else {
                v = this._form.value[key];
            }

            this._setVal(key, v);
        }
    }

    _setVal (key, v) {
        if ( this['set_' + key] ) {
            this[key] = _.isArray(v) ? v.map( i => this['set_' + key](i)) : this['set_' + key](v);
        } else {
            this[key] = v;
        }
    }

    updateDataFormItem() {

    }


    initFormItem(item) {
        let a = [];
        for ( let i in this[item]) {
            a.push(this.createItem(i, this[item][i]));
        }
        // a.push(this.createItem());
        return a;
    }

    createItem(label = '', value: any = ''): FormGroup {
        return new FormBuilder().group({
            label: label,
            value: value
        });
    }






}
