import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import * as _ from 'underscore';



import { FormControl, FormGroup, Validators } from '@angular/forms';

interface OptItem {
    name: string;
    value: any;
    type: string;
    hidden: any;
}

@Component({
    selector: 'app-form-constructor',
    templateUrl: './constructor.component.html',
    styleUrls: ['./constructor.component.scss']
})
export class FormConstructorComponent implements OnInit, OnChanges {

    @Input() options: any;
    @Input() ngModel: any;
    @Input() buttons: any;
    @Input() data: any = {};

    @Input() form: FormGroup;
    opt = [];
    defaultValue = {
        'int':    0,
        'int8':   0,
        'int16':  0,
        'int32':  0,
        'int64':  0,
        'uint':   0,
        'uint8':  0,
        'uint16': 0,
        'float':  0,
        'double': 0,
        'string': '',
        'boolean': false,
        'bool':   false,
        'select': '',
        'storage': null,
        'list':   []

    };
    isNumber = ['int', 'int8', 'int16', 'int32', 'int64', 'float', 'double', 'uint8', 'uint16'];
    valueWithUrl = /^(.*)\[(.*)\]$/;
    arrayGallery = {};

    constructor() { }

    ngOnInit() {
        if (!this.form) {
            this.form = new FormGroup({}, { updateOn: 'blur' });
        }
    }




    //
    //
    // toFormGroup(questions: QuestionBase<string>[] ) {
    //   let group: any = {};
    //
    //   questions.forEach(question => {
    //     group[question.key] = question.required ? new FormControl(question.value || '', Validators.required)
    //         : new FormControl(question.value || '');
    //   });
    //   return new FormGroup(group);
    // }


    ngOnChanges(changes: SimpleChanges) {
        if ( changes.options ) {
            this.setValue();
        }
    }

    getValueUrl(value) {
        if (!value || !_.isString(value)) return false;
        return value.match(this.valueWithUrl);
    }

    setValue() {
        this.opt = [];
        _.each(this.options, (v: OptItem, i) => {

            let type = v.type;
            if( v.type !== 'streams' && v.type !== 'bytes' && v.type !== 'image' && v.type !== 'audio' && v.type !== 'video' && _.isArray(v.value)) {
                const vU = this.getValueUrl(v.value[0]);
                if(vU && vU[2]) {
                    type = 'arrayGallery';
                }
                else {
                    type = 'array';
                    if( _.indexOf(this.isNumber, v.type ) !== -1) {
                        v.value = _.map(v.value, (i) => { return Number(i) ; });
                    }
                }
            }
            if ( type === 'bytes' || type === 'image' || type === 'audio' || type === 'video' ) {
                type = 'bytes';
            }

            if( type === 'arrayGallery' ) {
                this.arrayGallery[v.name] = _.map(v.value, (i) => { return this.getValueUrl(i); }) // vU;
            }

            if( !v.hidden ) { this.opt.push(_.extend({} , v, {TYPE : type})); }

            if (v.type !== 'group') {
                let v_value;
                if(v.type !== 'group' && _.isUndefined(v_value)) {
                    let vv = !_.isUndefined(this.data[v.name]) ? this.data[v.name] : v.value;

                    const value = (_.isArray(vv) && type !== 'streams') ? ( type === "arrayGallery" ? this.arrayGallery[v.name][0][1] : vv[0] ) : vv;
                    // const value = (_.isArray(v.value) && type !== 'streams') ? ( type === "arrayGallery" ? this.arrayGallery[v.name][0][1] : v.value[0] ) : v.value;
                    if(value){
                        if( _.indexOf(this.isNumber, v.type ) !== -1)
                        { v_value = Number(value) ;}
                        else if(v.type === 'boolean' || v.type === 'bool')
                        {
                            // debugger;
                            v_value = _.isBoolean(value) ? value : ( (value === 'false' || !value.length) ? false : true );
                        }
                        else if(v.type === 'byte' || v.type === 'bytes' || v.type === 'image' || v.type === 'audio' || v.type === 'video')
                        {}
                        else
                            v_value = value;
                    }else{
                        v_value = this.defaultValue[v.type];
                    }
                }
                this.form.addControl(v.name, new FormControl(v_value));
            }

            // if(v.type !== 'group' && _.isUndefined(this.ngModel[v.name])) {
            //     const value = (_.isArray(v.value) && type !== 'streams') ? ( type === "arrayGallery" ? this.arrayGallery[v.name][0][1] : v.value[0] ) : v.value;
            //     if(value){
            //         if( _.indexOf(this.isNumber, v.type ) !== -1)
            //         { this.ngModel[v.name] = Number(value) ;}
            //         else if(v.type === 'boolean' || v.type === 'bool')
            //         {
            //             // debugger;
            //             this.ngModel[v.name] = _.isBoolean(value) ? value : ( (value === 'false' || !value.length) ? false : true );
            //         }
            //         else if(v.type === 'byte' || v.type === 'bytes' || v.type === 'image' || v.type === 'audio' || v.type === 'video')
            //         {}
            //         else
            //             this.ngModel[v.name] = value;
            //     }else{
            //         this.ngModel[v.name] = this.defaultValue[v.type];
            //     }
            // }
        });
        // debugger
        // console.info(this.form);
    }





}
