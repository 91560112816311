import { Component, OnInit } from '@angular/core';
import {Person, UserModel} from '@app/_models';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UsersService} from '@app/pages/users/users.service';
import {FormGroup} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '@app/_services';
// import {el} from '@angular/platform-browser/testing/src/browser_util';


@Component({
    selector: 'app-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class UserItemComponent implements OnInit {

    item: UserModel;
    public onClose = new Subject<boolean> ();
    asEdit = false;
    error: any;
    itemForm: FormGroup;
    submitted: boolean;
    loading = false;
    filterEvent: any;

    constructor(
        private api: UsersService,
        private _bsModalRef: BsModalRef,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        // debugger
        // this.onClose = new Subject();

        this.error = null;
        if (this.item) {
            this.item = new UserModel(this.item);
        } else {
            this.activatedRoute.paramMap.subscribe(params => {
                const itemId = params.get("itemId");
                if (!itemId || itemId === 'create') {
                    this.item = new UserModel();
                    this.item.isNew = true;
                    this.edit(true);
                } else {
                    this.getInfo(itemId);
                    return ;
                }
            });
        }
        if ( this.item ) {
            this.filterEvent = {for_user: this.item.id};
        }
    }

    getInfo(itemId) {
        this.loading = true;
        this.api.getById( itemId )
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                (r) => {
                    this.item = new UserModel(r);
                    this.edit(false);
                    this.filterEvent = {for_user: this.item.id};
                },
                (err) => {
                    this.error = err;
                }
            );
    }

    edit (data?: boolean) {
        this.asEdit = data !== undefined ? data : (this.asEdit ? false : true);
        if (this.asEdit) {
            this.itemForm = this.item.getForm();
        } else {
            this.itemForm = null;
        }
    }

    public onConfirm(): void {
        this.onClose.next(true);
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        if ( this.item.isNew ) {
            this.router.navigate(['/users']);
        } else {
            this.edit();
        }

    }

    get f() {
        return this.itemForm.controls;
    }

    save() {
        this.submitted = true;

        if (this.itemForm.invalid) {
            return;
        }
        this.item.updateDataForm();
        this.api.save(this.item).subscribe(
            r => {
                this.asEdit = false;
                this.item = new UserModel(r);
                this.router.navigate(['/users', r.id]);
            },
            err => {
                this.error = err;
            }
        );
    }

    delete() {
        this.dialogService.confirmShow( 'Delete user', `Are you sure want to delete this user ${this.item.name}`)
            .subscribe( r => {
                if (r) {
                    this.error = null;
                    this.api.delete(this.item.id)
                        .subscribe(
                            r => {
                                this.router.navigate(['/users']);
                            },
                            err => {
                                this.error = err;
                            }
                        );
                }
            });
    }
}
