import {Model } from './model';
import {PaginateRequest } from './paginate-request';

import {Validators} from '@angular/forms';

export class ServingRegisterModel extends Model {
    name: string;
    stream_type: string;
    stream_url: string;
    registered: Date;
    assigned: boolean;

    set_registered(data) {
        return new Date(data);
    }

    formConfig(): any {
        return {
            // display_name: [this.display_name, Validators.required]
        };
    }

    toJSON(): any {
        return {
            name: this.name,
            stream_type: this.stream_type,
            stream_url: this.stream_url,
            registered: this.registered,
            assigned: this.assigned
        };
    }

}

export class ServingRegisterAssignModel extends Model  {

    channel_name: string;
    group_id: number;
    name: string;
    public: boolean;
    type: string;

    formConfig(): any {
        return {
            channel_name: [this.channel_name],
            group_id: [this.group_id, Validators.required],
            name: [this.name],
            public: [this.public],
            type: [this.type, Validators.required]

        };
    }

    toJSON(): any {
        return {
            channel_name: this.channel_name,
            group_id: Number(this.group_id),
            name: this.name,
            public: this.public,
            type: this.type
        };
    }

}

export class ServingRegisterModelList extends PaginateRequest<ServingRegisterModel> {}
