import { Component, OnInit } from '@angular/core';
// import {finalize} from 'rxjs/operators';
// import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {CoreBaseListConroller} from '@app/_core/base/list-controller';
// import {ServingsModel, ServingsStreamFileModel} from '@app/servings/model';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'underscore';
import {ServingsPersonsService, ServingPersonModel} from './persons.service';
import {DialogService} from '@app/_services';
import {finalize} from 'rxjs/operators';
import {ConfigService} from '@app/_services/config.service';
import {ServingModule} from '@app/serving/serving.module';
import {ServingsModel} from '@app/servings/model';
import {SubjectEvent} from '@app/_models';


class confingParamsPersons {
    from: any;
    to: any;
    interval: string;
    streams: any;
    age: any;
    gender: any;

    constructor (data) {
        if(data.dateInterval) {
            if(_.isArray(data.dateInterval)) {
                this.from = new Date(data.dateInterval[0]).toISOString();
                this.to = new Date(data.dateInterval[1]).toISOString();
            } else {
                this.from = new Date(Date.now() - data.dateInterval).toISOString();
                this.to = new Date(Date.now()).toISOString();
            }
        }
        //
        // if(data.dateInterval) {
        //     this.from = new Date(data.dateInterval[0]).toISOString();
        //     this.to = new Date(data.dateInterval[1]).toISOString();
        // }

        if(data.start && data.duration) {
            this.from = new Date(data.start + data.timeInterval[0] * 1000).toISOString();
            this.to =  new Date(data.start + data.timeInterval[1] * 1000).toISOString();
        }
        this.interval = data.interval;
        this.streams = data.streams;
        if(this.streams) {
            if(this.streams.indexOf('___empty___' ) == -1) {
                this.streams.push('___empty___');
            }
        }
        if(!_.isUndefined(data.age)) {
            this.age = data.age;
        }
        if(!_.isUndefined(data.gender)) {
            this.gender = data.gender;
        }
    }

    toJSON() {
        let json = {
            from: this.from,
            to: this.to,
            interval: this.interval,

        };
        if(!_.isUndefined(this.streams)) {
            json['streams'] = this.streams;
        }
        if(!_.isUndefined(this.age)) {
            json['age'] = this.age;
        }
        if(!_.isUndefined(this.gender)) {
            json['gender'] = this.gender;
        }
        return json;
    }
}



@Component({
    selector: 'app-serving-item-persons',
    templateUrl: './persons.component.html',
    styleUrls: ['./persons.component.scss']
})
export class ServingsItemPersonsComponent extends CoreBaseListConroller<ServingPersonModel> implements OnInit {

    csParamName = 'ServingsItemPersonsComponent';
    params: any = { page: 1, desc: true, order: 'start' };
    filterOpen = false;
    config: any = {};
    filtered = false;
    newParams: any;

    fOptions: any;
    fConfig: any;
    cConfig: any;
    range: any;


    ageClasses = [
        {
            "id": "21-",
            "title": "21 less"
        },
        {
            "id": "22-35",
            "title": "from 22 to 35"
        },
        {
            "id": "36-50",
            "title": "from 36 to 50"
        },
        {
            "id": "50+",
            "title": "50 and more"
        }
    ];

    data: ServingsModel;
    streamsList: any;


    constructor(
        api: ServingsPersonsService,
        aRoute: ActivatedRoute,
        router: Router,
        dialogService: DialogService,
        public servingApi: ServingsService,
        private configService: ConfigService,
        private apiServings: ServingsService
    ) {
        super(api, aRoute, router, dialogService);
    }

    ngOnInit(): void {

        this.data = this.apiServings.currentItem;

        if(this.data && this.data.activeStreams && this.data.activeStreams.length) {
            let hasStream = false;
            this.streamsList = {};
            _.each(this.data.activeStreams, (v: any) => {
                this.streamsList[v.key] = true;
                hasStream = true;
            });
            if(!hasStream) {
                delete this.streamsList;
            }
        }

        const p = this.configService.getCSP(this.csParamName);
        if ( p ) {
            if (p.params) {
                this.params = p.params;
            }
            this.filterOpen = p.filterOpen || false;
        }

        this.api.pref = `serving/${this.servingApi.currentItem.id}`;
        this.config = this.data.configFilter ? this.data.configFilter : this.servingApi.config;
        // if ( this.servingApi.config ) {
        // } else {
        //     this.getRange(this.servingApi.currentItem.id);
        // }
        this.getRange(this.servingApi.currentItem.id);

        if(this.data.activeStreams) {

            this.streamsList = {};
            let hasStream = false;
            let sc = ( this.data.configFilter && this.data.configFilter.streams ) ? this.data.configFilter.streams : [];
            let all = sc.length == 0 ? true : false;
            _.each(this.data.activeStreams, (v: any) => {
                this.streamsList[v.key] = ( all || sc.indexOf(v.key) != -1 ) ? true : false;
                hasStream = true;
            });
            if(!hasStream) {
                delete this.streamsList;
            }
        }


        // super.ngOnInit();

        if ( this.params ) {
            this.params = _.extend({}, this.defaultParams, this.params);
            // debugger
            // this.applyFilter();
        } else {
            this.params = this.params = _.extend({}, this.defaultParams);
            this.init();
            this.getCurrent();
        }

        this.extAction = this.api.extAction.subscribe((data: SubjectEvent) => {
            switch (data.type) {
                case 'updateList':
                    // debugger;
                    this.getList(data.data);
                    break;
            }
        });
    }

    // filterOpenAction() {
    //     this.filterOpen = !this.filterOpen;
    //     this.setCS({filterOpen: this.filterOpen});
    //     if ( !this.filterOpen && this.filtered ) {
    //         this.params = _.omit(this.params, ..._.keys(this.newParams));
    //         this.newParams = {};
    //         this.params.page = 1;
    //         this.getList();
    //         this.filtered = false;
    //     }
    // }

    getList(params: {} = {}){
        if (!params) {
            params = this.config;
        }
        let r = super.getList(params);
        this.data.configFilter = params;
        return r;
    }

    getRange(id) {
        this.loading = true;
        this.error = null;
        this.servingApi.getRange(id)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                (r: any) => {
                    this.setRange(r);
                    // this.range = [r.from, r.to];
                    // this.fOptions = this.filterOptions();
                    // this.fConfig= this.filterConfig();
                },
                (err) => {
                    this.setRange(this.servingApi.getRangeByError());
                    // this.config = {};
                    // this.error = err;
                }
            );
    }

    setRange(r) {
        this.range = [r.from, r.to];
        this.fOptions = this.filterOptions();
        this.fConfig= this.filterConfig();
    }

    changeParams(params) {
        this.newParams = _.extend({}, this.newParams, params);
    }

    prepareParamsBefore(params = {}) {
        params = super.prepareParamsBefore(params);
        this.setCS({params: params});
        return _.omit( params, 'count', 'interval');
    }

    // applyFilter() {
    //     this.filtered = true;
    //     this.params.page = 1;
    //     let streams = {
    //         streams: ['__empty__']
    //     };
    //
    //     _.each(this.streamsList, (v: boolean, k: string) => {
    //         if(v) {
    //             streams.streams.push(k);
    //         }
    //     });
    //
    //
    //     this.params = _.extend(this.params, this.newParams, streams);
    //     this.apiServings.currentItem.configFilter = this.params;
    //     // this.getList(this.newParams);
    //     this.getList(this.params);
    // }

    setCS( data = {}) {
        const csParams = this.configService.getCSP(this.csParamName) || {};
        this.configService.setCSP(this.csParamName, _.extend(csParams, data));
    }

    streamChange(event, s) {
        this.streamsList[s.key] = event.currentTarget.checked;
        // this.applyFilter();
    }


    filterOptions() {

       return [
            {
                name: 'streams',
                title: 'Streams',
                type: 'array',
                value: this.data.activeStreams.map((v) => { return {value: v.key, label: v.key}}),
                default: this.data.activeStreams.map((v) => { return v.key})
            },

            {
                name: 'dateInterval',
                title: 'Date interval',
                type: 'dateInterval',
                value: this.range,
                default: this.range
            },
            {
                name: 'age',
                title: 'Age',
                type: 'string',
                value: [
                    {
                        value: undefined,
                        name: 'All'
                    },
                    {
                        value: "21-",
                        name: "21 less"
                    },
                    {
                        value: "22-35",
                        name: "from 22 to 35"
                    },
                    {
                        value: "36-50",
                        name: "from 36 to 50"
                    },
                    {
                        value: "50+",
                        name: "50 and more"
                    }
                ],
                default: undefined
            },
           {
                name: 'gender',
                title: 'Gender',
                type: 'string',
                value: [
                    {
                        value: undefined,
                        name: 'All'
                    },
                    {
                        value: "male",
                        name: "Male"
                    },
                    {
                        value: "female",
                        name: "Female"
                    },
                    {
                        value: "undefined",
                        name: "Undefined"
                    }
                ],
                default: undefined
            },

            {
                name: 'apply',
                type: 'button',
                title: 'Apply',
                value: 'apply'
            }
        ]
    }

    filterConfig() {
        return _.extend(
            {},
            this.config
        );
    }

    filterChange(event) {
        switch (event.action) {
            case 'filterAplay':
            case 'filterChange':
                let c = {};
                _.each(this.fOptions, (v: any) => {
                    c[v.name] = event.data[v.name] || v.default;
                });

                let cc = new confingParamsPersons(c);
                this.getList(_.extend({}, this.params, cc));
                break;

        }
    }

}
