import {Component, Input, OnInit} from '@angular/core';
import {DashboardsService} from '@app/dashboards/dashboards.service';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

import { DatePipe } from '@angular/common';
import {DashboardsBase} from '@app/dashboards/items/dashboards_base';
import * as _ from 'underscore';

@Component({
  providers: [DatePipe],
  selector: 'app-dashboards-traffic-table',
  templateUrl: './traffic-table.component.html'
})
export class TrafficTableComponent extends DashboardsBase implements OnInit {

  graphData = {};
  graphLabel = [];
  listError: any;
  complite = false;
  trafficData:any;
  trafficCout:any;
  @Input() index: string;

  constructor( api: DashboardsService, private datePipe: DatePipe) {
    super(api);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getDataIndex('traffic-count');
    this.getDataIndex(this.index);
  }

  prepareResponce(r, index?, data?) {
    switch (index) {
      case 'traffic-count':
        this.trafficCout = _.findWhere(r.classes, {"id": "traffic"});
        break;
      case 'traffic-timeline':
        // debugger
        this.trafficData = r;
        // this.prepare(r);
        // this.complite = true;
        break;
    }
  }



}
