import {Component, OnInit, Input, forwardRef, Output, EventEmitter} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormArray, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {Model} from '@app/_models/model';
import * as _ from '@app/_models/stream';

@Component({
    selector: 'app-form-controll-object',
    templateUrl: './object.component.html',
    styleUrls: ['./object.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormControllObjectComponent),
            multi: true,
        }
    ],
})

export class FormControllObjectComponent implements ControlValueAccessor {

    form: FormGroup;
    @Input() options: any ;
    @Output() change = new EventEmitter();

    constructor (private fb: FormBuilder) {
        this.form = this.fb.group({
            params: this.fb.array([])
        }, {updateOn: 'blur'});
    }

    writeValue(value) {
        if (value) {
            for (let v in value) {
                this.addItem(v, value[v]);
            }
        }
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    createItem(label = '', value: any = '') {
        return {
            label: label,
            value: value
        };
    }

    get f() { return this.form.controls; }

    getControls() {
        return this.f.params['controls'];
    }

    addItem(key?: any, val?: any) {
        val =  this.createItem(key, val);
        const data = this.form.controls.params as FormArray;
        data.push(this.fb.group(val, {updateOn: 'blur'}));
        this.apply();

    }

    deleteRow(type: string, index: number, control?) {
        const item =  this.form.controls.params as FormArray;
        item.removeAt(index);
        this.apply();
    }


    apply() {
        let sdata = {};
        for (let i of this.f.params.value) {
            sdata[i.label] = i.value;
        }
        // debugger
        this.onChange(sdata);
        this.onTouched();
        // debugger
        this.change.emit();
    }

    // propagateChange = (_: any) => {};
    //
    // registerOnChange(fn) {
    //     this.propagateChange = fn;
    // }
    //
    // registerOnTouched() {}
}

