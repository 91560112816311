import {Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter} from '@angular/core';
import { Router} from '@angular/router';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit, OnChanges {

    @Input() page: number;
    @Input() count: number;
    @Input() limit: number;
    @Input() limits: number[] = [10, 25, 50];
    @Input() link: number;
    @Output() action: EventEmitter<any> = new EventEmitter<any>();
    @Input() lengthPageList = 5;
    @Input() anyPage: true;


    // @Input() params: any;


    pages: Array<any>;
    pCount: number;
    newPage: number;
    // counter = Array;

    constructor(private router: Router) { }

    ngOnInit() {
        if(!this.limit) {
            debugger
            this.limit = Number(localStorage.getItem('limitList'));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        for ( const propName in changes ) {
            switch (propName) {
                case 'page':
                case 'count':
                case 'limit':
                    this.initPages();
                    break;
            }
        }
    }

    initPages() {
        if(!this.count){
            this.pages = [];
            return [];
        }

        this.pCount = Math.floor(this.count / this.limit ) + ( ( this.count % this.limit > 0 ) ? 1 : 0 );
        var p = Array.from( Array( this.pCount ), ( x, index ) => index + 1 );
        // this.lengthPageList = 5;
        let page = this.page - 1;
        var s = page - ( Math.floor(this.lengthPageList / 2 ));
        var start = ( s <= 0 ) ? 0 : (
            ( page >= this.pCount )
                ? this.pCount - this.lengthPageList
                : s
        );

        // var start = ( (page + this.lengthPageList) >= this.pCount )
        //   ? this.pCount - this.lengthPageList
        //   : ( ( page >( Math.floor(this.lengthPageList / 2) ) )
        //         ? page - ( Math.floor(this.lengthPageList / 2 ))
        //         : page );

        var end = start + this.lengthPageList;
        this.pages =  p.slice(start, end);
    }

    goPage( page , event) {
        event.stopPropagation();
        if ( this.link ) {
            this.action.emit({type: 'changePage', data: {page: page, limit: this.limit}});
        } else {
            this.router.navigate([], { queryParams: { page: page, limit: this.limit} });
        }
    }

    changeLimit(event) {
        this.limit = Number(event.currentTarget.value)
        localStorage.setItem('limitList', this.limit.toString());
        this.goPage(this.page, event);
    }
}
