import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'underscore';
import {ProjectionModel} from '@app/_models/projection';
import Konva from 'konva';

class PointPosition {
    x: number;
    y: number;

    constructor(data: any = {}) {
        this.x = data && data.x ? data.x : 0;
        this.y = data && data.y ? data.y : 0;
    }
}

class Point {
    name: string;
    base: PointPosition;
    plan: PointPosition;
    active: boolean;
    constructor(data: any = {}) {
        this.name = data.name;
        this.base = new PointPosition(data.base);
        this.plan = new PointPosition(data.plan);
    }
}

@Component({
    selector: 'app-core-plan',
    templateUrl: './plan.component.html',
    styleUrls: ['./plan.component.scss']
})
export class CorePlanComponent implements OnInit {

    @Input() place: string;
    @Input() data: ProjectionModel;

    points: Point[] = [];
    mousePosition = {};
    movePointObject: PointPosition;
    active: number;

    constructor() { }

    ngOnInit(): void {

    }

    getX(p) {
        return p[this.place].x;
    }

    getY(p){
        return p[this.place].y;
    }

    setPoint( event) {
        const active = _.findWhere(this.data.projections, {active: true});
        if(active) {
            active[this.place].x = event.offsetX;
            active[this.place].y = event.offsetY;
            this.data.hasChange = true;
        }
    }



    width = window.innerWidth;
    height = window.innerHeight;

    update(activeAnchor) {
        var group = activeAnchor.getParent();

        var topLeft = group.get('.topLeft')[0];
        var topRight = group.get('.topRight')[0];
        var bottomRight = group.get('.bottomRight')[0];
        var bottomLeft = group.get('.bottomLeft')[0];
        var image = group.get('Image')[0];

        var anchorX = activeAnchor.getX();
        var anchorY = activeAnchor.getY();

        // update anchor positions
        switch (activeAnchor.getName()) {
            case 'topLeft':
                topRight.y(anchorY);
                bottomLeft.x(anchorX);
                break;
            case 'topRight':
                topLeft.y(anchorY);
                bottomRight.x(anchorX);
                break;
            case 'bottomRight':
                bottomLeft.y(anchorY);
                topRight.x(anchorX);
                break;
            case 'bottomLeft':
                bottomRight.y(anchorY);
                topLeft.x(anchorX);
                break;
        }

        image.position(topLeft.position());

        var width = topRight.getX() - topLeft.getX();
        var height = bottomLeft.getY() - topLeft.getY();
        if (width && height) {
            image.width(width);
            image.height(height);
        }
    }



    // addPoint() {
    //   const p = new Point({name: this.points.length});
    //   this.points.push(new Point());
    //   this.setActivePoint(this.points.length - 1);
    // }
    //
    // setActivePoint(i: number) {
    //   this.active = i;
    //   this.points.forEach((v, k) => {
    //     if(i === k) {
    //       this.points[k].active = true;
    //     } else {
    //       this.points[k].active = false;
    //     }
    //   })
    // }
    //
    // moveSetPoint(point, i, place) {
    //   // debugger
    //   this.movePointObject = point[place];
    //   this.setActivePoint(i);
    // }
    //
    // moveRemovPoint () {
    //   debugger
    //   delete this.movePointObject;
    // }
    //
    // movePoint(event) {
    //   // debugger
    //   if(this.movePointObject) {
    //
    //     this.movePointObject.x = event.offsetX;
    //     this.movePointObject.y = event.offsetY;
    //   }
    //
    //   this.mousePosition = {x: event.offsetX, y: event.offsetY}
    //
    // }
    //
    // setPoint(place, event) {
    //   if(_.isNumber(this.active)) {
    //     this.points[this.active][place].x = event.offsetX;
    //     this.points[this.active][place].y = event.offsetY;
    //   }
    // }
}
