import { Component, OnInit, OnDestroy, Input, TemplateRef } from '@angular/core';
import {PersonsService} from '@app/pages/persons/persons.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'underscore';
import {finalize} from 'rxjs/operators';
import {DialogService} from '@app/_services/dialog,service';

class ImageSnippet {
    pending: boolean = false;
    status: string = 'init';

    constructor(public src: string, public file: File) {}
}

@Component({
    selector: 'app-person-item-images',
    template: `
      <div class="d-flex justify-content-between mb-3">
          <h4 class="col-auto pl-0">Images</h4>
          <div class="form-group col-auto">
              <label class="image-upload-container btn btn-primary " for="addImg">Add Images</label>
              <input #imageInput
                     class="d-none"
                     id="addImg"
                     type="file"
                     accept="image/*"
                     (change)="processFile(imageInput)"
                     multiple
              >
          </div>
      </div>
      <div class="d-flex flex-wrap gallery">

          <div *ngFor="let i of images" class="gallery-item card d-flex justify-content-center align-content-center m-1" >
              <div class="gallery-item-img" *ngIf="!i.error">
                  <img *ngIf="i.aligned" src="{{i.aligned}}" style="max-width: 100%; max-height: 100%;" class="d-flex"/>
                  <div style="width: 160px; height: 160px;" *ngIf="!i.aligned"></div>
                  <div class="action" *ngIf="!i.action">
                      <button (click)="deleteImgage(i)">delete</button>
                  </div>
                  
              </div>
              <div class="gallery-item-img" *ngIf="i.error">
                  <div class="alert alert-danger h-100">
                      {{ i.error }} <br />
                      <button class="btn btn-danger mr-auto ml-auto" (click)="deleteImgage(i)">ok</button>
                  </div>
              </div>
              <p>{{i.filename}}</p>
              <app-loader *ngIf="i.action"  [action]="i.action" ></app-loader>
          </div>
      </div>

      <div *ngIf="imageError && imageError.length" class="alert alert-danger">
          {{ imageError | json }}
      </div>
        <app-loader *ngIf="loading" class="over"></app-loader>
        <!--<div> {{ images | json }}</div>-->
            
  `,
    // styleUrls: ['./item.component.scss']
})
export class PersonItemImagesComponent implements OnInit, OnDestroy {

    @Input() itemId: string;
    filterEvent: any;
    extAction = new Subject();
    paramsSubscription: Subscription;
    modalRef: BsModalRef;
    images: any;
    selectedFile: ImageSnippet;
    imageError: any;
    loading = false;
    apiEvent: Subject<any>;

    constructor( private activatedRoute: ActivatedRoute, private api: PersonsService, router: Router, private modalService: BsModalService, private dialogService: DialogService) {
        this.itemId = activatedRoute.parent.snapshot.params.itemId;
        this.paramsSubscription = router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.init(true);
            }
        });
    }

    init(update) {

        if ( update ) { this.itemId = this.activatedRoute.parent.snapshot.params.itemId; }
        this.getImages();
    }

    ngOnInit() {
        this.init( this.itemId ? false : true );
    }


    getImages() {
        this.imageError = [];
        this.loading = true;
        this.api.getImages(this.itemId, {fullPath: true})
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
            r => {
                this.images = r;
            },
            err => {
                this.imageError.push({
                        action: 'get list',
                        error: err
                    }
                );
            }
        );
    }

    processFile(imageInput: any) {
        this.imageError = [];
        if ( !imageInput.files && !imageInput.files.length ) return false;

        for ( let i = 0; i < imageInput.files.length; i++ ) {

            const file: File = imageInput.files[i];
            const reader = new FileReader();

            reader.addEventListener('load', (event: any) => {
                const image = _.findWhere(this.images, {filename: file.name});
                const newImage = {
                    filename: file.name,
                    // aligned: reader.result,
                    action: 'upload'
                };

                if ( !this.images.length ) {
                   this.apiEvent = this.api.events;
                }

                if (image) {
                    this.images[_.indexOf(this.images, image)] = newImage;
                } else {
                    this.images.push( newImage );
                }

                this.uploadImage(event, file);
            });
            reader.readAsDataURL(file);
        }
        imageInput.value = '';
    }

    uploadImage(event, file) {
        this.selectedFile = new ImageSnippet(event.target.result, file);
        this.selectedFile.pending = true;
        const i = _.indexOf(this.images, _.findWhere(this.images, {filename: file.name}));

        this.api.uploadImage( this.itemId, this.selectedFile.file).subscribe(
            (r) => {
                this.images[i] = r;
                this.apiEvent.next({ action: 'add image', data: this.itemId });
                this.apiEvent = null;
            },
            (err) => {
                this.images[i].error = err;
                this.images[i].action = null;
            }
        );
    }

    deleteImgage( img ) {
        if ( img && img.error ) {
            this.images = _.without(this.images, img);
        } else {
            this.dialogService.confirmShow( 'Delete image', `Are you sure want to delete this image ${img.filename}`)
            .subscribe(
                r => {
                    this.imageError = [];
                    img.action = 'delete';
                    this.api.deleteImage(this.itemId, img.filename).subscribe(
                        r => {
                            this.images = _.without(this.images, img);
                        },
                        err => {
                            this.imageError.push({
                                    filename: img.filename,
                                    action: 'delete',
                                    error: err
                                }
                            );
                        }
                    );
                }
            );
        }
    }

    ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

}
