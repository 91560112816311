import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CurrentUser } from '@app/_models';
import {ActivatedRouteSnapshot, Router} from '@angular/router';

// import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<CurrentUser>;
    public currentUser: Observable<CurrentUser>;
    apiUrl =  (window as any).externalConfig.apiUrl;
    sessVariable = 'AUDIENCE_SESS';
    public isPublic: boolean;

    constructor(
            private http: HttpClient,
            private router: Router,
        // private aRouter: ActivatedRouteSnapshot
        ) {
        // this.currentUserSubject = new BehaviorSubject<CurrentUser>(JSON.parse(localStorage.getItem('currentUser')));
        const userParam = JSON.parse(localStorage.getItem('currentUser'));
        const userObj = userParam ? new CurrentUser(userParam) : null;

        this.currentUserSubject = new BehaviorSubject<CurrentUser>(userObj);
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentUser.subscribe((user) => {
            if(user && !user.public) {
                this.isPublic = false;
            } else {
                this.isPublic = true;
            }
        });
        this.getInfo().subscribe();
    }

    public get currentUserValue(): CurrentUser {
        return this.currentUserSubject.value;
    }

    // login(username: string, password: string) {
    login(login: string, password: string) {
        const formData = new FormData();
        formData.append('login', login);
        formData.append('password', password);
        return this.http.post<any>(`${this.apiUrl}auth/login`, formData)
            .pipe(map(user => {
                if (user) {
                    this.saveUserInfo(user);
                }
                return user;
            }));
    }

    saveUserInfo (user) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        const userObj = user ? new CurrentUser(user) : null;
        this.currentUserSubject.next( userObj );
    }

    logout(error?: boolean) {
        return this.http.post<any>(`${this.apiUrl}auth/logout`, {})
            .pipe(
                map(user => {
                    localStorage.removeItem('currentUser');
                    this.currentUserSubject.next(null);
                    this.router.navigate(['/login']);
                    if (!error) {
                        this.getInfo().subscribe();
                    } else {
                        this.saveUserInfo(null);
                    }
                    // this.router.navigate(['/login'], { queryParams: { returnUrl: this.aRouter.url }});

                    return user;
                })
                // ,catchError(err => { debugger; return err; })
        );
    }

    getInfo() {
        return this.http.get(`${this.apiUrl}auth/info`)
            .pipe(
                map(user => {
                    if (user) {
                        this.saveUserInfo(user);
                    }
                    return user;
                }),
                catchError( err => {
                    if (err.status === 401) {
                        this.logout(true).subscribe();
                        return;
                    } else {
                        return throwError(err);
                    }
                    // debugger;
                })
            );
    }

    getToken() {
        return this.http.get(`${this.apiUrl}auth/token`);
    }

    changeToken() {
        return this.http.post(`${this.apiUrl}auth/token`, {});
    }
}
