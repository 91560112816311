import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ServingsModel} from '@app/servings/model';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import * as _ from 'underscore';
import {CoreBaseService} from '@app/_core/base/service';
import {HttpEventType} from '@angular/common/http';
import {finalize} from 'rxjs/operators';
declare var Dragdealer: any;

@Component({
    selector: 'app-servings-item-source',
    templateUrl: './source.component.html',
    styleUrls: ['./source.component.scss']
})
export class ServingsItemSourceComponent extends CoreBaseItemConroller<ServingsModel> implements OnInit {

    api: ServingsService;
    url = [];
    files = [];
    loadcount = -1;
    contentType: string;

    constructor(
        api: ServingsService,
        aRoute: ActivatedRoute,
        router: Router) {
        super(api, aRoute, router);
    }

    ngOnInit() {
        this.data = this.api.currentItem;
        this.loadcount = 0;
        this.contentType = this.aRoute.snapshot.data.type;
        for (let i of this.data.activeStreams) {
        // for (let i of this.data.inferenceStreams) {
            this.loadcount ++;
            this.api.getFilesStream(this.data.id, i.key).subscribe(
                (r) => {
                   const f = _.findWhere(r, {filename: `${this.aRoute.snapshot.data.type}.mp4`});
                   if (f) {
                       this.files.push({
                           stream: i.key,
                           files: r
                       });
                       this.url.push({
                           stream: i.key,
                           url: this.getUrl(i.key)
                       });
                   }
                },
                () => {},
                () => {
                    this.loadcount --;
                    // if (this.loadcount === this.data.activeStreams.length ) {
                    //    this.loadcount = -1;
                    // }
                    if (this.loadcount === 0 ) {
                       this.loadcount = -1;
                    }
                }
            );
        }
    }

    getUrl(stream: string) {
        return `${this.api.apiUrl}serving/${this.data.id}/stream/${stream}/files/${this.aRoute.snapshot.data.type}.mp4`;
    }
}
