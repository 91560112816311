import {CoreBaseService} from '@app/_core/base/service';
import {PaginateRequest, SubjectEvent} from '@app/_models';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { Input, OnDestroy, OnInit, TemplateRef, Directive } from '@angular/core';
import * as _ from 'underscore';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DialogService} from '@app/_services';
import {NgForm} from '@angular/forms';


//@Directive()
@Directive()
export class CoreBaseListConroller <T> implements OnDestroy, OnInit {

    api: CoreBaseService<any>;
    aRoute: ActivatedRoute;
    router: Router;
    list: any; //PaginateRequest<T>;
    page: any;
    loading: boolean;
    routerItemId = 'itemId';
    current: string;
    error: any;
    paramsSubscription: Subscription;
    querySubscription: Subscription;
    extAction: Subscription;
    @Input() params: any;
    defaultParams: any = {};
    @Input() action: any;
    dialogService: DialogService;
    modalRef: BsModalRef;

    constructor(api, aRoute, router, dialogService?: DialogService) {
        this.api = api;
        this.aRoute = aRoute;
        this.router = router;
        this.dialogService = dialogService;
    }

    ngOnInit(): void {
        if ( this.params ) {
        this.params = _.extend({}, this.defaultParams, this.params);
            this.getList(this.params);
        } else {
            this.params = this.params = _.extend({}, this.defaultParams);
            this.init();
            this.getCurrent();
        }

        this.extAction = this.api.extAction.subscribe((data: SubjectEvent) => {
            switch (data.type) {
                case 'updateList':
                    this.getList(data.data);
                    break;
            }
        });
    }

    init() {
        if ( this.router ) {
            this.paramsSubscription = this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.getCurrent();
                }
            });
        }

        if ( this.aRoute ) {
            this.querySubscription = this.aRoute.queryParams.subscribe(
                (queryParam: any) => {
                    this.params.page = queryParam['page'];
                    this.params.limit = queryParam['limit'] || localStorage.getItem('limitList') || 10;
                    this.getList();
                }
            );
        }
    }

    getCurrent(): string {
        if ( this.aRoute.snapshot.children && this.aRoute.snapshot.children[0] ) {
            this.current = this.aRoute.snapshot.children[0].params[this.routerItemId];
        } else {
            this.current = null;
        }
        return this.current;
    }

    prepareParamsBefore(params = {}) {
        let p = _.extend({}, this.params, params) ;
        let key = [];
        _.each( p, ( v, k) => {
            if ( v !== null && v !== 'null' && v !== undefined ) {
                key.push(k);
            }
        });
        p = _.pick( p, ...key );
        return p;
    }

    getList(params = {}) {
        this.error = null;
        this.loading = true;
        const req =  this.api.getAll(this.prepareParamsBefore(params));
            req.pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                r => {
                    // debugger
                    // this.list = r;
                    this.setList(r);
                    this.setParamsByResponce(r);
                    this.prepareListResponce();
                },
                (err) => {
                    this.error = err;
                }
            );
        return req;
    }

    setList(data) {
        this.list = data;
    }

    setParamsByResponce(r) {
       this.params = _.extend(this.params, _.omit(r, ['items', 'options']));
       // this.params = _.extend(this.params, r.options);
    }

    listOrder(field) {
        this.params.order = field;
        this.params.desc = this.params.desc ? false : true;
        this.getList();
    }

    goto(item) {
        this.router.navigate([item.id], {relativeTo: this.aRoute});
    }

    prepareListResponce() {}

    ngOnDestroy(): void {
        if ( this.paramsSubscription ) { this.paramsSubscription.unsubscribe(); }
        if ( this.querySubscription ) { this.querySubscription.unsubscribe(); }
        if ( this.extAction ) { this.extAction.unsubscribe(); }
    }

    openModal (template: TemplateRef<any>) {
        if ( this.dialogService ) {
            this.modalRef = this.dialogService.templateShow(template);
        }
    }
}
