<h5>{{title ? title : 'Audience Attention Span to Advertisement'}}</h5>
<div class="card mb-0" >
	<div class="card-body">
		<div class="chart-wrapper" style="min-height:300px;margin-top:40px;" *ngIf="complite">
			<canvas baseChart class="chart"
					[datasets]="graphData"
					[labels]="graphLabel"
					[options]="mainChartOptions"
					[colors]="mainChartColours"
					[legend]="mainChartLegend"
					[chartType]="mainChartType"></canvas>
		</div>
	</div>
</div>
<div class="small mb-3">
	{{desc ? desc : 'The attention span of the audience who are looking at a set advertisement measured by 3 variables, less than 10 seconds, 10-20 seconds, and more than 20 seconds. Time and count is used to measure when and how many audience members are looking at the advertisement.'}}
 </div>

<app-loader *ngIf="loading" class="over"></app-loader>
