<div class="flex-md-row row flex-column-reverse " >
    <div class="col-md-9 col">
        <div class="card">
            <div class="card-body" *ngIf="configMetrics">

                <div class="row printPageBraek" *ngIf="viewMetrics['app-dashboards-people-count-block']" >
                    <div class="col-12">
                        <app-dashboards-people-count-block [parent]="parent" [configData]="configMetrics" ></app-dashboards-people-count-block>
                    </div>
                </div>

                <div class="printPageBraek" *ngIf="viewMetrics['Traffic Timeline']">
                    <app-dashboards-line-timeline [index]="'traffic-timeline'"  [configData]="configMetrics"  [title]="'Traffic Timeline'" [desc]="' '" ></app-dashboards-line-timeline>
                </div>

                <div class="row printPageBraek" *ngIf="viewMetrics['app-dashboards-gender-age-count']" >
                    <div class="col-12">
                        <app-dashboards-gender-age-count [parent]="parent" [configData]="configMetrics" ></app-dashboards-gender-age-count>
                    </div>
                </div>


                <div class="printPageBraek" *ngIf="viewMetrics['Traffic table']">
                    <app-dashboards-traffic-table [index]="'traffic-timeline'"  [configData]="configMetrics" ></app-dashboards-traffic-table>
                </div>

                <div class="row printPageBraek" *ngIf="viewMetrics['app-dashboards-gender-age-count-pie']" >
                    <div class="col-12">
                        <app-dashboards-gender-age-count-pie [parent]="parent"  [configData]="configMetrics" ></app-dashboards-gender-age-count-pie>
                    </div>
                </div>

                <div *ngIf="viewMetrics['app-dashboards-gender-age-timeline']" class="printPageBraek">
                    <app-dashboards-gender-age-timeline  [configData]="configMetrics" ></app-dashboards-gender-age-timeline>
                </div>

                <div *ngIf="viewMetrics['app-dashboards-gender-age-timeline_group']" class="printPageBraek">
                    <app-dashboards-gender-age-timeline [bygender]="true"  [configData]="configMetrics" ></app-dashboards-gender-age-timeline>
                </div>

                <div *ngIf="viewMetrics['app-dashboards-gender-age-timeline-single']" class="printPageBraek">
                    <app-dashboards-gender-age-timeline-single  [configData]="configMetrics" ></app-dashboards-gender-age-timeline-single>
                </div>

                <div class="printPageBraek" *ngIf="viewMetrics['Audience Attention Span to Advertisement']">
                    <app-dashboards-line-timeline
                            [index]="'attention-timeline'"
                            [configData]="configMetrics" ></app-dashboards-line-timeline>
                </div>

                <div class="printPageBraek" *ngIf="viewMetrics['Audience Attention Span to Advertisement - Pie Chart']">
                    <app-dashboards-pie-count
                            [index]="'attention-count'"
                            [configData]="configMetrics"
                            [title]="'Audience Attention Span to Advertisement - Pie Chart'"
                            [desc]="'The attention span of the audience who are looking at a set advertisement measured by 3 variables, less than 10 seconds, 10-20 seconds, and more than 20 seconds. this pie chart is a total count of the recorded attention span.'"
                    ></app-dashboards-pie-count>
                </div>

                <!--<div class="printPageBraek" *ngIf="viewMetrics['Audience appearance']">-->
                    <!--<app-dashboards-line-timeline-->
                            <!--[index]="'appearance-timeline'"-->
                            <!--[configData]="configMetrics"-->
                            <!--[title]="'Audience appearance'"-->
                            <!--[desc]="' '" ></app-dashboards-line-timeline>-->
                <!--</div>-->
                <div class="printPageBraek" *ngIf="viewMetrics['Audience appearance']">
                    <app-dashboards-line-timeline
                            [index]="'appearance-timeline'"
                            [configData]="configMetrics"
                            [title]="'Dwell time'"
                            [desc]="' '" ></app-dashboards-line-timeline>
                </div>

                <div class="printPageBraek" *ngIf="viewMetrics['Audience appearance - Pie chart']">
                    <app-dashboards-pie-count
                            [index]="'appearance-count'"
                            [configData]="configMetrics"
                            [title]="'Audience appearance - Pie chart'"
                            [desc]="' '" ></app-dashboards-pie-count>
                </div>


                <div class="printPageBraek" *ngIf="viewMetrics['app-dashboards-people-timeline']">
                    <app-dashboards-line-timeline [index]="'people-timeline'"  [configData]="configMetrics"  [title]="'People Timeline'" [desc]="' '" ></app-dashboards-line-timeline>
                </div>

                <div class="printPageBraek" *ngIf="viewMetrics['app-dashboards-heatmap-timeline']">
                    <app-dashboards-line-timeline [index]="'heatmap-timeline'"  [configData]="configMetrics"  [title]="'Heatmap timeline'" [desc]="' '" ></app-dashboards-line-timeline>
                </div>


            </div>
        </div>
    </div>

    <div class="col-md-3  col toc" >
        <div class="card">
            <div class="card-body" *ngIf="config">


                <div class="form-group" *ngIf="streamsList">
                    <h5>Streams</h5>
                    <div *ngFor="let s of streams">
                        <div class="form-check" >
                            <input class="form-check-input"
                                   type="checkbox"
                                   [id]="'stream' + s.key"
                                   (change)="streamChange($event, s)"
                                   [checked]="streamsList[s.key]"
                            >
                            <label class="form-check-label" [for]="'stream' + s.key">
                                {{s.display_name || s.key}}
                            </label>
                        </div>

                    </div>
                    <hr/>
                </div>

                <div class="form-group">
                    <h5>Metrics</h5>
                    <ng-multiselect-dropdown
                            class="printDisplayNone"
                            [settings]="dropdownSettings"
                            [placeholder]="'select metrics'"
                            [data]="metricsList"
                            [(ngModel)]="metricsListSelected"
                            (onSelect)="setViewMetrics()"
                            (onDeSelect)="setViewMetrics()"
                            (onSelectAll)="setViewMetrics($event)"
                    ></ng-multiselect-dropdown>
                </div>
                <hr />
                <app-dashboards-filter
                        [groups]="{list: groups, selected: groupsSelected}"
                        [options]="{config: config}"
                        (changeConfig)="setConfig($event)"></app-dashboards-filter>
            </div>
        </div>
    </div>
</div>
