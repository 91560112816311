<div *ngIf="!current">

    <div class="clearfix mb-3" *ngIf="!action">
        <h4 class="float-left">Serving applications</h4>
        <!--<button *ngIf="!action" class="btn btn-primary btn-sm ml-3" (click)="goto({id:'create'})">add</button>-->
    </div>
    <!--<form #f="ngForm" (ngSubmit)="params.login = f.value.login; getList()" novalidate>-->
        <!--<div class=" form-row">-->
            <!--<div class="form-group col-9">-->
                <!--<input name="login" ngModel class="form-control" placeholder="login">-->
            <!--</div>-->
            <!--<div class="form-group col-3">-->
                <!--<button class="btn btn-default form-control">Search</button>-->
            <!--</div>-->
        <!--</div>-->
    <!--</form>-->


    <table class="table" *ngIf="list">
        <thead>
        <tr>
            <th>name</th>
            <th>stream type</th>
            <th>stream url</th>
            <th>registered</th>
            <th>assigned</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of list.items" (click)="goto(item)" class="clickabel">
            <td>{{item.name}}</td>
            <td>{{item.stream_type}}</td>
            <td>{{item.stream_url}}</td>
            <td>{{item.registered | date : 'short'}}</td>
            <td>
                <!--{{item.assigned}}-->
            <button class="btn btn-outline-primary" (click)="assignDataForm(item, servingAssign )">assign</button>
            </td>
        </tr>
        </tbody>
    </table>

    <!--<button *ngIf="action && action.type == 'delete'" class="btn btn-primary float-right" (click)="listAction()">add group</button>-->
</div>
<!--<app-pagination *ngIf="list" [page]="list.page" [count]="list.count" [limit]="list.limit"></app-pagination>-->
<app-pagination *ngIf="list" [link]="!action" (action)="getList($event.data)" [page]="list.page" [count]="list.count" [limit]="list.limit"></app-pagination>

<div class="row" *ngIf="current">
    <div class="col-9">
        <router-outlet></router-outlet>
    </div>
    <div class="col-3 border-left">
        <h6>Users</h6>
        <table class="table" *ngIf="list">
            <tbody>
            <tr *ngFor="let item of list.items" (click)="goto(item)" class="clickabel">
                <td>{{item.name}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
<app-loader class="over" *ngIf="loading"></app-loader>


<ng-template #servingAssign>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Serving assign</h5>
        <button type="button" class="close" (click)="modalRef.hide()" >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="assModel.form" (submit)="assignData()" >
            <div class="form-group row">
                <label class="col-sm-2 col-form-label col-form-label-sm">Channel name</label>
                <div class="col-sm-10">
                    <input class="form-control " formControlName="name">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label col-form-label-sm">Group</label>
                <div class="col-sm-10">
                    <select class="form-control " formControlName="group_id" required >
                        <option *ngFor="let i of groups" [value]="i.id">{{i.display_name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label col-form-label-sm">Type</label>
                <div class="col-sm-10">
                    <select class="form-control" formControlName="type" required >
                        <option selected> stream </option>
                        <option> videofile </option>
                    </select>
                </div>
            </div>
            <div>
                <button type="submit" >assign</button>
            </div>
        </form>
    </div>
</ng-template>
