<div class="alert alert-warning" *ngIf="error != null">
    <h4>Channel error</h4>
    <p>{{error.error.message}}</p>
    <div class="clearfix">
        <button *ngIf="currentUser && currentUser.admin" class="btn btn-primary float-right " (click)="error = null">hide</button>
    </div>
</div>
<div *ngIf="data && !data.edit">
    <div class="d-flex align-content-center">
        <button class="btn" (click)="goto()"><svg-icon src="assets/icon/back.svg" [svgStyle]="{ 'width.px':16 }"></svg-icon></button>
        <h4 class="flex-fill" style="margin: 0; line-height: 2;">Channel {{data.display_name}} <span class="badge badge-info">{{data.stream_type}}</span> <span *ngIf="data.health" class=" status {{data.health}} float-right" >{{data.health}}</span></h4>
        <div class="btn-group" dropdown *ngIf="!authUser.isPublic">
            <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle">
                <svg-icon src="assets/icon/dots-vertical.svg" ></svg-icon>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><span class="dropdown-item clickabel" (click)="publish($event)">{{data.public ? 'unpublish' : 'publish'}}</span></li>
                <li role="menuitem"><span class="dropdown-item clickabel" (click)="edit()">edit</span></li>
                <li role="menuitem"><span class="dropdown-item clickabel" (click)="deleteConfirm($event, 'Delete channel', 'Are you sure want to delete this channel')">delete</span></li>
            </ul>
        </div>

    </div>
    <p *ngIf="data.description">{{data.description}}</p>

    <ul class="nav nav-tabs d-block mt-3 ">
        <li class="nav-item" *ngFor="let i of getTabs()">
            <a [routerLink]="[tabs_description[i].link]" class="nav-link" [routerLinkActive]="'active'">{{tabs_description[i].title}}</a>
        </li>
    </ul>

    <!--<ul class="nav nav-tabs d-block mt-3 ">-->
        <!--<li class="nav-item">-->
            <!--<a [routerLink]="['dashboard']" class="nav-link" [routerLinkActive]="'active'">Dashboard</a>-->
        <!--</li>-->
        <!--<li class="nav-item" *ngIf="data.type === 'file'">-->
            <!--<a [routerLink]="['files']" class="nav-link" [routerLinkActive]="'active'">Video Files</a>-->
        <!--</li>-->
        <!--<li class="nav-item" *ngIf="data.type !== 'file'">-->
            <!--<a [routerLink]="['streams']" class="nav-link" [routerLinkActive]="'active'">Video Streams</a>-->
        <!--</li>-->
        <!--<li class="nav-item" *ngIf="!authUser.isPublic">-->
            <!--<a [routerLink]="['form']" class="nav-link" [routerLinkActive]="'active'">Configuration</a>-->
        <!--</li>-->
        <!--<li class="nav-item">-->
            <!--<a [routerLink]="['report']" class="nav-link" [routerLinkActive]="'active'">Details Report</a>-->
        <!--</li>-->
        <!--&lt;!&ndash;<li class="nav-item">&ndash;&gt;-->
            <!--&lt;!&ndash;<a [routerLink]="['persons']" class="nav-link" [routerLinkActive]="'active'">Details Report</a>&ndash;&gt;-->
        <!--&lt;!&ndash;</li>&ndash;&gt;-->
        <!--<li class="nav-item">-->
            <!--<a [routerLink]="['log']" class="nav-link" [routerLinkActive]="'active'">Log</a>-->
        <!--</li>-->
        <!--<li class="nav-item">-->
            <!--<a [routerLink]="['source']" class="nav-link" [routerLinkActive]="'active'">Source</a>-->
        <!--</li>-->
        <!--<li class="nav-item">-->
            <!--<a [routerLink]="['trackmap']" class="nav-link" [routerLinkActive]="'active'">Trackmap</a>-->
        <!--</li>-->
        <!--<li class="nav-item">-->
            <!--<a [routerLink]="['heatmap']" class="nav-link" [routerLinkActive]="'active'">Heatmap</a>-->
        <!--</li>-->
    <!--</ul>-->
    <router-outlet ></router-outlet>
</div>

<div class="alert alert-warning" *ngIf="data && data.edit && formConfig && complite >= 0 && data.form && groups && groups.items && groups.items.length == 0">
    <h4>Groups list empty.</h4>
    <p>To add a channel, you need a group.</p>
</div>
<div *ngIf="data && data.edit && formConfig && complite >= 0 && data.form && groups && groups.items && groups.items.length > 0">
    <form [formGroup]="data.form" >
    <!--<form [formGroup]="data.form" (submit)="saveData()" >-->
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">{{data.new ? 'Create channel' : 'Edit channel'}}</h5>
                <div class="row">

                    <div class="form-group col-6">
                        <label>Display name</label>
                        <input formControlName="display_name" class="form-control" required>
                        <div *ngIf="data.form.controls.display_name.invalid && submited"
                             class="alert alert-danger">
                            <div *ngIf="data.form.controls.display_name.errors.required">
                                display name is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label>Name</label>
                        <input formControlName="name" class="form-control" required [attr.disabled]="!data.new ? true : null">
                        <div *ngIf="data.form.controls.name.invalid && submited"
                             class="alert alert-danger">
                            <div *ngIf="data.form.controls.name.errors.required">
                                name is required.
                            </div>
                            <div *ngIf="data.form.controls.name.errors.pattern">
                                name pattern {{namePattern}}.
                            </div>
                        </div>
                    </div>
                    <div  class="form-group  col-6">
                        <label>Group</label>
                        <select  class="form-control" formControlName="group_id" [attr.disabled]="!data.new ? true : null" >
                            <option *ngFor="let item of groups.items; index as i; first as isFirst" [value]="item.id" >{{item.display_name}}</option>
                        </select>
                        <div *ngIf="data.form.controls.group_id.invalid && submited"
                             class="alert alert-danger">
                            <div *ngIf="data.form.controls.group_id.errors.required">
                                group_id is required.
                            </div>
                        </div>
                    </div>
                    <div  class="form-group  col-6">
                        <label>Source type</label>
                        <select  class="form-control" formControlName="type" [attr.disabled]="!data.new ? true : null" >
                            <option *ngFor="let item of types; index as i; first as isFirst" [value]="item" >{{item}}</option>
                        </select>
                        <div *ngIf="data.form.controls.type.invalid && submited"
                             class="alert alert-danger">
                            <div *ngIf="data.form.controls.type.errors.required">
                                type is required.
                            </div>
                        </div>
                    </div>


                    <div class="form-group col-6">
                        <label>Stream url <span class="small">(use for stream type file)</span></label>
                        <input formControlName="stream_url" class="form-control" >
                        <!--<input formControlName="stream_url" class="form-control"  [attr.disabled]="!data.new ? true : ( data.form.value.type == 'videofile' ? null : true )">-->

                    </div>

                    <!--<div class="form-group col-6">-->
                        <!--<label>Stream url <span class="small">(only for source type video file)</span></label>-->
                        <!--<input formControlName="stream_url" class="form-control"  [attr.disabled]="data.form.value.type == 'videofile' ? null : true ">-->
                        <!--&lt;!&ndash;<input formControlName="stream_url" class="form-control"  [attr.disabled]="!data.new ? true : ( data.form.value.type == 'videofile' ? null : true )">&ndash;&gt;-->

                    <!--</div>-->



                    <div  class="form-group  col-6">
                        <label>Channel type</label>
                        <select  class="form-control" formControlName="stream_type" (change)="getFormConfig()">
                            <option *ngFor="let item of data.stream_type_list; index as i; first as isFirst" [value]="item" >{{item}}</option>
                        </select>
                        <div *ngIf="data.form.controls.stream_type.invalid && submited"
                             class="alert alert-danger">
                            <div *ngIf="data.form.controls.stream_type.errors.required">
                                stream type is required.
                            </div>
                        </div>
                    </div>
                    <div  class="form-group  col-12">
                        <label>Description</label>
                        <textarea formControlName="description" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <app-form-constructor class="mb-3" [options]="formConfig.form"  [form]="this.data.form.controls.inferenceValues" [data]="data.new ? formConfig.defaultValues : data.inferenceValues" ></app-form-constructor>

        <div class="d-flex position-fixed clearfix m-0 align-items-center" style="bottom: 10px; right: 10px; background-color: rgba(255,255,255, .8)">
            <div class="alert alert-warning" *ngIf="error">
                <h4>Chanel error</h4>
                <p>{{error.error.message}}</p>
                <div class="clearfix">
                    <button class="btn btn-primary float-right " (click)="error = null">hide</button>
                </div>
            </div>
            <div *ngIf="data.form.invalid && formError" class="alert alert-danger m-0">
                Form field error
            </div>

            <button class="btn btn-default float-right m-2" (click)="canselEdit()">cancel</button>
            <button class="btn btn-primary float-right m-2"  (click)="saveData()">save</button>
        </div>
    </form>
</div>


<ng-template #chanelToken>
    <div class="modal-header">
        <h5 class="modal-title" id="errorPersonAction">Channel token</h5>
        <button type="button" class="close" (click)="hideKeyModel()" >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-info">
            <p>Remember this key for channel (<b>{{data.id}}</b>):</p>
            {{channelKey}}
        </div>
    </div>
    <div class="modal-footer">
        <div class="clearfix">
            <button class="btn btn-primary float-right " (click)="hideKeyModel()">ok</button>
        </div>
    </div>
</ng-template>

<ng-template #chanelError>
    <div class="modal-header">
        <h5 class="modal-title" id="errorPersonAction">Channel error</h5>
        <button type="button" class="close" (click)="modalRef.hide()" >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger">
            <p>{{error}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <div class="clearfix">
            <button class="btn btn-primary float-right " (click)="modalRef.hide()">ok</button>
        </div>
    </div>
</ng-template>
