import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {UserModel, PaginateRequest} from '../_models';
import {CoreBaseService} from '../_core/base/service';
import {Observable, Subject} from 'rxjs';
import {share} from 'rxjs/operators';
import {ServingRegisterModel, ServingRegisterAssignModel} from '@app/_models';

@Injectable({
    providedIn: 'root'
})

export class SettingsServingRegisterService extends CoreBaseService<ServingRegisterModel> {

    _pref = 'serving-register';
    current: any;
    config: any;
    public extAction = new Subject();
    // currentItem: ServingsModel;

    constructor(http: HttpClient) {
        super(http);
    }

    servingAssign(data: ServingRegisterAssignModel) {
        return this.http.post(this.getUrl() + `/assign`, data);
    }
}
