import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Event, UserModel, PaginateRequest, Person} from '@app/_models';
import {Subject} from 'rxjs';

// import {Apiservice} from '@app/_core';




@Injectable({
    providedIn: 'root'
})

export class EventsService  {
    apiUrl =  (window as any).externalConfig.apiUrl;
    protected pref = 'events';
    current: any;
    public extAction = new Subject();

    constructor( private http: HttpClient ) {}

    getUrl(params?: string ) {
        return `${this.apiUrl}${this.pref}` + ( params ? `/${params}` : '');
    }

    getAll(params) {
        var p = this.httpParams(params);
        var gp:any ;

        if ( p ) {
            gp = {params: p};
        }
        return this.http.get<PaginateRequest<Event>>(this.getUrl(), gp);
    }

    getById(id: string) {
        return this.http.get<Event>(this.getUrl(`${id}`));
    }

    update(item: Event) {
        return this.http.put(this.getUrl(`${item.id}`), item);
    }

    delete(id: string ) {
        return this.http.delete(this.getUrl(`${id}`));
    }

    save(item: Event) {
        if ( item.id ) {
            return this.http.put<Event>(this.getUrl(`${item.id}`), item.toJSON());
        } else  {
            return this.http.post<Event>(this.getUrl(), item.toJSON());
        }
    }

    getPersons(item: Event | string, params?: any) {
        var p = this.httpParams(params);
        var gp:any ;
        if ( p ) {
            gp = {params: p};
        }
        let id = item instanceof Event ? item.id : item;
        return this.http.get(this.getUrl(id) + '/persons', gp );
    }

    addPerson(event: Event | string, person: Person | string) {
        const eventId = event instanceof Event ? event.id : event;
        const personId = person instanceof Person ? person.id : person;
        return this.http.put(this.getUrl(eventId) + `/persons/${personId}`, {});
    }

    deletePerson(event: Event | string, person: Person | string) {
        const eventId = event instanceof Event ? event.id : event;
        const personId = person instanceof Person ? person.id : person;
        return this.http.delete(this.getUrl(eventId) + `/persons/${personId}`);
    }

    addUser(event: Event | string, user: UserModel | string) {
        const eventId = event instanceof Event ? event.id : event;
        const itemId = user instanceof UserModel ? user.login : user;
        return this.http.put(this.getUrl(eventId) + `/users/${itemId}`, {});
    }

    deleteUser(event: Event | string, user: UserModel | string) {
        const eventId = event instanceof Event ? event.id : event;
        const itemId = user instanceof UserModel ? user.login : user;
        return this.http.delete(this.getUrl(eventId) + `/users/${itemId}`);
    }

    httpParams(p?: any) {
        if ( p ) {
            let params = new HttpParams();
            for ( let i in p ){
                params = params.append(i, p[i]);
            }
            return params;
        }

        return null;
    }
}
