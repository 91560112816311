import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filesize' })
export class FileSizePipe implements PipeTransform {
  transform(size) {
    if(size === undefined) {
      return '-';
    }
    var s = size ;

    if ( s < 1000 ) {
      return `${s}b`;
    }

    s = size / (1024 );
    if ( s < 1000 ) {
      return `${s.toFixed(1)}Kb`;
    }

    s = size / ( 1024 * 1024 );
    if ( s <  1000 ) {
      return `${s.toFixed(1)}Mb`;
    }

    s = size / ( 1024 * 1024 * 1024 );
    return `${s.toFixed(1)}Gb`;
  }
}
