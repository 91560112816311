import { OnInit, OnDestroy, OnChanges, SimpleChanges, TemplateRef, Directive } from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {PersonsService} from '@app/pages/persons/persons.service';
import * as _ from 'underscore';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {finalize} from 'rxjs/operators';

@Directive()
export class PersonsBase implements OnInit, OnDestroy, OnChanges {

    list: any;
    page: number;
    filter: any = {};
    error: any;
    private querySubscription: Subscription;
    private paramsSubscription: Subscription;
    private api: PersonsService;
    private apiEvent: Subscription;
    serach: any;
    filterOn = false;
    fullInfo = false;
    modalService: BsModalService;
    modalRef: BsModalRef;
    currentPItem: any;
    currentId: string;
    aRouter: ActivatedRoute ;
    router: Router;
    loading = false;

    constructor(api: PersonsService, aRoute?: ActivatedRoute, modalService?: BsModalService, router?: Router) {
        this.initSearch();
        this.aRouter = aRoute;
        this.router = router;
        this.api = api;
        this.modalService = modalService;
        this.apiEvent = this.api.events.subscribe( r => {
            this.getList();
        });

        if ( router ) {
            this.paramsSubscription = router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.getCurrentId();
                }
            });
        }

        if(aRoute) {
            this.querySubscription = aRoute.queryParams.subscribe(
                (queryParam: any) => {
                    this.page = queryParam['page'];
                    this.getList();
                }
            );
        }
        if ( modalService ) {
            this.modalService = modalService;
        }
    }

    ngOnInit() {
        this.init();
    }

    init() {}

    ngOnChanges(changes: SimpleChanges): void {
        for (let c in changes ) {
            switch ( c ) {
                case 'filter':
                case 'extAction':
                    this.getList();
                    break;
            }
        }
    }

    initSearch(update?: boolean) {
        this.filterOn = false;
        this.serach = {name: null, company: null, position: null};
        if ( update ) { this.getList(); }
    }

    filterAction (event: any) {
        this.serach = event.data;
        switch (event.action) {
            case 'close':
                this.filterOn = false;
            case 'list':
                this.getList();

        }
    }

    getList() {
        let params = this.filter ? this.filter : {};
        params['page'] = this.page ? this.page : 1;

        params = _.extend({}, this.serach, params);

        this.loading = true;
        this.api.getAll(params)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
            r => {
                this.list = r;
            },
            (err) => {
                this.error = err;
            }
        );
    }

    ngOnDestroy(): void {
        if ( this.apiEvent ) {
            this.apiEvent.unsubscribe();
        }
        if ( this.querySubscription ) {
            this.querySubscription.unsubscribe();
        }
        if ( this.paramsSubscription ) {
            this.paramsSubscription.unsubscribe();
        }
    }

    clickItem( item, template: TemplateRef<any> ) {
        if ( this.modalService && this.fullInfo ) {
            this.modalRef = this.modalService.show(template,
                {
                    initialState : { item : item }
                });
        }
    }

    getCurrentId() {
        if ( this.aRouter.snapshot.children && this.aRouter.snapshot.children[0] ) {
            this.currentId = this.aRouter.snapshot.children[0].params.itemId;
        } else {
            this.currentId = null;
        }
    }

    goto(item) {
        this.router.navigate(['/persons', item.id]);
    }
}
