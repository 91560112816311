import {finalize} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, SimpleChanges, OnChanges, Directive } from '@angular/core';
import * as _ from 'underscore';
import {Subscription} from 'rxjs';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

//@Directive()
@Directive()
export class DashboardsBase implements OnDestroy, OnChanges {

    @Input() configData: any = {};
    @Input() title: string;
    @Input() desc: string;

    loading: boolean;
    error: any;
    extActionSubscription: Subscription;

    constructor ( public  api ) {}

    init() {}

    ngOnChanges(changes: SimpleChanges) {
        if ( changes.configData ) {
            this.init();
        }
    }

    getDataIndex (index: string, data = {}, update?) {
        //
        // if (index === 'gender-age-timeline') {
        //     debugger
        // }

        if( !this.extActionSubscription) {
            this.extActionSubscription = this.api.extAction.subscribe( ( x ) => {
                this.init();
            });
        }

        let d = _.extend({} , data, this.configData);
        let loadedParam  = JSON.stringify( d );

        if(update) delete this.api.loadedData[index];
        if( this.api.loadedData[index] &&  this.api.loadedData[index][loadedParam] &&  this.api.loadedData[index][loadedParam].data ) {
            this.prepareResponce(this.api.loadedData[index][loadedParam].data, index, data);
        } else if ( this.api.loadedData[index] &&  this.api.loadedData[index][loadedParam] &&  this.api.loadedData[index][loadedParam].promise ) {
            this.api.loadedData[index][loadedParam].promise
                .subscribe(
                    r => {
                        this.api.loadedData[index] = {};
                        this.api.loadedData[index][loadedParam] = r;
                        this.prepareResponce(r, index, data);
                    },
                    err => {
                        this.error = err;
                        this.prepareError(err, index, data);
                    }
                );
        } else {
            this.error = null;

            this.loading = true;
            this.api.loadedData[index] = {[loadedParam] : {}};
            // debugger
            const req = this.api.getDataIndex(index, d);
            this.api.loadedData[index][loadedParam].promise = req;
            req
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    })
                )
                .subscribe(
                    r => {
                        this.api.loadedData[index] = {};
                        this.api.loadedData[index][loadedParam] = { data: r };
                        this.prepareResponce(r, index, data);
                    },
                    err => {
                        this.error = err;
                        this.prepareError(err, index, data);
                    }
                );
        }
        }

    prepareResponce(r, index?, data?) {}

    prepareError(err, index?, data?) {}

    ngOnDestroy () {
        if( this.extActionSubscription) this.extActionSubscription.unsubscribe();
    }

    mainChartOptions = {
        bezierCurve: false,
        responsive: true,
        legend: {
            display: true
        },
        plugins: {
            datalabels: {
                display: false,
                font: {
                    weight: 'bold'
                },
                position: 'outside'
            }
        },
        scales: {
            xAxes: [{
                type: 'time',
                autoSkip: false,
                time: {
                    unit: 'second',
                    unitStepSize: 10
                },
                scaleLabel: {
                    display: true
                },
                // type: 'time',
                // time: {stepSize: 10},
                // // gridLines: {
                // //     drawOnChartArea: false,
                // // },
                // distribution: 'series'
                // // ticks: {
                // //     callback: function(value: any, y, z) {
                // //         debugger;
                // //         // return value.charAt(0);
                // //     }
                // // }
            }],
            yAxes: [{
                // ticks: {
                //     beginAtZero: true,
                //     maxTicksLimit: 5,
                //     stepSize: Math.ceil(250 / 5),
                //     max: 250
                // }
            }]
        }
    };



    // public mainChartOptions_: any = {
    //     tooltips: {
    //         enabled: false,
    //         custom: CustomTooltips,
    //         intersect: true,
    //         mode: 'index',
    //         position: 'nearest',
    //         callbacks: {
    //             labelColor: function(tooltipItem, chart) {
    //                 return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
    //             }
    //         }
    //     },
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     scales: {
    //         xAxes: [{
    //             gridLines: {
    //                 drawOnChartArea: false,
    //             },
    //             ticks: {
    //                 callback: function(value: any) {
    //                     return value.charAt(0);
    //                 }
    //             }
    //         }],
    //         yAxes: [{
    //             ticks: {
    //                 beginAtZero: true,
    //                 maxTicksLimit: 5,
    //                 stepSize: Math.ceil(250 / 5),
    //                 max: 250
    //             }
    //         }]
    //     },
    //     elements: {
    //         line: {
    //             borderWidth: 2
    //         },
    //         point: {
    //             radius: 0,
    //             hitRadius: 10,
    //             hoverRadius: 4,
    //             hoverBorderWidth: 3,
    //         }
    //     },
    //     legend: {
    //         display: false
    //     }
    // };

    public mainChartColours: Array<any> = [
        {
            backgroundColor: 'transparent',
            borderColor: '#1f1498',
            pointHoverBackgroundColor: '#fff'
        },
        {
            backgroundColor: 'transparent',
            borderColor: '#2982cc',
            pointHoverBackgroundColor: '#fff'
        },
        {
            backgroundColor: 'transparent',
            borderColor: '#f6960b',
            pointHoverBackgroundColor: '#fff'
        },
        {
            backgroundColor: 'transparent',
            borderColor: '#d93737',
            pointHoverBackgroundColor: '#fff'
        },
        {
            backgroundColor: 'transparent',
            borderColor: '#1f1498',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5]
        },
        {
            backgroundColor: 'transparent',
            borderColor: '#2982cc',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5]
        },
        {
            backgroundColor: 'transparent',
            borderColor: '#f6960b',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5]
        },
        {
            backgroundColor: 'transparent',
            borderColor: '#d93737',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5]
        }
    ];


    public mainChartColours_: Array<any> = [
        { // brandInfo
            backgroundColor: hexToRgba(getStyle('--info'), 10),
            borderColor: getStyle('--info'),
            pointHoverBackgroundColor: '#fff'
        },
        { // brandSuccess
            backgroundColor: 'transparent',
            borderColor: getStyle('--success'),
            pointHoverBackgroundColor: '#fff'
        },
        { // brandDanger
            backgroundColor: 'transparent',
            borderColor: getStyle('--danger'),
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5]
        }
    ];



    public mainChartLegend = true;
    public mainChartType = 'line';
}
