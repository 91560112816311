import {Component, OnInit, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {EventsService} from '@app/pages/events/events.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Event} from '@app/_models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

/* http://localhost:4201/#/persons/ab283ce4-d414-49fe-922d-687dd51b107b */

@Component({
  selector: 'app-event-item-person',
  template: `
      <div class="d-flex justify-content-between mb-3">
        <!--<h4 class="col-auto pl-0">Persons</h4>-->
          <span class="col-auto"></span>
        <button type="button" class="btn btn-primary col-auto" (click)="openModal(addEventPerson)" >add person</button>
      </div>
      <div>
        <app-persons-event [filter]="filterPersons" *ngIf="filterPersons" [env]="'list'" (action)="action($event)" title="event persons" [extAction]="extAction" [fullInfo]="true" ></app-persons-event>
      </div>
      <ng-template #errorPersonAction>
          <div class="modal-header">
              <h5 class="modal-title" id="errorPersonAction">Error</h5>
              <button type="button" class="close" (click)="modalRef.hide()" >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <div class="alert alert-danger">{{error | json}}</div>
          </div>
      </ng-template>
      <ng-template #addEventPerson>
        <div class="modal-header d-flex align-items-center">
          <h5 class="modal-title col " id="exampleModalLabel">Add persons </h5>
          <button (click)="changeEnv()" class="btn btn-primary" >{{env === 'list' ? 'add new person' : 'list'}}</button>
          <button type="button" class="close" (click)="modalRef.hide()" ><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body" >
          <app-persons-event *ngIf="env === 'list' && filterPersons" [filter]="filterNotPersons" [env]="'add'" (action)="action($event)" title="event add person" [extAction]="extAction"></app-persons-event>
          <app-person-item *ngIf="env === 'new person'" [view]="'short'" [item]="{event_id: itemId}" [eventAction]="addNewPersinaction"></app-person-item>
        </div>
      </ng-template>
  `,
  // styleUrls: ['./item.component.scss']
})
export class EventsItemPersonComponent implements OnInit, OnDestroy {
    @ViewChild('errorPersonAction', { static: true }) private errorTemplate: TemplateRef<any>;
  itemId: string;
  filterPersons: any;
  filterNotPersons: any;
  extAction = new Subject();
  paramsSubscription: Subscription;
  modalRef: BsModalRef;
  env = 'list';
  addNewPersinaction = new Subject();
  error:any;

  constructor( private activatedRoute: ActivatedRoute, private api: EventsService, router: Router, private modalService: BsModalService) {
    this.itemId = activatedRoute.parent.snapshot.params.itemId;
    this.paramsSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.init();
      }
    });
    this.addNewPersinaction.subscribe( ev => {
        this.actuinNewPersonEvent(ev);
    });
  }

  actuinNewPersonEvent(ev: any) {
      const action = (ev && ev.action) ? ev.action : '';
      switch (action) {
          case 'add':
              this.action(ev);
              break;
          case 'cancel':
              this.modalRef.hide();
              break;
      }
  }

  init() {
    this.itemId = this.activatedRoute.parent.snapshot.params.itemId;
    this.filterPersons = {event_id: this.itemId};
    this.filterNotPersons = {not_event_id: this.itemId};
  }

  ngOnInit() {
    this.init();
  }

  openModal(template: TemplateRef<any>) {
    this.env = 'list';
    this.modalRef = this.modalService.show(template);
  }

  action(ev ) {
    // debugger;
      this.error = null;
    switch (ev.action) {
      case 'delete':
        this.api.deletePerson( this.itemId, ev.data.id ).subscribe(
            r => {
              this.extAction.next({ev});
            },
            err => {
                this.error = err;
                this.openModal(this.errorTemplate);
            }
        );
        break;
      case 'add':
        this.api.addPerson( this.itemId, ev.data.id ).subscribe(
            r => {
              this.extAction.next({ev});
            },
            err => {
                this.error = err;
                this.openModal(this.errorTemplate);
            }
        );
        break;
    }
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.addNewPersinaction.unsubscribe();
  }


  changeEnv() {
    this.env = this.env === 'list' ? 'new person' : 'list';
  }
}
