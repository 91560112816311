import { OnInit, Input, SimpleChanges, OnChanges, OnDestroy, Directive } from '@angular/core';
export * from './item/item.component';
import {PaginateRequest, Stream} from '@app/_models';
import {StreamService} from '@app/pages/stream/stream.service';
import {finalize} from 'rxjs/operators';
import {CoreBaseService} from '@app/_core/base/service';
import {ActivatedRoute, Router} from '@angular/router';

@Directive()
export class StreamBaseComponent implements OnInit, OnChanges, OnDestroy {

    current: Stream;
    listClass = [];
    list: any; //PaginateRequest<Stream[]>;
    parent: any;
    error: any;
    apiPref: string;
    loading = false;
    public  api: CoreBaseService<Stream>;
    router: Router;
    aRouter: ActivatedRoute;
    page: number;

    constructor( api: StreamService, router, aRouter) {
        this.api = api;
        this.router = router;
        this.aRouter = aRouter;
        this.apiPref = api.pref;
    }

    ngOnInit() {
        this.aRouter.paramMap.subscribe(params => {
            const id = params.get( "streamId" );
            if ( !id ) {
                this.api.current = null;
            }
            this.getList();
        });

    }

    ngOnDestroy(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (let c in changes ) {
            switch ( c ) {
                case 'parent':
                    // this.getList();
                    break;
            }
        }
    }

    chagePage(ev){
        switch (ev.type) {
            case 'changePage':
                this.page = ev.data;
                this.getList();
                break;
        }
    }

    getList() {
        this.api.pref = this.parent ? `${this.parent.type}/${this.parent.data}/` : '';
        this.loading = true;
        const params = { page : this.page ? this.page : 1};
        this.api.getAll( params )
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe(
                (r) => {
                    this.list = r;
                },
                (err) => {
                    this.error = err;
                }
            );
    }

    goToItem (item, params?, ev?) {
        if ( ev ) ev.stopPropagation();
        if ( item ) {
            const p = [item.id];
            // if (params) p.push({queryParams: params});
            this.router.navigate(p, {queryParams: params, relativeTo: this.aRouter});
        }
        else
            this.router.navigate([ '../stream' ], {relativeTo: this.aRouter});
    }
}
