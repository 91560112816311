<h5 class="">All Recent Traffic</h5>
<div class="card mb-0">
	<div class="card-body">
		<!--<h4 class="card-title mb-0">Traffic gender age single</h4>-->
		<div class="row" *ngFor="let gender of graphData | keyvalue">
			<div class="col-sm-6 col-lg-3 mt-2 mb-2" *ngFor="let item of gender.value; let i=index">
				<div class="card text-white bg-info " [class]="'card text-white mb-0 bg-gradient-' + i" style="height: 100%">
					<div class="card-body pb-0">
						<button type="button" class="btn btn-transparent p-0 float-right">
							<i class="icon-location-pitein"></i>
						</button>
						<div class="text-value">{{item[0].count}}</div>
						<div>{{item[0].label}}</div>
					</div>
					<div class="chart-wrapper mt-3 mx-3" style="height:70px;">
						<canvas baseChart class="chart"
								[datasets]="item"
								[labels]="graphLabel"
								[options]="lineChart2Options"
								[colors]="lineChart2Colours"
								[legend]="lineChart2Legend"
								[chartType]="lineChart2Type"></canvas>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="small mb-3">
	Collection of all traffic charts that showcases the overall traffic within a specific gender and age. The upscale traffic is based on time. So if the line in increasing, it is multiple audience members within the time frame.
</div>
<app-loader *ngIf="loading" class="over"></app-loader>
