import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { PersonsService } from '@app/pages/persons/persons.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Person, Image } from '@app/_models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'underscore';
import {finalize} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {DialogService} from '@app/_services/dialog,service';

class ImageSnippet {
    pending = false;
    status = 'init';

    constructor(public src: string, public file: File) {}
}

class CEvemt {
    action: string;
    data: any;
}

@Component({
    selector: 'app-person-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class PersonsItemComponent implements OnInit, OnDestroy {

    @Input() item: Person ;
    @Input() eventAction: Subject<any> ;
    @Input() view = 'full';
    error: any;
    form: FormGroup;
    isEdit = false;
    saving: boolean;
    images: any;
    selectedFile: ImageSnippet;
    imageError: any;
    loading = false;
    private apiEvent: Subscription;

    constructor( private api: PersonsService, private activatedRoute: ActivatedRoute,   private router: Router, private dialogService: DialogService) { }

    ngOnInit() {
        this.apiEvent = this.api.events.subscribe(r => {
            this.imageUpdated(r);
        });

        this.error = null;
        if (this.item) {
            this.setItem(this.item);
        } else {
            this.activatedRoute.paramMap.subscribe(params => {
                const itemId = params.get("itemId");
                if (!itemId || itemId === 'create') {
                    this.item = new Person();
                    this.asEdit();
                } else {
                    this.getInfo(itemId);
                }
            });

        }
    }

    getInfo(itemId) {
        this.loading = true;
        this.api.getById( itemId )
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
                (r) => {
                    this.setItem(r);
                },
                (err) => {
                    this.error = err;
                }
            );
    }

    imageUpdated (ev:any) {
        if ( this.item.id === ev.id) {
            switch (ev.action) {
                case 'add image':
                    this.getInfo(ev.id);
                    break;
            }
        }
    }

    setItem(data) {
        this.item = Object.assign(new Person(), data);
        if ( this.eventAction ) {
            this.asEdit();
        } else {
            // this.getImages();
        }
    }

    // getImages() {
    //     this.api.getImages(this.item, {fullPath: true}).subscribe(
    //         r => {
    //             this.images = r;
    //         },
    //         err => {
    //             this.error = err;
    //         }
    //     );
    // }

    asEdit() {
        this.isEdit = !this.isEdit;
        if ( this.isEdit ) {
            this.form = this.item.getForm();
        } else {
            this.form = null;
        }
    }

    onSubmit() {
        this.error = null;
        this.saving = true;
        if (this.form.invalid) {
            return;
        }

        this.item.updateDataForm();

        this.api.save(this.item)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(
            r => {
                this.item = Object.assign( new Person(), r);
                this.asEdit();
                if ( this.eventAction ) {
                    this.eventAction.next({action: 'add', data: r});
                } else {
                    this.router.navigate(['/persons', r.id]);
                }
            },
            err => {
                this.error = err;
            }
        );
    }

    get f() { return this.form.controls; }

    delete() {
        this.dialogService.confirmShow( 'Delete person', `Are you sure want to delete this person ${this.item.name}`)
            .subscribe( r => {
                if (r) {
                    this.error = null;
                    this.api.delete(this.item.id)
                        .subscribe(
                            r => {
                                this.router.navigate(['/persons']);
                            },
                            err => {
                                this.error = err;
                            }
                        );
                }
        });
    }

    cancel() {
        if ( this.eventAction ) {
            this.eventAction.next({action: 'cancel'});
        } else {
            if ( !this.item.id ) {
                this.router.navigate(['/persons']);
            } else {
                this.asEdit();
            }
        }
    }

    ngOnDestroy(): void {
        if ( this.apiEvent ){
                this.apiEvent.unsubscribe();
        }
    }
}
