<div class="card">
    <div class="card-header">
        Model params
    </div>
    <div class="card-body">
        <table class="w-100" class="table">
            <thead>
            <tr>
                <th>label</th>
                <th>value</th>
                <th style="text-align: center; width: 105px;">
                    <button (click)="addItem()" class="btn btn-primary">+ param</button>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let item of getControls(); let i = index;">
            <tr [formGroup]="item" >
                <td><input formControlName="label" class="form-control" (blur)="apply()" ></td>
                <td><input formControlName="value" class="form-control" (blur)="apply()" ></td>
                <td style="tet-align: center; width: 105px"><button (click)="deleteRow('model_params', i)" class="btn btn-danger">Delete</button></td>
            </tr>
            </tbody>
            <tr *ngIf="getControls() && getControls().length > 1">
                <td colspan="2"></td>
                <td>
                    <button (click)="addItem()" class="btn btn-primary">+ param</button>
                </td>
            </tr>
        </table>
    </div>
</div>
