<div *ngIf="loadcount < 0 && files && files.length" >
    <div *ngFor=" let i of url" class="pt-2">
        <h4>{{i.stream}}</h4>
        <div class="card p-2 text-center">
            <div class="player">
                <div class="img">
                    <video class="" id="videoElement" #videoElement controls="controls" style="height: 480px" (playing)="onPlayingVideo($event)">
                        <source [src]="i.url">
                    </video>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loadcount < 0 && files && files.length == 0" class="card p-3">
    <div class="alert alert-warning text-center">
        Files empty
    </div>
</div>
<app-loader *ngIf="loadcount > 0"></app-loader>

<!--<div> {{loading | json }}</div>-->
<!--<div>{{files | json}}</div>-->
