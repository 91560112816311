import { Component, OnInit, OnDestroy } from '@angular/core';
import {EventsService} from '@app/pages/events/events.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Event} from '@app/_models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {DialogService} from '@app/_services/dialog,service';

@Component({
	selector: 'app-event-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss']
})
export class EventsItemComponent implements OnInit, OnDestroy {

	item: Event ;
	error: any;
	form: FormGroup;
	isEdit = false;
	saving: boolean;
	images: any;
	filterPersons: any;
	filterNotPersons: any;
	extAction = new Subject();
	loading = false;
	private modalRef: BsModalRef;

	constructor(
		private api: EventsService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private modalService: BsModalService,
		private dialogService: DialogService
	) { }

	ngOnInit() {
		this.error = null;
		this.activatedRoute.paramMap.subscribe(params => {
			const itemId = params.get( "itemId" );
			if ( itemId === 'create' ) {
				// this.item = new Event();
				this.setItem();
				this.asEdit();
			} else {
				this.loading = true;
				this.api.getById( itemId )
					.pipe(
						finalize(() => {
							this.loading = false;
						})
					)
					.subscribe(
						(r) => {
							// this.item = new Event(r);
							this.setItem(r);
							this.filterPersons = {event_id: this.item.id};
							this.filterNotPersons = {not_event_id: this.item.id};
							// this.getImages();
						},
						(err) => {
							this.error = err;
						}
					);
			}
		});
	}

	setItem(data?) {
		this.item = new Event(data);
		this.api.current = this.item;
	}

	asEdit() {
		this.isEdit = !this.isEdit;
		if ( this.isEdit ) {
			this.form = this.item.getForm();
		} else {
			this.form = null;
		}
	}

	onSubmit() {
		this.error = null;
		this.saving = true;
		if (this.form.invalid) {
			return;
		}

		this.item.updateDataForm();

		this.api.save(this.item).subscribe(
			r => {
				this.item = Object.assign( new Event(), r);
				this.asEdit();
				this.router.navigate(['/events', r.id]);
				this.saving = false;
				this.api.extAction.next({action: 'change', data: this.item});
			},
			err => {
				this.error = err;
				this.saving = false;
			}
		);
	}

	get f() { return this.form.controls; }

	delete() {

		this.dialogService.confirmShow( 'Delete event', `Are you sure want to delete this event ${this.item.title}`)
			.subscribe( result => {
					if (result) {
						this.error = null;
						this.api.delete(this.item.id)
							.subscribe(
								r => {
									this.api.extAction.next({action: 'delete', data: this.item});
									this.router.navigate(['/events']);
								},
								err => {
									this.error = err;
								}
							);
					}
				}
			);
	}

	cancel() {
		if ( !this.item.id ) {
			this.router.navigate(['/events']);
		} else {
			this.asEdit();
		}
	}

	action(ev) {
		switch (ev.action) {
			case 'delete':
				this.api.deletePerson(this.item, ev.data).subscribe(
					r => {
						this.extAction.next({ev});
						this.api.extAction.next({ev});

					},
					err => {
						debugger;
					}
				);
				break;
			case 'add':
				this.api.addPerson(this.item, ev.data).subscribe(
					r => {
						this.extAction.next({ev});
						this.api.extAction.next({ev});
						// debugger;
					},
					err => {
						debugger;
					}
				);
				break;
		}
	}

	ngOnDestroy(): void {
		this.extAction.complete();
		this.api.current = null;
	}

	exportLink () {
		const navigator = (window as any).navigator;
		const url = (window as any).location.origin + '/' + this.api.getUrl(this.item.id) + '/export';
		navigator.clipboard.writeText(url)
			.then(() => {
				this.dialogService.messageShow('export link', `Event model URL "${url}" has been copied to clipboard`).subscribe();
			})
			.catch(err => {
				this.dialogService.messageShow('export link', `Event model URL "${url}".`).subscribe();
			});
	}


}
