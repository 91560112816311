import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '@app/_services';
import {CurrentUser} from '@app/_models';
import {finalize} from 'rxjs/operators';

@Component({
	selector: 'app-user-header',
	templateUrl: './header.component.html'
})

export class UserHeaderComponent implements OnInit {

	token: any;
	tokenError: any;
	@ViewChild('userToken', { static: true }) private userToken: TemplateRef<any>;
	modalRef: BsModalRef;
	public currentUser: CurrentUser;
	loading: boolean;

	constructor(
		private api: AuthenticationService,
		private modalService: BsModalService,
		private router: Router,
		private aRouter: ActivatedRoute
	) {


	}

	ngOnInit() {
		this.api.currentUser.subscribe((user) => {
			// debugger;
			this.currentUser = user; //new CurrentUser(user);
		});
	}

	getToken() {
		this.loading = true;
		this.tokenError = null;
		this.openModal(this.userToken);
		this.api.getToken()
			.pipe(
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe(
			r => {
				this.token = r;
			},
			err => {
				this.tokenError = err;
			}
		);
	}

	changeToken() {
		this.tokenError = null;
		this.loading = true;
		this.api.changeToken()
			.pipe(
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe(
			r => {
				this.token = r;
			},
			err => {
				this.tokenError = err;
			}
		);
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}

}
