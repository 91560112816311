import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
// import {ServingsModel} from './model';
import {UserModel, PaginateRequest} from '../_models';
// import {UserModel} from '@app/_models/model';
import {CoreBaseService} from '../_core/base/service';
import {Observable, Subject} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class SettingsUsersService extends CoreBaseService<UserModel> {
    _pref = 'users';
    current: any;
    config: any;
    public extAction = new Subject();
    // currentItem: ServingsModel;

    constructor(http: HttpClient) {
        super(http);
    }



}
