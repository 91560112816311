import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mediaLength' })
export class MediaLengthPipe implements PipeTransform {
  transform(ts) {
    if(ts === undefined) {
      return '-';
    }
//conversion based on seconds
    var hh = Math.floor( ts / 3600);
    var mm = Math.floor( (ts % 3600) / 60);
    var ss = Math.floor((ts % 3600) % 60 );

//prepend '0' when needed
    var hhs = hh < 10 ? '0' + hh : hh;
    var mms = mm < 10 ? '0' + mm : mm;
    var sss = ss < 10 ? '0' + ss : ss;

//use it
    return `${hhs}:${mms}:${sss}`;

  }
}
