import {Component, Input, OnInit} from '@angular/core';
import {DashboardsService} from '@app/dashboards/dashboards.service';
import {DashboardsBase} from '@app/dashboards/items/dashboards_base';

@Component({
    selector: 'app-dashboards-gender-age-count-pie',
    templateUrl: './gender-age-count-pie.component.html',
    styleUrls: ['./gender-age-count-pie.component.scss']
})
export class GenderAgeCountPieComponent extends DashboardsBase implements OnInit {

    @Input() parent: any;
    data: any;
    genderCount: any = {};
    listError: any;
    complite = false;
    groupList = [];
    dataCount = {
        data: [],
        label: [],
        count: 0
    };


    constructor( api: DashboardsService ) {
        super(api);
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.getDataIndex('gender-count');
    }

    prepareResponce(r, index?, data?) {
        switch (index) {
            case 'gender-count':
                this.prepareCount(r);
                this.getDataIndex('gender-age-count');
                break;
            case 'gender-age-count':
                this.data = this.prepare(r);
                this.complite = true;
                break;
        }
    }

    prepareCount(r) {
        this.dataCount = {
            data: [],
            label: [],
            count: 0
        };
        this.genderCount['total'] = 0;
        for ( let item of r.classes ) {
            this.genderCount[item.id] = item;
            this.genderCount[item.id]['values'] = {};
            this.genderCount['total'] += item.value;

            this.dataCount.count += item.value;
            this.dataCount.label.push(item.title);
            this.dataCount.data.push(item.value);
        }
    }

    prepare(data) {
        this.pieData = {};
        this.pieLabels = {};

        for ( let item of data.classes ) {
            // debugger
            if (item.group[1].id !== 'undefined') {

                if (!this.pieData[item.group[0].id]) {
                    this.pieData[item.group[0].id] = [];
                    this.pieData[item.group[0].id + '_count'] = 0;
                }
                this.pieData[item.group[0].id].push(item.value);
                this.pieData[item.group[0].id + '_count'] += item.value;
                if (!this.pieLabels[item.group[0].id]) {
                    this.pieLabels[item.group[0].id] = [];
                }
                this.pieLabels[item.group[0].id].push( item.group[1].title );
                // this.pieLabels[item.group[0].id].push(item.group[0].title + " " + item.group[1].title);

            }
        }

    }

    public pieData ;
    public pieLabels ;
    public pieChartType = 'doughnut'; //'pie';
    public pieOptionsFemale = {
        legend : {
            position: "left"
        },

        plugins: {
            datalabels: {
                // color: 'white',
                display: true,
                font: {
                    weight: 'bold'
                },
                position: 'outside'
            }
        }
    };

    public pieOptionsMale = {
        legend : {
            position: "right"
        }
    };

    public pieOptions = {
        legend : {
            position: "bottom"
        },
        plugins: {
            datalabels: {
                // color: 'white',
                display: true,
                font: {
                    weight: 'bold'
                },
                position: 'outside'
            }
        }
    };

    // events
    public chartClicked(e: any): void {
        // console.log(e);
    };

    public chartHovered(e: any): void {
        // console.log(e);
    };
}
