<div class="row mb-3"  *ngIf="pieData">

    <div class="col-sm-6 d-flex align-content-center flex-column " *ngIf="pieData">
        <h5 class="card-title">Distribution Audience <span class="small">({{dataCount.count || 0}})</span></h5>
        <div class="card mb-2 flex-grow-1"  >
            <div class="card-body d-flex align-content-center align-items-center">
                <div class="chart-wrapper " style="position: relative;     width: 100%;">
                    <canvas
                            baseChart
                            class="chart"
                            [data]="dataCount.data"
                            [labels]="dataCount.label"
                            [options]="pieOptions"
                            [chartType]="pieChartType"
                            (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-6">

        <div class="col-sm-12" *ngIf="pieData">
            <h5 class="card-title">Age of male <span class="small">({{pieData.male_count || 0}})</span></h5>
            <div class="card mb-2"  >
                <div class="card-body">
                    <div class="chart-wrapper">
                        <canvas
                                baseChart class="chart"
                                [data]="pieData.male"
                                [labels]="pieLabels.male"
                                [options]="pieOptions"
                                [chartType]="pieChartType"
                                (chartHover)="chartHovered($event)"
                                (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <h5 class="card-title">Age of female <span class="small">({{pieData.female_count || 0}})</span></h5>
            <div class="card mb-2" *ngIf="pieData">
                <div class="card-body">
                    <div class="chart-wrapper">
                        <canvas
                                baseChart class="chart"
                                [data]="pieData.female"
                                [labels]="pieLabels.female"
                                [options]="pieOptions"
                                [chartType]="pieChartType"
                                (chartHover)="chartHovered($event)"
                                (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col small">
        Pie chart of all males and females who have been recorded, distributed among age.
    </div>
</div>
<app-loader *ngIf="loading" class="over"></app-loader>
