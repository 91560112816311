import {Component, OnInit, Input, TemplateRef, EventEmitter} from '@angular/core';
export * from './item/item.component';
import {StreamBaseComponent} from './stream_base.component';
import {StreamService} from '@app/pages/stream/stream.service';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import * as _ from 'underscore';

@Component({
    selector: 'app-stream-event',
    templateUrl: './stream_event.component.html'
})
export class StreamEventComponent extends StreamBaseComponent {
    @Input() parent: any;
    extAction:  Subject<any> ;
    extActionSub: Subscription;
    modalRef: BsModalRef;
    menuisOpen: boolean;
    api: StreamService;

    current: any;

    constructor( api: StreamService, private modalService: BsModalService, public router: Router, aRouter: ActivatedRoute) {
        super(api, router, aRouter);
    }

    ngOnInit() {
        super.ngOnInit();
        this.extAction = this.api.extAction;

        if (this.extAction) {
            this.extActionSub = this.extAction.subscribe(
                ev => {
                    this.ExtAction(ev);
                }
            );
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.extActionSub) this.extActionSub.unsubscribe();
    }

    menuIsOpen(r) {
        this.menuisOpen = r;
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    ExtAction(ev){
        switch (ev.action) {
            case 'itemDelete':
                this.current = null;
                this.getList();
                break;
            case 'itemSave':
                this.goToItem(ev.data);
                this.getList();
                break;
        }
    }

    editItem(item, ev) {
        ev.stopPropagation();
        var i = _.extend({}, item, {asEdit: true});
        this.current = i;
    }


}
