import {Component, Input, OnInit} from '@angular/core';
import {DashboardsService} from '@app/dashboards/dashboards.service';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

import { DatePipe } from '@angular/common';
import {DashboardsBase} from '@app/dashboards/items/dashboards_base';
import * as _ from 'underscore';

@Component({
  providers: [DatePipe],
  selector: 'app-dashboards-line-timeline',
  templateUrl: './line-timeline.component.html',
  styleUrls: ['./line-timeline.component.scss']
})
export class LineTimelineComponent extends DashboardsBase implements OnInit {
    @Input() index = 'gender-age-timeline';
    graphData = [];
    // graphDataBy =  {};
    graphLabel = [];
    listError: any;
    complite = false;


    constructor( api: DashboardsService, private datePipe: DatePipe) {
        super(api);
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.getDataIndex(this.index);
    }

    prepareResponce(r, indexName?, indexData?) {
        let label = [];
        let index;
        let data = [];
        let dataGender = {};
        switch (indexName) {
            case 'traffic-timeline':

                let appearance_count = [];
                let attention_count = [];
                for (let item of r.timeline){
                    label.push( new Date(item.startTime ) ) ; //this.datePipe.transform(new Date(item.startTime )));
                    appearance_count.push(item.values.appearance_count);
                    attention_count.push(item.values.attention_count);
                }
                this.graphData = [
                    {
                        data: appearance_count,
                        label: 'Count'
                    },
                    // {
                    //     data: attention_count,
                    //     label: 'Attention'
                    // }
                ];
                this.graphLabel = label;
                break;
            case 'people-timeline':
                let persons_count = [];
                // let attention_count = [];
                for (let item of r.timeline){
                    let l;
                    if(this.configData && this.configData.type === 'file') {
                        l = new Date(item.startTime ).getTime() - new Date(this.configData.start).getTime();
                    } else {
                        l = new Date(item.startTime );
                    }

                    label.push( l ) ; //this.datePipe.transform(new Date(item.startTime )));
                    persons_count.push(item.values.person_count);
                    // attention_count.push(item.values.attention_count);
                }
                this.graphData = [
                    {
                        data: persons_count,
                        label: 'Count'
                    }
                // ,
                //     {
                //         data: attention_count,
                //         label: 'Attention'
                //     }
                ];
                this.graphLabel = label;
                break;

            case 'attention-timeline':
                index = {};
                data = [];
                dataGender = {};
                this.graphData = [];
                for (let item of r.classes) {
                    index[item.id] = item;
                    let d = r.timeline.filter( i => {
                        return i.class == item.id;
                    });
                    this.graphData.push({
                        data: d.map( i => { return i.value; }),
                        label: item.title
                    });
                    label = d.map(i => new Date( i.startTime )); //// d.map( i => this.datePipe.transform(new Date(i.startTime ), 'short'));
                }
                this.graphLabel = label;
                break;
            case 'appearance-timeline':
                index = {};
                data = [];
                dataGender = {};
                this.graphData = [];
                for (let item of r.classes) {
                    index[item.id] = item;
                    let d = r.timeline.filter( i => {
                        return i.class == item.id;
                    });
                    this.graphData.push({
                        data: d.map( i => { return i.values.count; }),
                        label: item.title
                    });
                    label = d.map(i => new Date( i.startTime )); //d.map( i => this.datePipe.transform(new Date(i.startTime ), 'short'));
                }
                this.graphLabel = label;
                // console.log(this.graphLabel);
                break;

            default:
                this.prepare(r);
        }
        this.complite = true;
        const optin = _.clone(this.mainChartOptions);
        let innter =  ((Date.parse(r.parameters.to) - Date.parse(r.parameters.from)) / 5000);
        innter = innter - (innter % 300);
        optin['scales']['xAxes'][0]['time'] =  {
                unit: 'second',
                unitStepSize: ((Date.parse(r.parameters.to) - Date.parse(r.parameters.from)) / 5000)
                // unitStepSize: ((Date.parse(r.parameters.to) - Date.parse(r.parameters.from)) / 5000)
        };
        this.mainChartOptions = optin;
    }


  prepare(r) {

  }




}
