import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {EventsBase} from './events_base';
import {PersonsService} from '@app/pages/persons/persons.service';
import {EventsService} from '@app/pages/events/events.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';

@Component({
    selector: 'app-events-person',
    templateUrl: './events_person.component.html'
})
export class EventsPersonComponent extends EventsBase {

    @ViewChild('errorPersonAction', { static: true }) private errorTemplate: TemplateRef<any>;

    @Input() filter: any;
    @Input() env: any;
    @Input() title: any;
    @Input() extAction: Subject<any>;
    @Output() action: EventEmitter<any> = new EventEmitter<any>();
    @Input() fullInfo = false;
    modalRef: BsModalRef;
    actionAdd: Subject<any>;
    @Input() parentItem: string;
    @Input() parentType: string;

    constructor( api: EventsService, modalService: BsModalService, router: Router) {
        super(api, null, modalService, router);

    }


    init() {
        if ( this.env === 'delete') {
            this.extAction = new Subject();
        }
        if ( this.extAction ) {
            this.extAction.subscribe( ev => {
                this.getList();
            });
        }

    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    chagePage(ev){
        switch (ev.type) {
            case 'changePage':
                this.page = ev.data;
                this.getList();
                break;
        }
    }
    openModal (template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    do ( action: string, item, event) {
        debugger
        // return
        var req;
        this.error = null;
        event.stopPropagation();
        switch (action) {
            case 'add':
                this.loading = true;
                req = ( this.parentType === 'person' )
                    ? this.api.addPerson( item.id, this.parentItem )
                    : this.api.addUser( item.id, this.parentItem );

                req.pipe(
                        finalize(() => {
                            this.loading = false;
                        })
                    )
                    .subscribe(
                    r => {
                        _.delay(() => {this.extAction.next();}, 100);
                    },
                    err => {
                        this.error = err;
                    }
                );
                break;
            case 'delete':
                this.loading = true;

                // this.error = {err: 'sdffs'};
                // this.openModal(this.errorTemplate);
                // return false;

                req = (this.parentType === 'person')
                        ? this.api.deletePerson( item.id, this.parentItem )
                        : this.api.deleteUser( item.id, this.parentItem );
                req.pipe(
                    finalize(() => {
                            this.loading = false;
                        })
                    ).subscribe(
                    r => {
                        debugger
                        _.delay(() => {this.extAction.next();}, 100);
                    },
                    err => {
                        this.error = err;
                        this.openModal(this.errorTemplate);
                    }
                );
                break;
        }
    }
}
