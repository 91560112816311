import {HttpClient, HttpParams, HttpParameterCodec} from '@angular/common/http';
import {PaginateRequest, Model} from '@app/_models';
// import {Model} from '@app/_models/model';
import {Observable, Subject} from 'rxjs';
import {ServingsModel} from '@app/servings/model';

class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

export class CoreBaseService <T extends Model> {
    apiUrl =  (window as any).externalConfig.apiUrl;
    pref = "";
    _pref = "";
    http: HttpClient;
    current: any;
    currentItem: T;

    public extAction = new Subject();

    constructor(  http: HttpClient ) {
        this.http = http;
    }

    getUrl(params?: string ) {
        return `${this.apiUrl}${this.pref}${this._pref}` + ( params ? `/${params}` : '');
    }

    getAll(params?: any) {
        var p = this.httpParams(params);
        var gp:any ;

        if ( p ) {
            gp = {params: p};
        }
        return this.http.get<PaginateRequest<T>>(this.getUrl(), gp);
    }

    getList(params?: any): Observable<T> {
        return this.http.get<T>(this.getUrl());
    }

    getById(id: string) {
        return this.http.get<T>(this.getUrl(`${id}`));
    }

    save(item: T) {
        debugger
        if (!item.new || item.id) {
            return this.http.put<T>(this.getUrl(`${item.id}`), item);
        } else {
            return this.http.post<T>(this.getUrl(), item);
        }
    }

    delete(id: any) {
        return this.http.delete(this.getUrl(`${id}`));
    }

    httpParams(p?: any) {
        // debugger
        if ( p ) {
            let params = new HttpParams({encoder: new CustomEncoder()});
            for ( let i in p ){
                params = params.append(i, p[i]);
            }
            return params;
        }

        return null;
    }
}
