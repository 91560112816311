<div *ngIf="logs">
    <div *ngFor="let log of logs | keyvalue" class="card p-2">
        <h4>{{log.key}}</h4>
        <pre class="log_view">
            {{log.value}}
        </pre>
    </div>
</div>

<app-loader *ngIf="loading"></app-loader>
