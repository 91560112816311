import { Injectable } from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ServingsModel, ServingForm, ServingsStreamFileModel} from './model';
import {CoreBaseService} from '@app/_core/base/service';
import {Observable, Subject} from 'rxjs';
import {Image, PaginateRequest, Person} from '@app/_models';
import {share, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ServingsService extends CoreBaseService<ServingsModel> {
    _pref = 'serving';
    current: any;
    config: any;
    public extAction = new Subject();
    // currentItem: ServingsModel;

    intervals = [
        {value: '1min', name: '1 min', date: 60000},
        {value: '5min', name: '5 min', date: 300000},
        {value: '15min', name: '15 min', date: 900000},
        {value: '1hour', name: '1 hour', date: 3600000},
        {value: '3hour', name: '3 hour', date: 10800000},
        {value: '12hour', name: '12 hour', date: 43200000},
        {value: '1day', name: '1 day', date: 86400000}
    ];

    constructor(http: HttpClient) {
        super(http);
    }

    getForm (type?: string) {
        let url = `${this.apiUrl}serving-form` + (type ? `/${type}` : '');
        return this.http.get<ServingForm>(url);
    }

    public getLog(id: string) {
        return this.http.get(this.getUrl(`${id}`) + '/logs');
    }

    public getRange(id: string) {
        return this.http.get(this.getUrl(`${id}`) + '/index-range');
    }
    public getRangeByError() {
        return {
            from: new Date(Date.now() - (24 * 60 * 60 * 1000)).toISOString(),
            to: new Date().toISOString(),
        }
    }

    // public getPersons(id: string) {
    //     return this.http.get(this.getUrl(`${id}`) + '/persons');
    // }

    getPersons (id, params?) {
        var p = this.httpParams(params);
        var gp:any ;

        if ( p ) {
            gp = {params: p};
        }
        return this.http.get(this.getUrl(`${id}`) + '/persons', gp).pipe(share());
    }

    save(item: ServingsModel) {
        if (!item.isNew && item.id) {
            return this.http.put<ServingsModel>(this.getUrl(`${item.id}`), item);
        } else {
            return this.http.post<ServingsModel>(this.getUrl(), item);
        }
    }

    videoFielUpload(item: ServingsModel, key: string, file: File) {
        const data = new FormData();
        data.append('file', file);
        return this.http.post<ServingsModel>(this.getUrl(`${item.id}/stream/${key}/upload`), data, {
                reportProgress: true,
                observe: 'events'
        });

    }

    getVideoFiles(item: ServingsModel, key: string) {
        return this.http.get<ServingsModel>(this.getUrl(`${item.id}/stream/${key}/video`));
    }

    public uploadVideo(itemId, data = {}) {
        const formData = new FormData();
        // const id = item instanceof Person ? item.id : item;
        for (let i in data) {
            formData.append(i, data[i]);
        }
        return this.http.post(
            this.getUrl(itemId) + '/upload',
            formData,
        );
    }

    public getFiles (itemId, params?: any) {
        var p = this.httpParams(params);
        var gp:any ;

        if ( p ) {
            gp = {params: p};
        }
        return this.http.get<PaginateRequest<ServingsStreamFileModel>>(this.getUrl(itemId), gp);
    }

    public getFilesStream (itemId, stream: string) {
        return this.http.get<any>(this.getUrl(itemId) + `/stream/${stream}/files`);
    }

    public uploadVideoProgress(itemId, data = {}) {
        const formData = new FormData();
        // const id = item instanceof Person ? item.id : item;
        for (let i in data) {
            formData.append(i, data[i]);
        }
        // debugger
        return this.http.post(
            this.getUrl(itemId) + '/upload',
            formData,
    {
                reportProgress: true,
                observe: 'events'
            }
        ).pipe(
            map((event) => {
                console.log('event', event);

                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        return {
                            type: 'progress',
                            data: {
                                progress: Math.round(100 * event.loaded / event.total),
                                total: event.total,
                                load: event.loaded
                            },
                            event: event
                        };
                    case HttpEventType.Response:
                        return {
                            type: 'complite',
                            data: event.body,
                            event: event
                        };
                    default:
                        return event;
                        // return {
                        //     type: 'unhandled',
                        //     event: event
                        // };
                }
            })
        );

    }
}
