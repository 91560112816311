import {Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy} from '@angular/core';
import {VideosService} from '@app/pages/videos/videos.service';
import {VideoFile, VideoFileClasses, VideoFileTimeline, VideoFileTimelineInterval} from '@app/_models/video-file';
import {ActivatedRoute} from '@angular/router';
import * as _ from 'underscore';
import {finalize} from 'rxjs/operators';


@Component({
	selector: 'app-video-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss']
})

export class VideoItemComponent implements OnInit, OnChanges, OnDestroy {

	@Input() video: VideoFile;
	@Input() parent: any;


	videoTimelines: VideoFileTimeline[];
	classes: VideoFileClasses[];
	timeline: VideoFileTimeline[];
	error: any;
	timelineError: any;
	videoIndex: [];
	videoIndexInfo: {};
	currentIndex: string;
	index: any;
	loadIndex = false;
	loading = false;

	allIndex = false;

	constructor( private api: VideosService, private activatedRoute: ActivatedRoute) {
		this.videoTimelines = [];
	}

	ngOnInit() {
		this.error = null;
		this.parent = this.api.parent;
		if ( !this.video ) {
			this.activatedRoute.paramMap.subscribe(params => {
				const videoId = params.get( "videoId" );
				this.loading = true;
				this.api.getById( videoId )
					.pipe(
						finalize(() => {
							this.loading = false;
						})
					)
					.subscribe(
						(r) => {
							this.video = r;
							this.getVideoIndex();
							this.api.current = this.video;
							this.videoTimelines = [];
						},
						(err) => {
							this.error = err;
						}
					);
			});
		}
	}

	ngOnDestroy(): void {
		this.api.current = null;
	}

	ngOnChanges(changes: SimpleChanges): void {
		for ( let c in changes ) {
			switch (c) {
				case 'video':
					this.getVideoIndex();
					break;
			}
		}
	}

	getVideoIndex() {
		if ( !this.video.hasIndex ) return false;
		this.loadIndex = false;
		this.api.getVideoIndex(this.video)
			.pipe(
				finalize(() => {
					this.loadIndex = false;
				})
			)
			.subscribe(
				r => {
					this.videoIndex = r;
					if ( this.videoIndex && this.videoIndex.length ) {
						this.index = r;
						this.videoIndexInfo = {};
						for ( let i  in this.index ) {
							this.videoIndexInfo[ this.index [i] ] = null;
						}
						this.getVideoIndexInfo(this.index[0]);

					}
				}
			);
	}

	changeIndex(ev) {
		this.getVideoIndexInfo(ev.currentTarget.value);
		this.selectAllIndex(ev.currentTarget.value === 'persons-count');
		// if(ev.currentTarget.value === 'persons-count') {
		//
		// }
	}

	getVideoIndexInfo(index) {
		this.currentIndex = index;
		this.videoTimelines = [];
		if ( !this.videoIndexInfo[this.currentIndex] ) {
			this.loadIndex = false;
			this.api.getVideoIndexInfo(this.video, index)
				.pipe(
					finalize(() => {
						this.loadIndex = false;
					})
				)
				.subscribe(
					r => {
						let t = [];

						switch (index) {
							case 'persons':
								_.each(r.classes, (item) => {
									let tt = Object.assign( new VideoFileTimeline(), _.extend( {order: item.title}, item ));
									tt.timeline = _.map(
										_.filter(r.timeline, (el: any) => {
											return el.class === item.id;
										}),
										(item) => {
											return Object.assign( new VideoFileTimelineInterval(), item);
										}
									);
									t.push(tt);
								});
								break;
							case 'persons-count':
								// debugger
								let max = 0, min ;
								_.each(r.timeline, (item) => {
									if (item.value > max) max = item.value;
									if ( _.isUndefined(min) || (!_.isUndefined(min) && min > item.value) ) min = item.value;
								});

								for ( let i = min; i < max; i++) {
									const item = {order: i, title: 'persons count ' + i};
									let tt = Object.assign( new VideoFileTimeline(), item );
									tt.timeline = _.map(
										_.filter(r.timeline, (el: any) => {
											return el.value === i;
										}),
										(item) => {
											return Object.assign( new VideoFileTimelineInterval(), item);
										}
									);
									t.push(tt);
								}
								// this.videoTimelines = t;
								// _.each(r.timeline, (item) => {
								// 	let tt = Object.assign( new VideoFileTimeline(), _.extend({}, item ) ) ;
								// 	tt.timeline = [Object.assign( new VideoFileTimelineInterval(), item)];
								// 	t.push(tt);
								// });
								break;
						};
						this.videoIndexInfo[index] = t;
						if ( index === 'persons-count') {
							this.selectAllIndex(true);
						}
					}
				);
		}
	}

	clickTimeline(item, event) {
		const tl = _.findWhere(this.videoTimelines, item);
		if ( tl ) {
			this.videoTimelines = _.without(this.videoTimelines, tl);
			this.allIndex = false;
		} else {
			if ( event.ctrlKey ) {
				this.videoTimelines.push(item);
				if ( this.videoIndexInfo[this.currentIndex].length === this.videoTimelines.length ) {
					this.allIndex = true;
				}
			} else {
				this.videoTimelines = [];
				this.videoTimelines.push(item);
				if ( this.videoIndexInfo[this.currentIndex].length === this.videoTimelines.length ) {
					this.allIndex = true;
				}
			}
		}
		this.orderVideoTimeLine();
	}

	selected(item) {
		return _.findWhere(this.videoTimelines, item);
	}

	selectAllIndex(val) {
		if ( val ) {
			this.videoTimelines = this.videoIndexInfo[this.currentIndex];
		}
		else {
			this.videoTimelines = [];
		}
		this.allIndex = val;
		this.orderVideoTimeLine();
	}

	orderVideoTimeLine() {
		this.videoTimelines = _.sortBy( this.videoTimelines, 'order');
	}



}
