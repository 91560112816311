import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoreBaseService} from '@app/_core/base/service';
import {Subject} from 'rxjs';
import { tap, mapTo, share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DashboardsService extends CoreBaseService<any> {

    _pref = 'index';
    current: any;
    public extAction = new Subject();

    loadedData = {};

    constructor(http: HttpClient) {
        super(http);
    }

    setPref (parent?) {
        // if (parent)
        this._pref = parent ? parent + '/index' : 'index';
    }

    getDataIndex (id, params?) {
        var p = this.httpParams(params);
        var gp:any ;

        if ( p ) {
            gp = {params: p};
        }
        return this.http.get(this.getUrl(`${id}`), gp).pipe(share());
    }
}
