import {Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter} from '@angular/core';
// import {EventEmitter} from 'events';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import * as _ from 'underscore';
import {ConfigService} from '@app/_services/config.service';

class DashboardConfig {
    interval: string;
    from: string;
    to: string;
    serving: [];
}


@Component({
    selector: 'app-dashboards-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']

})
export class DashboardsFilterComponent implements OnInit, OnChanges {

    @Input() options: any;
    @Input() groups: any;
    @Input() controls = {date: true, interval: true};
    @Output() changeConfig = new EventEmitter();
    @Input() autoapply: boolean;
    @Input() intervalsAvailable: any[];

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    config: DashboardConfig = new DashboardConfig();
    sendData = {}; // DashboardConfig =  new DashboardConfig();
    _sendData = {}; // DashboardConfig =  new DashboardConfig();
    bsValue = new Date();
    bsRangeValue: Date[];
    maxDate = new Date();
    inited = false;
    maxPointResponce = 100;

    intervals = [];
    // [
    //     {value: '1min', label: '1 min', date: 60000, available: true},
    //     {value: '5min', label: '5 min', date: 300000, available: true},
    //     {value: '15min', label: '15 min', date: 900000, available: true},
    //     {value: '1hour', label: '1 hour', date: 3600000, available: true},
    //     {value: '3hour', label: '3 hour', date: 10800000, available: true},
    //     {value: '12hour', label: '12 hour', date: 43200000, available: true},
    //     {value: '1day', label: '1 day', date: 86400000, available: true}
    // ];

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 6,
        allowSearchFilter: true
    };

    constructor(private apiConfig: ConfigService) {}

    ngOnInit() {
        if (this.intervalsAvailable) {
            this.intervals = this.intervalsAvailable;
            // this.initOptions();
        } else {

            this.apiConfig.config.subscribe((config) => {
                this.intervals = _.map( config.indexIntervals, (i) => {i['available'] = true; return i;  });
                // this.initOptions();
            });
        }

    }

    initIntervals(date: Date[] ) {
        if (this.intervals.length < 1 ) { return false; }

        if (!_.isArray(date) || date.length < 2) { return false; }

        if(!this.intervalsAvailable) {

            const start = date[0]; //new Date(date[0]);
            const end = date[1]; //new Date(date[1]);
            const di = end.getTime() - start.getTime();
            const dim = di / 2;
            const i = di / this.maxPointResponce;
            for (let item of this.intervals) {
                item.available = i < item.msec && item.msec < dim;
            }
        }

        const ci = _.findWhere(this.intervals, {value: this.config['interval'] || '15min'});

        if ( !ci || (ci && !ci.available) ) {
            this.config['interval'] = this.intervals[_.findIndex(this.intervals, {available: true})].name; //_.findWhere(this.intervals, {available: true}).value;
            this.changeInterval(this.config['interval']);
        }
        this.inited = true;
        this.apply();
    }

    initOptions() {

        const day = 86400000;
        const toDate = (this.options && this.options.config && this.options.config.to) ? new Date(this.options.config.to) : new Date();
        const fromDate = (this.options && this.options.config && this.options.config.from) ? new Date(this.options.config.from) : new Date(toDate.valueOf() - day );

        if(this.options.config && this.options.config.from && this.options.config.to ) {
            this.bsRangeValue = [
                new Date(this.options.config.from),
                new Date(this.options.config.to)
            ];
        }
        else {
            this.bsRangeValue = [
                ( fromDate < new Date(toDate.valueOf() - day) ) ? new Date(toDate.valueOf() - day) : fromDate,
                toDate
            ];
        }
        this.dpConfig.rangeInputFormat = 'MM Do YYYY, hh:mm:ss';
        this.dpConfig.dateInputFormat = 'MMMM Do YYYY, h:mm:ss a';
        this.dpConfig.showWeekNumbers = false;
        this.dpConfig.maxDate = new Date();
        // this.config['interval'] = (this.options && this.options.config && this.options.config.interval) ? this.options.config.interval : '15min';
        // this.initIntervals(this.bsRangeValue);

    }

    ngOnChanges(changes: SimpleChanges) {
        if ( changes.options ) {
            if(!this.bsRangeValue || !this.bsRangeValue.length) {
                this.initOptions();
                this.setSendDate();
            }
        }
        if ( changes.groups) {
            this.setSendDate();
        }
    }

    setSendDate() {
        const conf = {};
        conf['bsRangeValue'] = this.bsRangeValue;
        conf['interval'] = this.config.interval;
        if ( this.groups ) {
            _.each(this.groups.selected, (v: any[], k) => {
                conf[k] = v.map( i => i.item_id );
            });
        }

        this.sendData = conf;
        this.apply();
    }


    changeDateField(valiable, event) {
        switch (valiable) {
            case 'from':
                this.bsRangeValue = [new Date(event.target.value), this.bsRangeValue[1]];
                break;
            case 'to':
                this.bsRangeValue = [this.bsRangeValue[0], new Date(event.target.value)];
                break;
        }
        this.sendData['bsRangeValue'] = this.bsRangeValue;
        this.initIntervals(this.sendData['bsRangeValue']);
        if ( this.autoapply ) {
            this.apply();
        }
    }

    setViewMetricsGroup(list, key, init) {
        list = _.isArray(list) ? list : this.groups.selected[key];
        this.sendData[key] = list.map( i => i.item_id );
    }

    changeBsDaterangepicker(event: any[]) {
        if ( event && event[0].toISOString() === event[1].toISOString() ) {
            event[0].setHours(0, 0, 0);
            event[1].setHours(23, 59, 59);
        }
        this.sendData['bsRangeValue'] = event;
            this.initIntervals(this.sendData['bsRangeValue']);
        if ( this.autoapply ) {
            this.apply();
        }
    }


    changeInterval(value) {
        let i = _.findWhere(this.intervals, {name: value});
        this.sendData['msec'] = i.msec;
        this.sendData['interval'] = value;
    }

    changeDate(date, type?, ev?, init?) {
        switch (type) {
            case 'date':
            case 'bsRangeValue':
                const  d = ev ? ev : date;
                if (  _.isArray(d) ) {
                    this.config['from'] = d[0].toISOString();
                    this.config['to'] = d[1].toISOString();
                }
                break;
            // case 'interval':
            //     debugger;
            //     this.config['interval'] = ev ? ev.target.value : date;
            //     break;
            default:
                this.config[type] = date;
        }
    }

    apply(update?) {
        if ( !this.inited ) return;
        let data = {};
        _.each(this.sendData, (v, k) => {
            this.changeDate( v, k);
        });

        if (update || !_.isEqual(this._sendData, this.sendData) ) {
            // console.log({data: this.config})
            this.changeConfig.emit({type: 'changeConfig', data: _.extend({}, this.config)});
        }
        this._sendData = _.extend({}, this.sendData);
    }

    //
    // changeDate( q, w? , e?, r? ) {
    //
    // }
}
