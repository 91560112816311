<div class="app flex-row align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="clearfix">
                    <h1 class="float-left display-3 mr-4">Permission error</h1>
                    <h4 class="pt-3">Oops! You have not permissions.</h4>
                    <!--<p class="text-muted">You not have permission.</p>-->
                </div>
                <!--<div class="input-prepend input-group">-->
                    <!--<div class="input-group-prepend">-->
                        <!--<span class="input-group-text"><i class="fa fa-search"></i></span>-->
                    <!--</div>-->
                    <!--<input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">-->
                    <!--<span class="input-group-append">-->
            <!--<button class="btn btn-info" type="button">Search</button>-->
          <!--&lt;!&ndash;</span>&ndash;&gt;-->
                <!--</div>-->
            </div>
        </div>
    </div>
</div>
