import {Component, OnDestroy, OnInit} from '@angular/core';
import {EventsService} from '@app/pages/events/events.service';
export * from '@app/pages/events/item/item.component';
// import {PaginateRequest, Person} from '@app/_models';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})

export class EventsComponent implements OnInit, OnDestroy {

  list: any;
  page: number;
  private querySubscription: Subscription;
  private paramsSubscription: Subscription;
  currentVideoId: string;
  loading: boolean;

  constructor(private api: EventsService, private  aRoute: ActivatedRoute, private  router: Router) {
    this.paramsSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getCurrentVideoId();
      }
    });

    this.querySubscription = aRoute.queryParams.subscribe(
      (queryParam: any) => {
        this.page = queryParam['page'];
        this.getList();
      }
    );

    this.api.extAction.subscribe((ev: any) => {
      switch (ev.action) {
        case 'change':
        case 'delete':
          this.getList();

      }
    });
  }

  getCurrentVideoId() {
    if ( this.aRoute.snapshot.children && this.aRoute.snapshot.children[0] ) {
      this.currentVideoId = this.aRoute.snapshot.children[0].params.itemId;
    } else {
      this.currentVideoId = null;
    }
  }

  ngOnInit() {
    this.getCurrentVideoId();
  }

  getList() {
    let params = {
      page: this.page ? this.page : 1
    };

    this.loading = true;
    this.api.getAll(params)
        .pipe(
            finalize(() => {
              this.loading = false;
            })
        )
        .subscribe(
      r => {
        this.list = r;
      },
      (err) => {
      }
    );
  }

  ngOnDestroy(): void {
    if ( this.querySubscription ) {
      this.querySubscription.unsubscribe();
    }
    if ( this.paramsSubscription ) {
      this.paramsSubscription.unsubscribe();
    }
  }

  goto(item) {
   this.router.navigate(['/events', item.id]);
  }


}
