<div class="pt-1">
    <nav aria-label="Page navigation example" *ngIf="pages && pages.length ">
    <!--<nav aria-label="Page navigation example" *ngIf="pages && pages.length > 1 ">-->
        <ul class="pagination">
            <div style="display: inherit" *ngIf="!link">

                <li class="page-item" [ngClass]="{disabled: page == 0}" >
                    <a class="page-link" [ngClass]="{disabled: page == 0}" [routerLink]="" [queryParams]="{page: 0}" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                    </a>
                </li>
                <li class="page-item" *ngFor="let p of pages" [ngClass]="{ 'active': p === page }"  >
                    <a class="page-link" [routerLink]="" [queryParams]="{page: p }" >{{p}}</a>
                </li>
                <li class="page-item"  [ngClass]="{disabled: page == pCount}">
                    <a class="page-link"  [routerLink]="" [queryParams]="{ page: pCount }" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </div>

            <div style="display: inherit" *ngIf="link">
                <li class="page-item" [ngClass]="{disabled: page == 0}" >
                    <a class="page-link" (click)="goPage(0, $event)" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                    </a>
                </li>
                <li class="page-item" *ngFor="let p of pages" [ngClass]="{ 'active': p === page }" >
                    <a class="page-link" (click)="goPage(p, $event)">{{p}}</a>
                </li>
                <li class="page-item"  [ngClass]="{disabled: page == pCount}" >
                    <a class="page-link"  (click)="goPage(pCount, $event)" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </div>
            <li class="page-item">
                <div class="input-group">
                    <select [(ngModel)]="limit" [ngModelOptions]="{standalone: true}" (change)="changeLimit($event)" class="form-control" type="number" step="1"  style="width: 100px" placeholder="page">
                        <option *ngFor="let i of limits" [value]="i">{{i}}</option>
                    </select>
                </div>
            </li>


            <li class="page-item" *ngIf="anyPage">
                <form (ngSubmit)="goPage(newPage, $event)">
                    <div class="input-group">
                        <input min="1" [(ngModel)]="newPage" [ngModelOptions]="{standalone: true}" class="form-control" type="number" step="1"  style="width: 100px" placeholder="page"/>
                        <span class="input-group-append">
                            <button class="btn btn-primary" type="submit">go</button>
                        </span>
                    </div>
                </form>
            </li>
            <li class="page-item" *ngIf="anyPage && pCount">
                <span class="total">Total {{pCount}}</span>
            </li>
            <!--<li class="page-item" >-->
                <!--<span class="total">Limit</span>-->
                <!--<select (change)="changeLimit($event.target.value)" [(ngModel)]="limit" [ngModelOptions]="{standalone: true}">-->
                    <!--<option [value]="10">10</option>-->
                    <!--<option [value]="10">20</option>-->
                <!--</select>-->
            <!--</li>-->
        </ul>
    </nav>
</div>
