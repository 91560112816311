
<div *ngIf="api.currentItem"  class="flex-md-row row flex-column-reverse " >
    <div class="col-md-9 col">
        <div *ngIf="cConfig && cConfig.streams">
            <div *ngFor=" let i of data.activeStreams" class="pt-2">
                <div *ngIf="cConfig.streams.indexOf(i.key) != -1">
                    <h4>{{i.key}}</h4>
                    <app-heatmap
                            *ngIf="cConfig"
                            [baseUrl]="api.getUrl(api.currentItem.id + '/stream/' + i.key)"
                            [config]="cConfig"
                            [size]="{width: api.currentItem.width, height: api.currentItem.height}"
                            [hasProjection]="canProjection(i)"
                            [type]="contentType"
                            [id]="api.currentItem.id + '_' + i.key"
                    ></app-heatmap>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3  col toc" >
        <div class="card">
            <div class="card-body" *ngIf="range">
                <app-core-filter
                        [storageName]="'chanell' + api.currentItem.id"
                        [options]="fOptions"
                        [config]='fConfig'
                        (change)="filterChange($event)"

                ></app-core-filter>
            </div>
        </div>

    </div>

</div>


<!--dwell time-->















<!--<div class="flex-md-row row flex-column-reverse " *ngIf="config" >-->
    <!--<div class="col-md-9 col">-->
        <!--<div *ngFor=" let i of data.activeStreams" class="pt-2">-->
        <!--&lt;!&ndash;<div *ngFor=" let i of data.inferenceStreams" class="pt-2">&ndash;&gt;-->

            <!--<div *ngIf="cConfig">-->
            <!--&lt;!&ndash;<div *ngIf="config && data.streams_meta && data.streams_meta[i.key] ; else empty">&ndash;&gt;-->
                <!--<h4>{{i.key}}</h4>-->
                <!--<app-servings-item-heatmap [data]="i" [serving]="data.id" [config]="cConfig"></app-servings-item-heatmap>-->
                <!--&lt;!&ndash;<app-servings-item-heatmap [data]="i" [serving]="data.id" [meta]="data.streams_meta[i.key]" [config]="cConfig"></app-servings-item-heatmap>&ndash;&gt;-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
    <!--<div class="col-md-3  col toc" >-->
        <!--<div class="card">-->
            <!--<div class="card-body" *ngIf="config">-->
                <!--<app-dashboards-filter-->
                        <!--[groups]="{list: groups, selected: groupsSelected}"-->
                        <!--[options]="{config: config}"-->
                        <!--(changeConfig)="setConfig($event)"></app-dashboards-filter>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
<!--</div>-->

<!--<ng-template #empty>-->
    <!--<div *ngIf="!data.streams_meta" class="card p-3">-->
        <!--<div class="alert alert-warning text-center">-->
            <!--Files empty-->
        <!--</div>-->
    <!--</div>-->
<!--</ng-template>-->
