import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { DashboardsComponent } from './dashboards.component';
import { DashboardsPeopleComponent } from './items/people/people.component';
import { PeopleTrafficComponent } from './items/people-traffic/people-traffic.component';
import { PeopleCountComponent } from './items/people-count/people-count.component';
import { PeopleListComponent } from './items/people-list/people-list.component';
import { GenderAgeCountComponent } from './items/gender-age-count/gender-age-count.component';
import { GenderAgeCountPieComponent } from './items/gender-age-count-pie/gender-age-count-pie.component';
import { GenderAgeTimelineComponent } from './items/gender-age-timeline/gender-age-timeline.component';
import { GenderAgeTimelineSingleComponent } from './items/gender-age-timeline-single/gender-age-timeline-single.component';
import { AgeTimelineComponent } from './items/age-timeline/age-timeline.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {LineTimelineComponent} from './items/line_timeline/line-timeline.component';
import {PieCountComponent} from './items/pie-count/pie-count.component';
import {TrafficTableComponent} from './items/traffic-table/traffic-table.component';
import {CoreModule} from '@app/_core/core.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// RECOMMENDED
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DashboardsFilterComponent } from './filter/filter.component';
import {PeopleCountBlockComponent} from '@app/dashboards/items/people-count-block/people-count-block.component';
import {DashboardsNewComponent} from '@app/dashboards/dashboards-new.component';
// or
// import { BsDatepickerModule } from 'ngx-bootstrap';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ChartsModule,
    BsDropdownModule,
    ButtonsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CoreModule
  ],
  declarations: [
    DashboardsComponent,
    DashboardsNewComponent,
    DashboardsPeopleComponent,
    PeopleTrafficComponent,
    PeopleCountComponent,
    PeopleListComponent,
    GenderAgeCountComponent,
    GenderAgeTimelineComponent,
    GenderAgeTimelineSingleComponent,
    AgeTimelineComponent,
    GenderAgeCountPieComponent,
    LineTimelineComponent,
    PieCountComponent,
    DashboardsFilterComponent,
    TrafficTableComponent,
    PeopleCountBlockComponent
  ],
  exports: [DashboardsComponent, DashboardsFilterComponent, DashboardsNewComponent]
})
export class DashboardsModule { }
