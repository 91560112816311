<div class="card">
    <div class="card-body" *ngIf="configMetrics">

        <div class="row printPageBraek" *ngIf="viewMetrics['app-dashboards-people-count-block']" >
            <div class="col-12">
                <app-dashboards-people-count-block [parent]="parent" [configData]="configMetrics" ></app-dashboards-people-count-block>
            </div>
        </div>

        <div class="printPageBraek" *ngIf="viewMetrics['Traffic Timeline']">
            <app-dashboards-line-timeline [index]="'traffic-timeline'"  [configData]="configMetrics"  [title]="'Traffic Timeline'" [desc]="' '" ></app-dashboards-line-timeline>
        </div>

        <div class="row printPageBraek" *ngIf="viewMetrics['app-dashboards-gender-age-count']" >
            <div class="col-12">
                <app-dashboards-gender-age-count [parent]="parent" [configData]="configMetrics" ></app-dashboards-gender-age-count>
            </div>
        </div>


        <div class="printPageBraek" *ngIf="viewMetrics['Traffic table']">
            <app-dashboards-traffic-table [index]="'traffic-timeline'"  [configData]="configMetrics" ></app-dashboards-traffic-table>
        </div>

        <div class="row printPageBraek" *ngIf="viewMetrics['app-dashboards-gender-age-count-pie']" >
            <div class="col-12">
                <app-dashboards-gender-age-count-pie [parent]="parent"  [configData]="configMetrics" ></app-dashboards-gender-age-count-pie>
            </div>
        </div>

        <div *ngIf="viewMetrics['app-dashboards-gender-age-timeline']" class="printPageBraek">
            <app-dashboards-gender-age-timeline  [configData]="configMetrics" ></app-dashboards-gender-age-timeline>
        </div>

        <div *ngIf="viewMetrics['app-dashboards-gender-age-timeline_group']" class="printPageBraek">
            <app-dashboards-gender-age-timeline [bygender]="true"  [configData]="configMetrics" ></app-dashboards-gender-age-timeline>
        </div>

        <div *ngIf="viewMetrics['app-dashboards-gender-age-timeline-single']" class="printPageBraek">
            <app-dashboards-gender-age-timeline-single  [configData]="configMetrics" ></app-dashboards-gender-age-timeline-single>
        </div>

        <div class="printPageBraek" *ngIf="viewMetrics['Audience Attention Span to Advertisement']">
            <app-dashboards-line-timeline
                    [index]="'attention-timeline'"
                    [configData]="configMetrics" ></app-dashboards-line-timeline>
        </div>

        <div class="printPageBraek" *ngIf="viewMetrics['Audience Attention Span to Advertisement - Pie Chart']">
            <app-dashboards-pie-count
                    [index]="'attention-count'"
                    [configData]="configMetrics"
                    [title]="'Audience Attention Span to Advertisement - Pie Chart'"
                    [desc]="'The attention span of the audience who are looking at a set advertisement measured by 3 variables, less than 10 seconds, 10-20 seconds, and more than 20 seconds. this pie chart is a total count of the recorded attention span.'"
            ></app-dashboards-pie-count>
        </div>

        <div class="printPageBraek" *ngIf="viewMetrics['Audience appearance']">
            <app-dashboards-line-timeline
                    [index]="'appearance-timeline'"
                    [configData]="configMetrics"
                    [title]="'Dwell time'"
                    [desc]="' '" ></app-dashboards-line-timeline>
        </div>

        <div class="printPageBraek" *ngIf="viewMetrics['Audience appearance - Pie chart']">
            <app-dashboards-pie-count
                    [index]="'appearance-count'"
                    [configData]="configMetrics"
                    [title]="'Audience appearance - Pie chart'"
                    [desc]="' '" ></app-dashboards-pie-count>
        </div>


        <div class="printPageBraek" *ngIf="viewMetrics['app-dashboards-people-timeline']">
            <app-dashboards-line-timeline [index]="'people-timeline'"  [configData]="configMetrics"  [title]="'People Timeline'" [desc]="' '" ></app-dashboards-line-timeline>
        </div>

        <div class="printPageBraek" *ngIf="viewMetrics['app-dashboards-heatmap-timeline']">
            <app-dashboards-line-timeline [index]="'heatmap-timeline'"  [configData]="configMetrics"  [title]="'Heatmap timeline'" [desc]="' '" ></app-dashboards-line-timeline>
        </div>


    </div>
</div>
