<div class="alert-box">
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{message}}
  </div>
  <div class="modal-footer" *ngIf=" type === 'confirm' ">
    <button type="button" class="btn btn-secondary" (click)="onConfirm()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="onCancel()">No</button>
  </div>

  <div class="modal-footer" *ngIf=" type === 'message' ">
    <button type="button" class="btn btn-secondary" (click)="onConfirm()">Ok</button>
  </div>


</div>
