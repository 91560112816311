<h5 class="">
	Traffic
	<!--<span class="small" *ngIf="trafficCout">( average {{trafficCout.values.average}} of {{trafficCout.values.maximum}} )</span>-->
</h5>
<style>
	.bg1 {
		background-color: rgba(0,0,0,.1);
	}
	.bg2 {
		background-color: rgba(0,0,0,.15);
	}



	tr {
		width: 100%;
		display: inline-table;
		table-layout: fixed;
	}

	table{
		height:450px;
		display: -moz-groupbox;
		position: relative;
	}
	thead{
		overflow-y: scroll;
		height: auto;
		width: 100%;
		/* padding-right: 18px; */
		display: block;
	}
	tbody{
		overflow-y: scroll;
		height: 330px;
		width: 100%;
		position: absolute;
	}
</style>
<div class="card mb-0">
	<div class="card-body overflow-auto">

		<table *ngIf="trafficData" class="table" style="margin-bottom: 0px; min-width: 600px">

			<!--<colgroup>-->
				<!--<col span="2">-->
				<!--<col span="4">-->
				<!--<col span="4">-->
			<!--</colgroup>-->
			<thead>
				<tr>
					<th colspan="2">
						time
					</th>
					<th colspan="4" class="bg1">appearance</th>
					<th colspan="5" class="bg2">attention</th>
				</tr>
				<tr>
					<th>start</th>
					<th>end</th>
					<th class="bg1">avg</th>
					<th class="bg1">count</th>
					<th class="bg1">min</th>
					<th class="bg1">max</th>

					<th class="bg2">avg</th>
					<th class="bg2">count</th>
					<th class="bg2">min</th>
					<th class="bg2">max</th>
					<th class="bg2">rate</th>
				</tr>
			</thead>
			<tbody>
				<!--<colgroup>-->
					<!--<col span="2">-->
					<!--<col span="4">-->
					<!--<col span="5">-->
				<!--</colgroup>-->
				<tr *ngFor="let item of trafficData.timeline">
					<td>{{item.startTime |  date:'short'}}</td>
					<td>{{item.endTime |  date:'short'}}</td>
					<td class="bg1">{{item.values.appearance_average}}</td>
					<td class="bg1">{{item.values.appearance_count}}</td>
					<td class="bg1">{{item.values.appearance_minimum}}</td>
					<td class="bg1">{{item.values.appearance_maximum}}</td>

					<td class="bg2">{{item.values.attention_average}}</td>
					<td class="bg2">{{item.values.attention_count}}</td>
					<td class="bg2">{{item.values.attention_minimum}}</td>
					<td class="bg2">{{item.values.attention_maximum}}</td>
					<td class="bg2">{{(item.values.attention_rate * 100).toFixed(1)}}%</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div class="small mb-3">
	<!--Collection of all traffic charts that showcases the overall traffic within a specific gender and age. The upscale traffic is based on time. So if the line in increasing, it is multiple audience members within the time frame.-->
</div>
<app-loader *ngIf="loading" class="over"></app-loader>
