import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fixed' })
export class FixedPipe implements PipeTransform {
    transform(val, count = 2, zero) {
        var v = parseFloat(val.toFixed(count));

        if ( zero && (v % 1 > 0) ) {
            return  parseInt(v.toString());
        }
        return v;
    }
}
