import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(private element: ElementRef) {}

  ngOnInit() {
    let el = this.element.nativeElement.parentElement;
    el.style.display = 'block';
    el.style.position = 'relative';
  }

}
