import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Stream} from '@app/_models';
import {CoreBaseService} from '@app/_core/base/service';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class StreamService extends CoreBaseService<Stream> {
    _pref = 'stream';
    current: any;
    public extAction = new Subject();

    constructor( http: HttpClient) {
        super(http);
    }

    serving (id, method) {
        return this.http[method](this.getUrl(`${id}/serving`));
    }

    serving_info (id) {
        return this.http.get(this.getUrl(`${id}/serving`));
    }

    serving_start(id) {
        return this.http.post(this.getUrl(`${id}/serving`), {});
    }

    serving_stop(id) {
        return this.http.delete(this.getUrl(`${id}/serving`));
    }

    serving_restaer(id) {
        return this.http.get(this.getUrl(`${id}/serving/restart`));
    }
}
