import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {PersonsBase} from './persons_base';
import {PersonsService} from '@app/pages/persons/persons.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-persons-event',
    templateUrl: './persons_event.component.html',
    styleUrls: ['./persons.component.scss']
})
export class PersonsEventComponent extends PersonsBase {
    @Input() filter: any;
    @Input() env: any;
    @Input() title: any;
    @Input() extAction: Subject<any>;
    @Output() action: EventEmitter<any> = new EventEmitter<any>();
    @Input() fullInfo = false;

    constructor( api: PersonsService, modalService: BsModalService) {
        super(api, null, modalService);
    }

    init() {
        if ( this.extAction ) {
            this.extAction.subscribe( ev => {
                this.getList();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    do(action, person, event) {
        event.stopPropagation();
        this.action.emit({action: action, data: person});
    }

    chagePage(ev){
        switch (ev.type) {
            case 'changePage':
                this.page = ev.data;
                this.getList();
                break;
        }
    }

}
