
<style>
    .persons img { max-height: 50px; max-width: 50px; margin: 2px;}
    .filterHide, .filterHide app-dashboards-filter{
        opacity: 0;
        height: 0px;
        position: absolute;
        top: -1000px;
    }

</style>

<div class="flex-md-row row flex-column-reverse " >
    <div class="col-md-9 col">

        <div class="card p-2 overflow-auto">
            <div>
                <h4 class="mt-2 mb-2">Report <span *ngIf="params && params.from">{{params.from | date:'short'}} - {{params.to| date:'short'}}</span> ( page {{params.page}} )
                    <!--<button class="btn btn-sm float-right" (click)="filterOpenAction()">filter <span class="caret"></span></button>-->
                </h4>

                <div class="alert alert-danger" *ngIf="error">{{error.message}}</div>
                <!--<div [ngClass]="{'filterHide': !filterOpen}" *ngIf="config">-->

                    <!--<app-dashboards-filter-->
                            <!--[options]="{config: config}"-->
                            <!--(changeConfig)="changeParams($event.data)"-->
                            <!--[controls]="{date: true}"-->
                            <!--[autoapply]="true"-->
                    <!--&gt;</app-dashboards-filter>-->
                    <!--<div class="filterGroup">-->

                        <!--<div class="form-group">-->
                            <!--<div class="col">-->

                                <!--<label>gender</label>-->
                                <!--<select class="form-control" (change)="changeParams({gender: $event.target.value})">-->
                                    <!--<option [value]="null">all</option>-->
                                    <!--<option [value]="'male'">male</option>-->
                                    <!--<option [value]="'female'">female</option>-->
                                    <!--<option [value]="'undefined'">undefined</option>-->
                                <!--</select>-->
                            <!--</div>-->
                            <!--<div class="col">-->

                                <!--<label>age</label>-->
                                <!--<select class="form-control" (change)="changeParams({age: $event.target.value})">-->
                                    <!--<option [value]="null">all</option>-->
                                    <!--<option *ngFor="let i of ageClasses" [value]="i.id">{{i.title}}</option>-->
                                <!--</select>-->
                            <!--</div>-->
                            <!--<div class="col">-->
                                <!--<button class="btn btn-primary w-100" [disabled]="!newParams" (click)="applyFilter()">apply filter</button>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            </div>

            <table class="table persons" *ngIf="list">
                <thead>
                <tr>
                    <th>Images</th>
                    <th>ID</th>
                    <!--<th>Name</th>-->
                    <th>Gender</th>
                    <th>Age</th>
                    <th>Duration</th>
                    <th>Emotion</th>
                    <th>Start</th>
                    <th>End</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of list.items; let i = index;">
                    <td>
                        <div >
                            <img [src]="img" *ngFor="let img of item.body_images" />
                        </div>
                        <div >
                            <img [src]="img" *ngFor="let img of item.face_images" />
                        </div>
                        <div >
                            <img [src]="img" *ngFor="let img of item.good_face_images" />
                        </div>
                    </td>
                    <td>{{item.class_id}}</td>
                    <!--<td>{{item.name}}</td>-->
                    <td>{{ ( !item.gender || item.gender === 'undefined') ? 'N/A' : item.gender }}</td>
                    <td>{{item.age ? item.age : 'N/A' }}</td>
                    <td>{{ item.duration ? item.duration.toFixed(1) : 'N/A' }}</td>
                    <td>{{ ( !item.emotion || item.emotion === 'undefined') ? 'N/A' : item.emotion }}</td>
                    <td>{{item.start | date:'short'}}</td>
                    <td>{{item.end | date:'short'}}</td>
                </tr>
                </tbody>
            </table>
            <hr/>
            <app-pagination *ngIf="list"   (action)="getList($event.data)" [page]="list.page" [count]="list.count" [limit]="list.limit" link="true"></app-pagination>


            <app-loader *ngIf="loading" class="over"></app-loader>

        </div>
    </div>
    <div class="col-md-3  col toc" >
        <div class="card">
            <div class="card-body" *ngIf="range">

                <app-core-filter
                        [options]="fOptions"
                        [config]='fConfig'
                        [storageName]="'chanell' + servingApi.currentItem.id"
                        (change)="filterChange($event)"
                ></app-core-filter>

                <!--<div class="form-group" *ngIf="streamsList">-->
                    <!--<h5>Streams</h5>-->
                    <!--<div *ngFor="let s of data.activeStreams">-->
                        <!--<div class="form-check" >-->
                            <!--<input class="form-check-input"-->
                                   <!--type="checkbox"-->
                                   <!--[id]="'stream' + s.key"-->
                                   <!--(change)="streamChange($event, s)"-->
                                   <!--[checked]="streamsList[s.key]"-->
                            <!--&gt;-->
                            <!--<label class="form-check-label" [for]="'stream' + s.key">-->
                                <!--{{s.display_name || s.key}}-->
                            <!--</label>-->
                        <!--</div>-->

                    <!--</div>-->
                    <!--<hr/>-->
                <!--</div>-->

                <!--<app-dashboards-filter-->
                    <!--[options]="{config: config}"-->
                    <!--(changeConfig)="changeParams($event.data)"-->
                    <!--[controls]="{date: true}"-->
                    <!--[autoapply]="true"-->
                <!--&gt;</app-dashboards-filter>-->

                <!--<div class="filterGroup">-->
                    <!--<div class="form-group">-->
                        <!--<label>gender</label>-->
                        <!--<select class="form-control" (change)="changeParams({gender: $event.target.value})">-->
                            <!--<option [value]="null">all</option>-->
                            <!--<option [value]="'male'">male</option>-->
                            <!--<option [value]="'female'">female</option>-->
                            <!--<option [value]="'undefined'">undefined</option>-->
                        <!--</select>-->
                    <!--</div>-->
                    <!--<div class="form-group">-->

                        <!--<label>age</label>-->
                        <!--<select class="form-control" (change)="changeParams({age: $event.target.value})">-->
                            <!--<option [value]="null">all</option>-->
                            <!--<option *ngFor="let i of ageClasses" [value]="i.id">{{i.title}}</option>-->
                        <!--</select>-->
                    <!--</div>-->
                    <!--<div class="form-group">-->
                        <!--<button class="btn btn-primary w-100" [disabled]="!newParams" (click)="applyFilter()">apply filter</button>-->
                    <!--</div>-->
                <!--</div>-->
            </div>
        </div>
    </div>
</div>
