<div *ngIf="!current" class="card">
    <div class="card-body">
        <div class="row">
            <h1 class="col-auto mr-auto">Channels</h1>
            <div class="col-auto">
                <button *ngIf="!authUser.isPublic && currentUser && currentUser.admin" type="button" class="btn btn-primary" [routerLink]="'create'"> + add </button>
            </div>
        </div>
        <table class="table table-responsive-sm table-hover table-outline mb-0" *ngIf="list">
            <thead class="thead-light">
            <tr>
                <th colspan="2">Title</th>
            </tr>
            </thead>
            <tbody>
            <tr  *ngFor="let item of list.items" (click)="goto(item)" class="clickabel" [ngClass]="{'active': ( currentVideoId && item.id === currentVideoId ) }">
                <td>
                    <div>
                        {{item.display_name}} (type: {{item.type}})
                        <span *ngIf="item.health" class=" status {{item.health}} float-right" >{{item.health}}</span>
                        <span *ngIf="!authUser.isPublic && item.public" class="badge badge-danger">public</span>
                    </div>
                    <p *ngIf="item.description">{{item.description}}</p>
                </td>
                <td class="text-right">
                    <span class="badge badge-info">{{item.stream_type}}</span>
                </td>

            </tr>
            </tbody>
        </table>
        <app-pagination *ngIf="list" [page]="list.page" [count]="list.count" [limit]="list.limit" [anyPage]="true"></app-pagination>
    </div>
</div>
<app-loader class="over" *ngIf="loading"></app-loader>
