import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServingsComponent } from './servings/servings.component';
import { ServingsItemComponent} from './servings/item/item.component';
import {CoreModule} from '@app/_core/core.module';
import { ServingsItemFormComponent } from './item/form/form.component';
import { ServingsItemStreamsComponent } from '@app/servings/item/streams/streams.component';
import {AppServingsRoutingModule} from '@app/servings/servings.module.routing';
import {DashboardsModule} from '@app/dashboards/dashboards.module';
import { ServingsItemLogComponent } from './item/log/log.component';
import { ServingsMainDashboardComponent } from './main/dashboard/dashboard.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ServinsItemDebugComponent} from './item/debug/debug.component';
import { ServinsItemDashboardComponent} from './item/dashboard/dashboard.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ServingsItemFilesComponent } from './item/files/files.component';
import { ServingsItemPersonsComponent} from './item/persons/persons.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ServingsItemHeatmapComponent} from '@app/servings/item/heatmap/heatmap.component';
import {ServingsItemHeatmapPageComponent} from '@app/servings/item/heatmap-page/heatmap-page.component';
import {ServingsItemSourceComponent} from '@app/servings/item/source/source.component';
import {ServingsItemHeatmapNewComponent} from '@app/servings/item/heatmap_new/heatmap-new.component';


@NgModule({
  declarations: [
    ServingsComponent,
    ServingsItemComponent,
    ServingsItemFormComponent,
    ServingsItemStreamsComponent,
    ServingsItemLogComponent,
    ServingsMainDashboardComponent,
    ServinsItemDebugComponent,
    ServinsItemDashboardComponent,
    ServingsItemFilesComponent,
    ServingsItemPersonsComponent,
    ServingsItemHeatmapComponent,
    ServingsItemHeatmapPageComponent,
    ServingsItemSourceComponent,
    ServingsItemHeatmapNewComponent
  ],
  imports: [
    AppServingsRoutingModule,
    CommonModule,
    CoreModule,
    DashboardsModule,
    FormsModule,
    ReactiveFormsModule,
    // BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AngularSvgIconModule,
    TooltipModule.forRoot()
    // ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
  ],
  exports: [
    ServingsComponent,
    ServingsItemComponent,
    ServingsItemStreamsComponent,
    ServingsMainDashboardComponent
  ],
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
  // declarations: [ServingsComponent, ServingsItemComponent]
})

export class ServingsModule { }
