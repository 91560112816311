import {AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as _ from 'underscore';
import {ProjectionModel} from '@app/_models/projection';
import Konva from 'konva';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-core-plan-konva',
    templateUrl: './plan-konva.component.html',
    styleUrls: ['./plan-konva.component.scss']
})
export class CorePlanKonvaComponent implements OnInit, AfterViewInit {

    @Input() place: string;
    @Input() data: ProjectionModel;
    @Input() event: Subscription;

    active: number;
    imgPosition: any;

    loading = false;
    darthVaderGroup: any;
    stage: any;
    imageObj1: any;
    darthVaderImg: any;
    layer: any;
    scale = 1;

    constructor(private  el: ElementRef) {
    }

    ngOnInit(): void {
        // this.init();
        this.data.extAction.subscribe((e: any) => {
            let p; // = this.data.projections[e.data.index];
            switch (e.action) {
                case 'addPint':
                    p = this.data.projections[e.data.index];
                    this.addAnchor(this.darthVaderGroup,  p[this.place].x,  p[this.place].y, e.data.index);
                    break;
                case 'setActive':
                    this.setActive();
                    break;
                case 'changeImage':
                    this.init();
                case 'update':
                    break;
            }

        })
    }

    getX(p) {
        return p[this.place].x;
    }

    getY(p){
        return p[this.place].y;
    }

    setPoint( event) {
        const active = _.findWhere(this.data.projections, {active: true});
        if(active) {
            active[this.place].x = event.offsetX;
            active[this.place].y = event.offsetY;
            this.data.hasChange = true;
        }
    }

    update(activeAnchor) {
        const name = Number(activeAnchor.getName());
        this.data.setActive(name);
        this.data.projections[name][this.place].x = activeAnchor.getX();
        this.data.projections[name][this.place].y = activeAnchor.getY();
        this.data.hasChange = true;
    }


    addAnchor(group, x, y, name) {
        var stage = group.getStage();
        var layer = group.getLayer();

        if(!this.data.projections[name][this.place].obj){

            var anchor = new Konva.Group({
                x: x,
                y: y,
                draggable: true,
                dragOnTop: false,
                name: name,
            });

            var text = new Konva.Text({
                text: name,
                x: -3,
                y: -20,
                name: 'text'
            });

            var circle = new Konva.Circle({
                x: 0,
                y: 0,
                stroke: '#666',
                fill: '#ddd',
                strokeWidth: 2,
                radius: 8,
                name: 'circle',
                draggable: false,
                dragOnTop: false,
            });

            anchor.add(circle);
            anchor.add(text);

            this.setScalePoint(anchor);

            group.add(anchor);

            this.data.projections[name][this.place].obj = anchor;

            const thet = this;

            anchor.on('dragmove', function () {
                thet.update(this);
            });
            anchor.on('mousedown touchstart', function () {
                group.draggable(false);
                this.moveToTop();
                const el: any = this;
                // debugger;
                thet.data.setActive(el.getName());

            });
            anchor.on('dragend', function () {
                group.draggable(true);
            });
            // add hover styling
            anchor.on('mouseover', function () {
                var layer = this.getLayer();
                document.body.style.cursor = 'pointer';
                let c = this.getChildren()[0] as Konva.Circle;
                // this.getChildren()[0].strokeWidth(4);
                c.strokeWidth(4 * (1 / thet.scale));
            });
            // anchor.on('click', function () {
            //    // this.getName();
            //    debugger
            // });

            anchor.on('mouseout', function () {
                var layer = this.getLayer();
                document.body.style.cursor = 'default';
                let c = this.getChildren()[0] as Konva.Circle;
                // this.getChildren()[0].strokeWidth(2);
                c.strokeWidth(2 * (1 / thet.scale));
                group.draggable(true);
            });

        } else {
            this.data.projections[name][this.place].obj.x = x;
            this.data.projections[name][this.place].obj.y = y;
        }

    }

    setScalePoint(point) {
        let scale = 1 / this.scale;
        let circle = point.find('.circle')[0];
        let text = point.find('.text')[0];
        circle.strokeWidth(2 * scale);
        circle.radius(8 * scale);
        text.fontSize(14 * scale);
        text.y(-24 * scale);
        text.x(-4 * scale);
    }

    setActive() {
        _.each(this.data.projections, (v, k) => {
            this._setActive(k);
        })
    }

    _setActive(i) {
        // const el = this.darthVaderGroup.get(i);
        // debugger
        const el = this.data.projections[i][this.place].obj;

        if(this.data.projections[i].active) {
            el.getChildren()[0].fill('red');
            // el.fill('red');
        } else {
            el.getChildren()[0].fill('#ddd');
            // el.fill('#ddd');
        }
    }


    
    _init() {
        this.stage = new Konva.Stage({
            container: this.place,
            width: this.el.nativeElement.offsetWidth,
            height: this.el.nativeElement.offsetHeight,

        });

        this.layer = new Konva.Layer();
        this.stage.add(this.layer);
        // const scale = 0.5;
        // this.layer.scale({x: scale, y: scale});

        // darth vader
        this.imageObj1 = new Image();
        this.imageObj1.onload = () => {


            const param = {
                x: 0,
                y: 0,
                draggable: true,
            };

            _.each(this.data.projections, (p,k) => {

                if(param.x < p[this.place].x * -1) {
                    param.x = p[this.place].x * -1 + 10;
                }
                if(param.y < p[this.place].y * -1) {
                    param.y = p[this.place].y * -1 + 10;
                }
                // this.addAnchor(darthVaderGroup,  p[this.place].x,  p[this.place].y, k);
            });

            this.darthVaderGroup = new Konva.Group(param);
            this.layer.add(this.darthVaderGroup);


            if(!this.darthVaderImg) {

                this.darthVaderImg = new Konva.Image(
                    {
                        image: this.imageObj1,
                        width: this.imageObj1.width,
                        height: this.imageObj1.height,
                        // draggable: true,
                    }
                );
                this.darthVaderGroup.add(this.darthVaderImg);
            } else {
                this.darthVaderImg.image(this.imageObj1);
                this.darthVaderImg.width(this.imageObj1.width);
                this.darthVaderImg.height(this.imageObj1.height);
            }
            _.each(this.data.projections, (p,k) => {
                this.addAnchor(this.darthVaderGroup,  p[this.place].x,  p[this.place].y, k);
            });


            // this.imgEvent(darthVaderImg);
            this.loading = false;
        };

    }
    
    init() {
        if(this.data[this.place + 'Url']) {
            this.loading = true;
            this.imageObj1.src = this.data[this.place + 'Url'];
        }
    }


    imgEvent(anchor) {
        const thet = this;
        anchor.on('dragmove', function () {
            console.log('dragmove', this);
            thet.update(this);
            thet.imgPosition = this.position();
        });
        anchor.on('mousedown touchstart', function () {
            console.log('mousedown touchstart', this);
        });
        anchor.on('dragend', function () {
            const l = this.getParent();
            thet.imgPosition = this.position();
        });
        anchor.on('mouseover', function () {
            console.log('mouseover', this);
        });
        anchor.on('mouseout', function () {
            console.log('mouseout', this);
        });
    }

    ngAfterViewInit(): void {
        this._init();
        if(this.data.id) {
            this.init();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // debugger;
        this.stage.width(this.el.nativeElement.offsetWidth);
        this.stage.height(this.el.nativeElement.offsetHeight);
    }
    
    setScale(event) {
        this.scale = Number(event.currentTarget.value);
        this.layer.scale({x: this.scale,y: this.scale});
        _.each(this.data.projections, (v) => {
            this.setScalePoint(v[this.place].obj);
        });
    }

}
