import { Component, OnInit, Input } from '@angular/core';
import {CoreBaseListConroller} from '@app/_core/base/list-controller';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'underscore';
import {CorePersonsService, CorePersonModel} from './persons.service';
import {DialogService} from '@app/_services';
import {finalize} from 'rxjs/operators';
import {ConfigService} from '@app/_services/config.service';

@Component({
    selector: 'app-persons',
    templateUrl: './persons.component.html',
    styleUrls: ['./persons.component.scss']
})




export class CorePersonsComponent extends CoreBaseListConroller<CorePersonModel> implements OnInit {

    @Input() apiPath: string;

    @Input() config: any = {};

    csParamName = 'ServingsItemPersonsComponent';
    params: any = { page: 1, desc: true, order: 'start' };
    filterOpen = false;
    filtered = false;
    newParams: any;
    ageClasses = [
        {
            "id": "21-",
            "title": "21 less"
        },
        {
            "id": "22-35",
            "title": "from 22 to 35"
        },
        {
            "id": "36-50",
            "title": "from 36 to 50"
        },
        {
            "id": "50+",
            "title": "50 and more"
        }
    ];

    constructor(
        api: CorePersonsService,
        aRoute: ActivatedRoute,
        router: Router,
        dialogService: DialogService,
        private  servingApi: ServingsService,
        private configService: ConfigService
    ) {
        super(api, aRoute, router, dialogService);
    }

    ngOnInit(): void {
        // const p = this.configService.getCSP(this.csParamName);
        // if ( p ) {
        //     if (p.params) {
        //         this.params = p.params;
        //     }
        //     this.filterOpen = p.filterOpen || false;
        // }

        this.api.pref = this.apiPath; // `serving/${this.servingApi.currentItem.id}`;
        // this.config = this.servingApi.config;
        // if ( this.servingApi.config ) {
        // } else {
        //     this.getRange(this.servingApi.currentItem.id);
        // }
        super.ngOnInit();
    }

    filterOpenAction() {
        this.filterOpen = !this.filterOpen;
        this.setCS({filterOpen: this.filterOpen});
        if ( !this.filterOpen && this.filtered ) {
            this.params = _.omit(this.params, ..._.keys(this.newParams));
            this.newParams = {};
            this.params.page = 1;
            this.getList();
            this.filtered = false;
        }
    }

    getRange(id) {
        this.loading = true;
        this.servingApi.getRange(id)
            .subscribe(
                (r) => {
                    this.setRange(r);

                    // this.config = _.extend({}, this.config, r);
                },
                (err) => {
                    this.setRange(this.servingApi.getRangeByError());
                    // this.config = {};
                }
            );
    }

    setRange(r) {
        this.config = _.extend({}, this.config, r);
    }

    changeParams(params) {
        this.newParams = _.extend({}, this.newParams, params);
    }

    prepareParamsBefore(params = {}) {
        params = super.prepareParamsBefore(params);
        this.setCS({params: params});
        return _.omit( params, 'count', 'interval');
    }

    applyFilter() {
        this.filtered = true;
        this.params.page = 1;
        this.params = _.extend(this.params, this.newParams);
        this.getList(this.newParams);
    }

    setCS( data = {}) {
        const csParams = this.configService.getCSP(this.csParamName) || {};
        this.configService.setCSP(this.csParamName, _.extend(csParams, data));
    }
}
