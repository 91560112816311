import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';
import * as _ from 'underscore';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

declare var Slider:any;

class optionItemModel {
    name: string;
    title: string;
    type: string; // string, bool,
    value: any;
    dependence: string;
    valueBase: any;
    default: any;
    disabled: true;
    local_type: string;
}

class lsSaveObject {
    name: string;
    value: any;
    tsUpdate: Date;

    constructor(name, v) {
        this.name = name;
        this.value = v;
        this.tsUpdate = new Date();
    }

    toJSON() {
        return {
            name: this.name,
            value: this.value,
            tsUpdate: this.tsUpdate
        }
    }
}

@Component({
    selector: 'app-core-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    // changeDetection:ChangeDetectionStrategy.Default
    changeDetection:ChangeDetectionStrategy.OnPush
})
export class CoreFilterComponent implements OnInit, OnChanges, AfterViewInit {

    private _localStorageName: string = 'filterConfigs';

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    @Input() storageName: string; // name save local user config;
    @Input() options: optionItemModel[];
    localOtions: optionItemModel[];
    @Input() config: any;
    @Output() change = new EventEmitter();

    delayInterval: any;
    params: any;
    complited = false;

    delayEmit: any;
    update: any;

    dateIntervalList = [
        {
            value: 30 * 60 * 1000,
            label: 'last 30 minets'
        },
        {
            value: 60 * 60 * 1000,
            label: 'last 1 hours'
        },
        {
            value: 3 * 60 * 60 * 1000,
            label: 'last 3 hours'
        },
        {
            value: 6 * 60 * 60 * 1000,
            label: 'last 6 hours'
        },
        {
            value: 12 * 60 * 60 * 1000,
            label: 'last 12 hours'
        },
        {
            value: 24 * 60 * 60 * 1000,
            label: 'last 1 day'
        },
        {
            value: 'custom',
            label: 'user custom'
        },
    ];

    constructor(private ref: ChangeDetectorRef) { }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.config) {
            let lsv = this.localStorageValue;
            if(!this.params && !_.isUndefined(lsv)) {
                this.initParams(_.extend({}, this.config, lsv));
            } else {
                this.initParams(_.extend({}, changes.config.currentValue))
            }
        }
    }

    initParams(cfg) {
        this.localOtions = [];
        _.each(this.options, (v, k) => {
            let val =  _.clone(v);
            val.local_type = this.getTypeItemOptions(val);
            this.localOtions.push(val);
            let p = {};
            p[val.name] = (cfg && cfg[v.name]) ? cfg[val.name] : val.default;
            this.params = _.extend({}, this.params, p);

            switch(val.type) {
                case 'timeInterval':
                    let p = this.params[val.name] || val.default;
                    if(this.params.from) { p[0] = this.params.start; }
                    if(this.params.to) { p[1] = this.params.to; }
                    this.params['timeInterval'] = p; //new lsSaveObject(v.name, p);
                    break;
                case 'dateInterval':
                    this.dpConfig.rangeInputFormat = 'MM Do YYYY, hh:mm:ss';
                    this.dpConfig.dateInputFormat = 'MMMM Do YYYY, h:mm:ss a';
                    this.dpConfig.showWeekNumbers = false;
                    this.dpConfig.maxDate = new Date();
                    this.params[val.name] = cfg[val.name]
                            ? cfg[val.name]
                            :  this.params[val.name]
                                ? this.params[val.name]
                                : val.default;

                    // if(val.dependence) {
                    //     this.setDependence(val.name, val.dependence);
                    // }
                    break;

            }

        });
        // debugger
        _.each(this.options, (v, k) => {
            if(v.dependence) {
                this.setDependence(v.name, v.dependence);
            }
        });
        console.log('params', this.params);
        this.apply('filter');
        this.complited = true;
    }

    setDependence(target, dependence) {
        let from, to;
        let min, max;
        if (_.isArray(this.params[target])) {
            from = new Date(this.params[target][0]).getTime();
            to = new Date(this.params[target][1]).getTime();
            min  = (to - from) / 100;
            max = to - from;
        } else {
            min = this.params[target] / 100;
            max = this.params[target];
        }

        let dep = _.findWhere(this.localOtions, {name: dependence});

        if(dep) {
            let val = dep.valueBase || dep.value;
            dep.value = val.filter((v) => { return v.date >= min && v.date <= max});
            if(this.params[dependence]){
                let pValue = _.findWhere( dep.value, {value: this.params[dependence]});
                if(!pValue) {
                    this.params[dependence] = dep.value[0] ? dep.value[0].value : dep.valueBase[0].value;
                }
            }
        }
    }

    changeParams(event, target, item?: any) {
        this.ref.detach();
        let t = _.findWhere(this.options, {name: target});
        if(t) {
            switch (t.type) {
                case 'array':
                    let index = this.params[target].indexOf(item.value);
                    let val = event.currentTarget.checked;
                    if(index != -1) {
                        this.params[target].splice(index, 1);
                    }
                    if(val) {
                        this.params[target].push(item.value);
                    }
                    break;
                case 'boolean':
                    this.params[target] = event.currentTarget.checked;
                    break;

                case 'dateInterval':
                    if(item) {
                        let from, to;
                        if (item == 'dateIntervalSelect') {
                            let v = Number(event.currentTarget.value);
                            if(!isNaN(v)) {
                                this.params[target] = v;
                            } else {
                                from = Date.now() - (24 * 60 * 60 * 1000);
                                to = Date.now();
                                this.params[target] = [new Date(from).toISOString(), new Date(to).toISOString()];
                            }
                        }
                        if(item == 'from') {
                            this.params[target][0] = event.currentTarget.value;
                        }
                        if(item == 'to') {
                            this.params[target][1] = event.currentTarget.value;
                        }
                        if(item == 'datapicker' && event && event[0] && event[1]){
                            this.params[target] = event.map(v => { return new Date(v).toISOString()});
                        }
                        if(t.dependence) {
                            this.setDependence(target, t.dependence);
                        }
                    }
                    break;

                case 'timeInterval':
                    // debugger;
                    this.params[target] = event;
                    break;
                case 'number':
                    this.params[target] = isNaN(Number(event.currentTarget.value)) ? event.currentTarget.value : Number(event.currentTarget.value);
                    break;
                default:
                    this.params[target] = event.currentTarget.value;
            }
            this.localStorageValue = this.params;
            this.ref.detectChanges();
            this.emit('filterChange', this.params, target);
        }
    }

    apply(target: string) {
        this.emit('filterAplay', this.params, target);
        // this.change.emit({action: 'filterAplay', data: this.params, target});
    }

    emit(action, data, target) {
        if(this.delayEmit) {clearTimeout(this.delayEmit);}
        this.delayEmit = _.delay(() => {
            this.change.emit({action: action, data: data, target});
        }, 500);

    }

    btnAction(target) {
        switch (target.type) {
            case 'button':
                switch (target.value) {
                    case 'apply':
                        this.apply(target.name);
                        break;
                }
                break;

        }

    }

    get localStorageValue(): any {
        if(this.storageName) {
            let lsv: any = localStorage.getItem(this._localStorageName);
            if(lsv) {
                lsv = JSON.parse(lsv); // new lsSaveObject(this.storageName, JSON.parse(lsv));
                return lsv[this.storageName] ? lsv[this.storageName].value : undefined;
            }
        }
        return;
    }

    set localStorageValue(v){
        if(this.storageName) {
            let lsv: any = localStorage.getItem(this._localStorageName);
            lsv = lsv ? JSON.parse(lsv) : {};
            let lsvEl = lsv[this.storageName] ? lsv[this.storageName].value : {};
            lsv[this.storageName] = new lsSaveObject(this.storageName, _.extend(lsvEl, v));
            localStorage.setItem(this._localStorageName, JSON.stringify(lsv));
        }
    }

    getTypeItemOptions(item): any {
        switch (item.type) {
            case 'number':
            case 'string':
                if(_.isArray(item.value) ) {
                    return 'select';
                } else {
                    return item.type;
                }
                break;
            default:
                return item.type;
        }
        return;
    }

    timeIntervalAction (value, target) {
        if (_.isArray(value)) {
            this.changeParams(value, target);
            // clearTimeout(this.delayInterval);
            // this.delayInterval = _.delay(() => {
            //     this.changeParams(value, target);
            // }, 300);

        }
    }


    changeBsDaterangepicker () {}

    ngAfterViewInit() {
        let thet = this;
        _.each(this.options, (v) => {
            if(v.type == 'timeInterval') {
                var slider = new Slider(
                    '#filter_' + v.name,
                    {
                        // formatter: _.bind(this.timeIntervalAction, this, v.name),
                        formatter: function (value) {
                            thet.timeIntervalAction(value, this.id);
                        },
                        min: v.value[0],
                        max: v.value[1],
                        id: v.name,
                        value: thet.params[v.name]
                    }
                );

            }
        })
    }

    setDefault(target: string) {
        let opt = _.findWhere(this.options, {name: target});
        if( opt && opt.default) {
            this.params[target] = opt.default;
        }
    }

    dataIntervalIsValue(val) {
        return _.isNumber(val) ? val : 'custom';
    }

    dataIntervalIsCustom(val) {
        return _.isArray(val);
    }

}
