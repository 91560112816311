import {Component, OnInit, OnDestroy, Input, ElementRef, AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() action: string = 'loading';
  @Input() progress: string;
  elConfig: any;

  constructor(private element: ElementRef) {}

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit(): void {
    // debugger;

    let el = this.element.nativeElement.parentElement;
    el.style.display = 'block';
    el.style.position = 'relative';
    el.style.minHeight = this.element.nativeElement.offsetHeight + 'px';
  }

}

//
//
// import {Component, OnInit, OnDestroy, Input, ElementRef} from '@angular/core';
//
// @Component({
//   selector: 'app-loader',
//   templateUrl: './loader.component.html',
//   styleUrls: ['./loader.component.scss']
// })
// export class LoaderComponent implements OnInit, OnDestroy {
//   @Input() action: string;
//   elConfig: any;
//
//   constructor(private element: ElementRef) {}
//
//   ngOnInit() {
//     let el = this.element.nativeElement.parentElement;
//     // this.elConfig = {
//     //   display: el.style.display,
//     //   po
//     // }
//     el.style.display = 'block';
//     el.style.position = 'relative';
//     el.style.minHeight = '60px';
//   }
//
//   ngOnDestroy() {
//
//   }
//
// }
