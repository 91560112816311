<h5 class="card-title">Gender and Age Distribution</h5>
<div class="card mb-0">
    <!--<div class="card-header">-->
        <!--Gender and Age Distribution Table-->
    <!--</div>-->
    <div class="card-body" *ngIf="complite">
        <div>
            <h5> Audience People Count {{genderCount.male.value + genderCount.female.value + genderCount.undefined.value}}</h5>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="callout callout-info">
                    <small class="text-muted">Male</small>
                    <br>
                    <strong class="h4">{{genderCount.male.value}}</strong>
                    <span class="text-muted small">({{ (genderCount.male.value * 100 / genderCount.total ).toFixed(1)}}%)</span>
                </div>
            </div><!--/.col-->
            <div class="col-sm-4">
                <div class="callout callout-danger">
                    <small class="text-muted">Female</small>
                    <br>
                    <strong class="h4">{{genderCount.female.value}}</strong>
                    <span class="text-muted small">({{ (genderCount.female.value * 100 / genderCount.total ).toFixed(1)}}%)</span>
                    <!--<span>({{(genderData.identified * 100 / genderData.total).toFixed(1)}}%)</span>-->
                </div>
            </div>
            <div class="col-sm-4">
                <div class="callout callout-secondary">
                    <small class="text-muted">Undefined</small>
                    <br>
                    <strong class="h4">{{genderCount.undefined.value}}</strong>
                    <span class="text-muted small">({{ (genderCount.undefined.value * 100 / genderCount.total ).toFixed(1)}}%)</span>
                </div>
            </div>
        </div>
        <hr class="mt-0">
        <div class="progress-group mb-4" *ngFor="let item of groupList">
            <div class="progress-group-header">
                <div>{{genderCount.male.values[item].title}}</div>
                <div class="ml-auto">
                    <div class="callout callout-info" style=" display: inline-block; margin: 0" *ngIf="genderCount.male.values[item].title !== 'Undefined'">
                        {{genderCount.male.values[item].count}} <span class="text-muted small">({{genderCount.male.values[item].percent.toFixed(1)}}%)</span>
                    </div>
                    <div class="callout callout-danger" style=" display: inline-block; margin: 0" *ngIf="genderCount.female.values[item].title !== 'Undefined'">
                        {{genderCount.female.values[item].count}} <span class="text-muted small">({{genderCount.female.values[item].percent.toFixed(1)}}%)</span>
                    </div>
                    <div class="callout callout-secondary" style=" display: inline-block; margin: 0" *ngIf="genderCount.undefined.values[item].title === 'Undefined'">
                        {{genderCount.undefined.values[item].count}} <span class="text-muted small">({{genderCount.undefined.values[item].percent.toFixed(1)}}%)</span>
                    </div>
                </div>
            </div>
            <div class="progress-group-bars">
                <div class="progress progress-xs" *ngIf="genderCount.male.values[item].title !== 'Undefined'">
                    <div class="progress-bar bg-info" role="progressbar" [style.width.%]="genderCount.male.values[item].percent" aria-valuenow="genderCount.male.values[item].percent" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress progress-xs" *ngIf="genderCount.female.values[item].title !== 'Undefined'">
                    <div class="progress-bar bg-danger" role="progressbar" [style.width.%]="genderCount.female.values[item].percent" aria-valuenow="genderCount.female.values[item].percent" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress progress-xs" *ngIf="genderCount.undefined.values[item].title === 'Undefined'">
                    <div class="progress-bar bg-secondary" role="progressbar" [style.width.%]="genderCount.undefined.values[item].percent" aria-valuenow="genderCount.undefined.values[item].percent" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="small mb-3">
    This table represents the general audience given at a respective time frame. It is a distribution based on age and gender.
</div>

<app-loader *ngIf="loading" class="over"></app-loader>
