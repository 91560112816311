import { Component, OnInit } from '@angular/core';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {ServingsModel} from '@app/servings/model';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-servings-item-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class ServingsItemLogComponent  extends CoreBaseItemConroller<ServingsModel> implements OnInit {

  data: ServingsModel;
  api: ServingsService;
  logs: any;

  constructor(
      api: ServingsService,
      aRoute: ActivatedRoute,
      router: Router) {
    super(api, aRoute, router);
  }

  ngOnInit() {
    this.data = this.api.currentItem;
    this.getLogs();
  }

  getLogs () {
    this.loading = true;
    this.api.getLog(this.data.id)
        .pipe(
            finalize(() => {
              this.loading = false;
            })
        )
        .subscribe(
            (r) => {
              this.logs = r;
            },
            (err) => {
              this.error = err;
            }
        );
  }

}
