import {Component, Input, OnInit} from '@angular/core';
import {DashboardsService} from '@app/dashboards/dashboards.service';
import {DashboardsBase} from '@app/dashboards/items/dashboards_base';
// import pluginDataLabels from 'chartjs-plugin-datalabels';
// import { Chart } from 'chart.js';

@Component({
    selector: 'app-dashboards-pie-count',
    templateUrl: './pie-count.component.html',
    styleUrls: ['./pie-count.component.scss']
})
export class PieCountComponent extends DashboardsBase implements OnInit {

    @Input() parent: any;
    data: any;
    genderCount: any = {};
    listError: any;
    complite = false;
    groupList = [];

    @Input() index = 'gender-age-count';

    constructor( api: DashboardsService ) {
        super(api);
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.getDataIndex( this.index );
    }

    prepareResponce(r, index?, data?) {
        switch (index) {
            case 'appearance-count':
                this.pieData = { data: [], count: 0 };
                this.pieLabels = [];
                for ( let item of r.classes ) {
                    this.pieData.data.push(item.values.count);
                    this.pieData.count += item.values.count;
                    this.pieLabels.push( item.title );
                }
                break
            default:
                this.data = this.prepare(r);
        }

        this.complite = true;
    }


    prepare(data) {
        this.pieData = { data: [], count: 0 };
        this.pieLabels = [];
        for ( let item of data.classes ) {
            this.pieData.data.push(item.value);
            this.pieData.count += item.value;
            this.pieLabels.push( item.title );
        }
    }

    public pieData ;
    public pieLabels ;
    public pieChartType = 'doughnut'; //'pie';
    public pieOptionsFemale = {
        legend : {
            position: "left"
        },

        plugins: {
            datalabels: {
                // color: 'white',
                display: true,
                font: {
                    weight: 'bold'
                },
                position: 'outside'
            }
        }
    };

    public pieOptionsMale = {
        legend : {
            position: "right"
        }
    };

    // events
    public chartClicked(e: any): void {
        // console.log(e);
    };

    public chartHovered(e: any): void {
        // console.log(e);
    };
}
