<div class="clearfix">
    <h5 class="float-left">Filter</h5>
    <!--<button class="btn btn-primary float-right btn-sm" (click)="apply(true)" >apply</button>-->
</div>

<div *ngIf="groups">
    <div class="form-group" *ngFor="let item of groups.list | keyvalue">
        <label>{{item.key }}</label>
        <ng-multiselect-dropdown
                class="printDisplayNone"
                [placeholder]="item.key"
                [data]="item.value"
                [(ngModel)]="groups.selected[item.key]"
                [settings]="dropdownSettings"
                (onSelect)="setViewMetricsGroup($event, item.key)"
                (onDeSelect)="setViewMetricsGroup($event, item.key)"
                (onSelectAll)="setViewMetricsGroup($event, item.key)"
                (onDeSelectAll)="setViewMetricsGroup($event, item.key)"
        ></ng-multiselect-dropdown>
    </div>
</div>

<div class="form-group timeFilter" *ngIf="controls.date">

    <div class="col">
        <label>from</label>
        <input type="text"
               id="datafrom"
               class="form-control"
               [value]="bsRangeValue[0] | date:'yyyy-MM-dd HH:mm:ss'"
               (change)="changeDateField( 'from', $event)"
        >
    </div>
    <div class="col">
        <label>to</label>
        <input type="text"
               id="datato"
               class="form-control"
               [value]="bsRangeValue[1] | date:'yyyy-MM-dd HH:mm:ss'"
               (change)="changeDateField( 'to', $event)"
        >
    </div>
    <button class="btn btn-success col" (click)="dpr.show()" [attr.aria-expanded]="dpr.isOpen" type="button">Date Range Picker</button>
    <input type="dateInterval"
           id="dataI"
           class="form-control"
           bsDaterangepicker
           #dpr="bsDaterangepicker"
           [bsConfig]="dpConfig"
           [(ngModel)]="bsRangeValue"
           (bsValueChange)="changeBsDaterangepicker($event)"
           style="opacity: 0; height: 0;"
    >

</div>
<div class="form-group" *ngIf="controls.interval && intervals.length">
    <label for="intervalLength">interval</label>
    <select class="form-control" id="intervalLength" class="btn" [value]="config.interval" (change)="changeInterval($event.target.value)" style="width: 100%">
        <option *ngFor="let i of intervals" [value]="i.name" [disabled]="!i.available">{{i.label}}</option>
    </select>
</div>

<button class="btn btn-primary float-right" (click)="apply(true)" *ngIf="!autoapply"  style="width: 100%;">Apply filter</button>
