import { Component, OnInit, Input } from '@angular/core';
export * from './item/item.component';
import {VideoFile, PaginateRequest} from '@app/_models';
import {VideosService} from '@app/pages/videos/videos.service';
import {VideosBaseComponent} from './videos_base.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent extends VideosBaseComponent {

  constructor(api: VideosService, roter: Router, aRouter: ActivatedRoute) {
    super(api, roter, aRouter);
  }

}
