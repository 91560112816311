import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent} from '@app/_core/loading/loading.component';
import { LoaderComponent } from '@app/_core/loader/loader.component';
import { FormConstructorComponent } from './form/constructor/constructor.component';
import { FormControllObjectComponent} from './form/controll/object/object.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationComponent } from './pagination/pagination.component';
import { AppCoreRoutingModule } from './core.module.routing';
import { FormsModule } from '@angular/forms';


import { ReactiveFormsModule } from  '@angular/forms';
import {
    FileSizePipe,
    MediaLengthPipe,
    FixedPipe,
    LogPipe,
    ObjectPipe
} from '@app/_core/pipe';
import { FormControllStreamComponent } from './form/controll/stream/stream.component';
import { ModalModule, BsModalRef} from 'ngx-bootstrap/modal';
import {HeatmapComponent} from '@app/_core/heatmap/heatmap.component';
import {CorePersonsComponent} from '@app/_core/persons/persons.component';
import { CorePlanComponent } from './plan/plan.component';
import { ProjectionComponent } from './projection/projection.component';
import {CorePlanKonvaComponent} from '@app/_core/plan-konva/plan-konva.component';
import { CoreFilterComponent } from './filter/filter.component';
import {NgxBootstrapSliderModule} from 'ngx-bootstrap-slider';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PrettyJsonPipe} from './pipe/prettyjson';

const APP_PIPE = [
    FileSizePipe,
    MediaLengthPipe,
    FixedPipe,
    LogPipe,
    ObjectPipe,
    LoaderComponent,
    LoadingComponent,
    FormConstructorComponent,
    FormControllObjectComponent,
    FormControllStreamComponent,
    PaginationComponent,
    HeatmapComponent,
    CorePersonsComponent,
    CorePlanComponent,
    CorePlanKonvaComponent,
    CoreFilterComponent,
    PrettyJsonPipe
];

@NgModule({
    declarations: [
        ...APP_PIPE,
        ProjectionComponent,

    ],
    exports: [
        ...APP_PIPE,

    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TabsModule,
        AppCoreRoutingModule,
        FormsModule,
        ModalModule.forRoot(),
        NgxBootstrapSliderModule,
        BsDatepickerModule.forRoot(),

]
})
export class CoreModule { }
