<div *ngIf="data && !data.edit ">
    <div class="clearfix">
        <h4 class="float-left">Group {{data.display_name}}</h4>


        <!--<a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle-->
           <!--class="dropdown-toggle btn float-right" (click)="false">-->
            <!--menu-->
            <!--<span class="caret"></span>-->
        <!--</a>-->
        <!--<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">-->
            <!--<div class="dropdown-header text-center"><strong>Account</strong></div>-->
            <!--<a class="dropdown-item clickabel" (click)="edit(true)">Edit</a>-->
            <!--<a class="dropdown-item clickabel" (click)="deleteConfirm()">Delete</a>-->
        <!--</div>-->

        <!--<div class="dropdown">-->
            <!--<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
                <!--Dropdown button-->
            <!--</button>-->
            <!--<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">-->
                <!--<a class="dropdown-item" href="#">Action</a>-->
                <!--<a class="dropdown-item" href="#">Another action</a>-->
                <!--<a class="dropdown-item" href="#">Something else here</a>-->
            <!--</div>-->
        <!--</div>-->

        <button class="float-right btn btn-default" (click)="edit(true)">edit</button>
        <button class="float-right btn btn-default" (click)="deleteConfirm($event, 'Delete group', 'Are you sure want to delete this group')">delete</button>
    </div>
    <p>Organization: {{data.organization_name}}</p>

    <div class="align-items-center d-flex justify-content-between mb-2">
        <h6 class="float-left">User groups</h6>
        <button class="btn btn-sm  ml-3" (click)="openModal(addGroup)">add user</button>
    </div>
    <app-settings-users  [params]="{group: data.id}" [action]="{type: 'delete', data: data.id}"></app-settings-users>


    <ng-template #addGroup>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add user to group</h5>
            <button type="button" class="close" (click)="modalRef.hide()" >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-settings-users  [params]="{not_group: data.id}" [action]="{type: 'add', data: data.id}"></app-settings-users>
        </div>
    </ng-template>
</div>

<div *ngIf="data && data.edit && hasConfig">
    <form [formGroup]="data.form" (submit)="saveData()" >

        <div class="form-group row">
            <label class="col-sm-2 col-form-label col-form-label-sm">Name</label>
            <div class="col-sm-10">
                <input class="form-control form-control" formControlName="display_name" required (keyup)="handleKeyUp($event)">
                <div *ngIf="data.form.controls.display_name.invalid && saving"
                     class="alert alert-danger">
                    <div *ngIf="data.form.controls.display_name.errors.required">
                        display name is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row" *ngIf="cloudDealer && cloudDealer.enabled">
            <label class="col-sm-2 col-form-label col-form-label-sm">Manage token</label>
            <div class="col-sm-10">
                <input class="form-control form-control" formControlName="manage_token" required>
                <div *ngIf="data.form.controls.manage_token.invalid && saving"
                     class="alert alert-danger">
                    <div *ngIf="data.form.controls.manage_token.errors.required">
                        manage token is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row"  *ngIf="cloudDealer && cloudDealer.enabled">
            <label class="col-sm-2 col-form-label col-form-label-sm">Organization name</label>
            <div class="col-sm-10">
                <input class="form-control form-control" formControlName="organization_name" required>
                <div *ngIf="data.form.controls.organization_name.invalid && saving"
                     class="alert alert-danger">
                    <div *ngIf="data.form.controls.organization_name.errors.required">
                        organization name is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row"  *ngIf="cloudDealer && cloudDealer.enabled">
            <label class="col-sm-2 col-form-label col-form-label-sm">Read token</label>
            <div class="col-sm-10">
                <input class="form-control form-control" formControlName="read_token" required>
                <div *ngIf="data.form.controls.read_token.invalid && saving"
                     class="alert alert-danger">
                    <div *ngIf="data.form.controls.read_token.errors.required">
                        read token is required.
                    </div>
                </div>
            </div>
        </div>

        <div class="alert alert-danger" *ngIf="error" >
            {{error | json}}
        </div>

        <div class="d-flex justify-content-end">
            <button (click)="canselEdit()" class="btn btn-default">cancel</button>
            <button type="submit" class="btn btn-primary">{{data.new ? 'create' : 'save' }}</button>
        </div>
    </form>
</div>
<app-loader *ngIf="loading" class="over"></app-loader>

