<div *ngIf="complited">
    <div *ngFor="let item of localOtions">
        <div [ngSwitch]="item.local_type" >
            <div *ngSwitchCase="'boolean'" class="form-check mb-2">
                <input class="form-check-input" type="checkbox" [checked]="params[item.name]" [id]="'filter' + item.name" (change)="changeParams($event, item.name)">
                <label class="form-check-label" [for]="'filter' + item.name" >
                    {{item.title || item.name}}
                </label>
            </div>

            <div class="form-group" *ngSwitchCase="'select'">
                <label>{{item.title || item.name}}</label>
                <select class="form-control" (change)="changeParams($event, item.name)" [value]="params[item.name]" [disabled]="item.disabled">
                    <option *ngFor="let i of item.value" [value]="i.value">{{i.name}}</option>
                </select>
                <!--{{item.value | json}}-->
            </div>

            <div class="form-group" *ngSwitchCase="'array'">
                <label>{{item.title || item.name}}</label>

                <div *ngFor="let i of item.value" class="form-check mb-2">

                    <input class="form-check-input"
                           type="checkbox"
                           [checked]="params[item.name].indexOf(i.value) >= 0"
                           [id]="'filter' + item.name + i.value"
                           (change)="changeParams($event, item.name, i)">
                    <label class="form-check-label" [for]="'filter' + item.name+ i.value" >
                        {{i.label}}
                    </label>
                </div>
            </div>

            <div *ngSwitchCase="'timeInterval'" class="form-group">
                <label>{{item.title || item.name}}</label>
                <div class="d-flex justify-content-between">
                    <span>{{params[item.name][0] | mediaLength}}</span>
                    <span>{{params[item.name][1] | mediaLength}}</span>
                </div>
                <input
                        [id]="'filter_' + item.name"
                        type="text"
                        data-slider-step="1"
                />
            </div>

            <div class="form-group timeFilter" *ngSwitchCase="'dateInterval'">
                <label>{{item.title || item.name}}</label>
                <div class="form-group">
                    <select class="form-control" [value]="dataIntervalIsValue(params[item.name])" (change)="changeParams($event, item.name, 'dateIntervalSelect')">
                        <option *ngFor="let i of dateIntervalList"  [value]="i.value">{{i.label}}</option>
                    </select>
                </div>
                <!--{{dataIntervalIsValue(params[item.name])}}-->
                <!--{{params[item.name] | json }}-->
                <div *ngIf="dataIntervalIsValue(params[item.name]) == 'custom'">

                    <div class="form-group">
                        <label>from</label>
                        <input type="text"
                               id="datafrom"
                               class="form-control"
                               [value]="params[item.name][0] | date:'yyyy-MM-dd HH:mm:ss'"
                               (change)="changeParams($event, item.name, 'from')"
                        >
                    </div>
                    <div class="form-group">
                        <label>to</label>
                        <input type="text"
                               id="datato"
                               class="form-control"
                               [value]="params[item.name][1] | date:'yyyy-MM-dd HH:mm:ss'"
                               (change)="changeParams($event, item.name, 'to')"
                        >
                    </div>
                    <div class="form-group">

                        <button class="btn btn-success col" (click)="dpr.show()" [attr.aria-expanded]="dpr.isOpen" type="button">Date Range Picker</button>
                        <input type="dateInterval"
                               id="dataI"
                               class="form-control"
                               bsDaterangepicker
                               #dpr="bsDaterangepicker"
                               [bsConfig]="dpConfig"
                               [(ngModel)]="params[item.name]"
                               (bsValueChange)="changeParams($event, item.name, 'datapicker')"
                               style="opacity: 0; height: 0;"
                        >
                    </div>
                </div>
                <!--<button class="btn" (click)="setDefault(item.name)">def</button>-->

            </div>


            <div *ngSwitchCase="'button'">
                <button class="btn btn-primary w-100" (click)="btnAction(item)">{{item.title}}</button>
            </div>

            <div *ngSwitchDefault>
                not control - {{item.title || item.name}} ({{item.local_type}})
            </div>

        </div>
    </div>

    <!--<div>{{params| json }}</div>-->

</div>

<!--<div  class="form-check mb-2">-->
    <!--<input class="form-check-input" type="checkbox" [id]="'filter_test'">-->
    <!--<label class="form-check-label" [for]="'filter_test'" >-->
        <!--test-->
    <!--</label>-->
<!--</div>-->

<!--<input id="ex1" data-slider-id='ex1Slider' type="text" data-slider-min="0" data-slider-max="500" data-slider-step="1" data-slider-value="[250,450]"/>-->


<!--<mv-slider [value]="1" [min]="1" [max]="100"  (change)="change()"></mv-slider>-->
<!--<mv-slider [(value)]="" [min]="1" [max]="100"  (change)="change()"></mv-slider>-->

