<div class="card" *ngIf="!currentId">
    <div class="card-body">
        <div class="row">
            <h1 class="col-auto mr-auto">Users</h1>
            <div class="col-auto">
                <button type="button" class="btn btn-primary" (click)="goto('create')"> + add </button>
            </div>
        </div>
        <table class="table table-responsive-sm table-hover table-outline mb-0" *ngIf="list">
            <thead class="thead-light">
            <tr>
                <th>Name</th>
                <th>Service / Login</th>
            </tr>
            </thead>
            <tbody>
            <tr  *ngFor="let item of list.items" (click)="goto(item.id)" class="clickabel" >
                <td>
                    {{item.name}}
                </td>
                <td>
                    <span *ngIf="item.service">Service: {{item.service}}</span>
                    <span *ngIf="item.login">Login: {{ item.login }}</span>

                </td>
            </tr>
            </tbody>
        </table>
        <app-loader *ngIf="loading" class="over" ></app-loader>
        <app-pagination *ngIf="list" [page]="list.page" [count]="list.count" [limit]="list.limit"></app-pagination>
        <div class="alert alert-danger" *ngIf="error">{{error | json}}</div>
    </div>
</div>


<div class="row " *ngIf="currentId" >
    <div class="col-12  col-xl-9">
        <div class="card">
            <div class="card-body">
                <router-outlet ></router-outlet>
            </div>
        </div>
    </div>

    <div class="d-none d-xl-block col-xl-3 toc subCol" *ngIf="list" >
        <div class="card">
            <div class="card-body">
                <ul class="section-nav">
                    <li class="toc-entry toc-h2" *ngFor="let item of list.items" >
                        <a [routerLink]="[item.id]" routerLinkActive="active" class="d-flex row align-items-center">
                            <span class="col">{{item.name}}</span>
                        </a>
                    </li>
                </ul>
                <app-pagination *ngIf="list" [page]="list.page" [count]="list.count" [limit]="list.limit" [lengthPageList]="3" [anyPage]="false"></app-pagination>
                <app-loader *ngIf="loading" class="over" ></app-loader>
            </div>
        </div>
    </div>
</div>

