import { Component, OnInit } from '@angular/core';
import {CoreBaseListConroller} from '../../../_core/base/list-controller';
import {ServingsModelList, ServingsStreamFileModel} from '../../model';
import {ServingsFilesService} from './files.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ServingsService} from '@app/servings/servings.service';
import {DialogService} from '@app/_services';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-servings-strems-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.scss']
})

export class ServingsItemFilesComponent extends CoreBaseListConroller<ServingsStreamFileModel> implements OnInit {

    currentVideo: ServingsStreamFileModel;

    newVideo: any;
    uploadProgress: any;
    uploading = false;
    uploadingError: any;
    hasDate = false;
    bsDate: Date = new Date();
    defaultParams = {
        desc: true
    };
    currentPlayingVideo: HTMLVideoElement;

    constructor(
        api: ServingsFilesService,
        aRoute: ActivatedRoute,
        router: Router,
        dialogService: DialogService,
        private  servingApi: ServingsService) {
        super(api, aRoute, router, dialogService);

    }

    ngOnInit(): void {
        // this.params.desc = true;
        this.api.pref = `serving/${this.servingApi.currentItem.id}`;
        this.newVideo = {
            file: null,
        };
        // this.params = {
        //     // limit: 5
        // };
        super.ngOnInit();
    }

    viewVideoFile(item, template){
        this.currentVideo = new ServingsStreamFileModel(item);
        this.openModal(template);
    }

    onPlayingVideo(event) {
        // debugger;
        event.preventDefault();
        // play the first video that is chosen by the user
        if (this.currentPlayingVideo === undefined) {
            this.currentPlayingVideo = event.target;
            this.currentPlayingVideo.play();
        }
    }

    closeModal(type?) {
        switch (type) {
            case 'view':

                if (this.currentPlayingVideo) {
                    this.currentPlayingVideo.pause();
                }
                this.currentPlayingVideo = null;
                this.currentVideo = null;
            case 'add':
                this.newVideo = {
                    file: null,
                };
                break;
        }
        if ( this.modalRef ) {
            this.modalRef.hide();
        }
    }

    setUploadFile(files: FileList) {
        this.newVideo.file = files.item(0);
        this.uploadProgress = null;
    }

    tooltip(item) {
        let label = '';
        switch (item.status) {
            case 'check_error':
                label = `Error: ${item.status_error}`;
                break;
            default:
                label =  `${item.staus}`;
        }
        return label;
    }

    uploadFile( event ) {
        this.uploadProgress = null;
        this.uploading = true;
        this.uploadingError = null;
        if ( this.hasDate ) {
            this.newVideo['start_time'] = this.bsDate;
        } else {
            delete this.newVideo['start_time'];
        }
        this.servingApi.uploadVideo(this.servingApi.currentItem.id, this.newVideo)
            .pipe(finalize(
                () => {this.uploading = false;}
            ))
            .subscribe(
                (r) => {
                    this.getList();
                    this.closeModal('add');
                },
                (err) => {
                    this.uploadingError = err;
                }
            );
    }

    initDate(event) {
        event.stopPropagation();
        this.hasDate = event.target.checked;
        if (this.hasDate) {
            this.bsDate = new Date();
        } else {
            // delete this.newVideo['start'];
        }
    }

    changeBsDate(event) {
        this.bsDate = event;

    }
}
