<div *ngIf="form">

    <form [formGroup]="form" >
    <!--<form [formGroup]="form" (ngSubmit)="send($event)" >-->
        <!--<app-form-constructor [options]="data.inferenceForm"  [form]="form" [data]="data.inferenceValues" ></app-form-constructor>-->
        <!--<app-form-constructor class="mb-3" [options]="form"  [form]="this.data.form.controls.inferenceValues" [data]="data.inferenceValues" ></app-form-constructor>-->
        <app-form-constructor [options]="inferenceForm"  [form]="form" [data]="data.inferenceValues" ></app-form-constructor>
        <div class="clearfix mb-2">
            <button (click)="send($event)" class="float-right btn btn-primary">save</button>
        </div>
    </form>
</div>
<app-loader *ngIf="loading" class="over"></app-loader>
