import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard, AdminGuard} from '@app/_guards';
import {DashboardsComponent} from '@app/dashboards/dashboards.component';
import {ServingsComponent} from '@app/servings/servings/servings.component';
import {ServingsItemFormComponent} from '@app/servings/item/form/form.component';

import {
    UsersComponent,
    UserItemComponent,
    StreamItemComponent


} from './pages';


// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';
import { RegisterComponent } from './views/register/register.component';
import {ServingsItemComponent} from '@app/servings/servings/item/item.component';
import {ServingsMainDashboardComponent} from '@app/servings/main/dashboard/dashboard.component';
import {PermissionErrorComponent} from '@app/views/error/permission.error.component';

export const routes: Routes = [
    {
        path: '',
        // redirectTo: 'home',
        redirectTo: 'serving',
        pathMatch: 'full',

    },
    {
        path: 'permission_error',
        component: PermissionErrorComponent,
        data: {
            title: 'permission_error'
        }
    },
    {
        path: '404',
        component: P404Component,
        data: {
            title: 'Page 404'
        }
    },
    {
        path: '500',
        component: P500Component,
        data: {
            title: 'Page 500'
        }
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login Page'
        }
    },
    {
        path: 'logout',
        component: LogoutComponent,
        data: {
            title: 'Logout Page'
        }
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {
            title: 'Register Page'
        }
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: DefaultLayoutComponent,
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'home',
                data: {
                    parent: {type: 'index', param: null}
                },
                component: ServingsMainDashboardComponent
            },
            {
                path: 'serving',
                data: {path: 'serving'},
                loadChildren: () => import('@app/servings/servings.module').then(m => m.ServingsModule)
                // loadChildren: '@app/servings/servings.module'
            },
            {
                path: 'files',
                data: {path: 'files'},
                loadChildren: () => import('@app/files/files.module').then(m => m.FilesModule)
            },
            {
                path: 'projection',
                data: {path: 'projection'},
                loadChildren: () => import('@app/projection/projection.module').then(m => m.ProjectionModule)
            },
            {
                path: 'settings',
                canActivate: [AdminGuard],
                data: {path: 'settings'},
                loadChildren: () => import('@app/settings/settings.module').then(m => m.SettingsModule)
            },

            {
                path: 'users',
                component: UsersComponent,
                data: {
                    title: 'Users'
                },
                children: [
                    {
                        path: ':itemId',
                        component: UserItemComponent
                        }
                ]
            }
        ]
    },
    { path: '**', component: P404Component }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
