import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {UserModel, PaginateRequest, CurrentUser} from '../_models';
import {CoreBaseService} from '../_core/base/service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ConfigService extends CoreBaseService<UserModel> {
    _pref = 'config';
    current: any;
    private configSubject: BehaviorSubject<any>;
    public config: Observable<any>;
    configSpace: any;

    constructor(http: HttpClient) {
        super(http);

        this.configSubject = new BehaviorSubject<any>({});
        this.config = this.configSubject.asObservable();
        this.getConfig().subscribe();
    }

    getConfig()  {
        return this.http.get(this.getUrl())
            .pipe(map(config => {
                this.configSubject.next(config);
                return config;
            }));
    }


    createCS() {
        this.configSpace = {};
    }

    removeCS() {
        this.configSpace = null;
    }

    setCSP( name: string, params: any) {
        if ( this.configSpace ) {
            this.configSpace[name] = params;
        }
    }

    getCSP (name: string) {
        if ( this.configSpace ) {
            return this.configSpace[name];
        }
        return null;
    }

}
