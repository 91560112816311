import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {PersonsBase} from './persons_base';
import {PersonsService} from '@app/pages/persons/persons.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-persons-filter',
    templateUrl: './persons_filter.component.html'
})

export class PersonsFilterComponent implements OnInit {
    @Input() serach: any;
    @Output() action: EventEmitter<any> = new EventEmitter<any>();

    constructor( ) {
    }

    ngOnInit() {
        if ( !this.serach ) this.initSearch();
    }

    initSearch() {
        this.serach = {name: null, company: null, position: null};
    }

    getList(action = 'list') {
        this.action.emit({action: action, data: this.serach});
    }

    close() {
        this.initSearch();
        this.getList('close');
    }
}
