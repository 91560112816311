

<div *ngIf="opt" [formGroup]="form" class="row align-items-start w-100" >
<!--<div *ngIf="opt" [formGroup]="form" class="row align-items-start w-100" > -->
    <div *ngFor="let item of opt"  [style.width.%]="(item.width && item.TYPE !== 'streams') ? item.width : 100">
        <div [ngSwitch]="item.TYPE"  class="col w-100">
            <div class="card " *ngSwitchCase="'group'" [style.width.%]="item.width ? item.width : 100" >
                <div class="card-body">
                    <h5 class="card-title">{{item.label ? item.label : item.name}}</h5>
                    <app-form-constructor class="d-flex" [options]="item.elements" [form]="form" [data]="data" ></app-form-constructor >
                </div>
            </div>

            <div class="form-group" *ngSwitchCase="'array'" >
                <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
                <select [formControlName]="item.name" class="form-control"  >
                    <option *ngFor="let opt of item.value" [ngValue]="opt">
                        {{opt}}
                    </option>
                </select>
            </div>
            <div  class="form-group" *ngSwitchCase="'int'">
                <label>{{item.label ? item.label : item.name}}</label>
                <input type="number" [formControlName]="item.name"  class="form-control" />
            </div>
            <div  class="form-group" *ngSwitchCase="'string'">
                <label>{{item.label ? item.label : item.name}}</label>
                <input type="text" [formControlName]="item.name" class="form-control" />
            </div>

            <div class="form-check" *ngSwitchCase="'boolean'">
              <label class="" [for]="item.name">
                {{item.label ? item.label : item.name}}
              </label>
                <div style="height: calc(1.5em + 0.75rem + 2px);" class='d-flex align-items-center'>
              <input class="" type="checkbox" [formControlName]="item.name"  [id]="item.name">
              <!--<input class="form-check-input" type="checkbox" [formControlName]="item.name"  [id]="item.name">-->
              </div>
            </div>

            <!--<md-input-container ng-switch-when="boolean" flex>-->
            <!--<md-checkbox ng-model="$ctrl.ngModel[item.name]" >{{item.label ? item.label : item.name}}</md-checkbox>-->
            <!--</md-input-container>-->

            <div class="form-group" *ngSwitchCase="'float'">
                <label>{{item.label ? item.label : item.name}}</label>
                <input type="number" step="any" [formControlName]="item.name" class="form-control" />
            </div>
            <div class="form-group" *ngSwitchCase="'streams'">
                <app-form-controll-stream [formControlName]="item.name" ></app-form-controll-stream>
            </div>
            <div  class="form-group" *ngSwitchDefault flex>
                <h5>{{item.label ? item.label : item.name}}</h5>
                <div>{{item.name}} - {{item.TYPE}}</div>
                {{item | json}}
            </div>
        </div>
    </div>
</div>













<!--<div  class="servingSpec" layout="row" layout-align="start" layout-wrap flex>-->
    <!--<div  ng-switch="item.TYPE" ng-repeat="item in $ctrl.opt" flex="{{item.width ? item.width : 100}}" layout="row"  layout-align="start ">-->
        <!--<md-input-container ng-switch-when="array" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<md-select ng-model="$ctrl.ngModel[item.name]">-->
                <!--<md-option ng-value="opt" ng-repeat="opt in item.value">{{ opt }}</md-option>-->
            <!--</md-select>-->
        <!--</md-input-container>-->

        <!--<div ng-switch-when="arrayGallery" class="radioGallery flex">-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<md-radio-group  ng-model="$ctrl.ngModel[item.name]" layout="row" layout-wrap layout-align="start start" >-->
                <!--<md-radio-button ng-repeat="v in $ctrl.arrayGallery[item.name]" value="{{v[1]}}" class="md-primary" layout="column" layout-align="center">-->
                    <!--<div class="icon" style=' background: url("{{v[2]}}") center center no-repeat; background-size: contain;'></div>-->
                    <!--<span class="label">{{v[1]}}</span>-->
                <!--</md-radio-button>-->
            <!--</md-radio-group>-->
        <!--</div>-->

        <!--<div ng-switch-when="bytes" ng-if="!$ctrl.multipart" flex app-filereader-dec ng-model="$ctrl.ngModel[item.name]" type="item.type" value="item.value" label="item.label ? item.label : item.name" >-->
            <!--&lt;!&ndash;<div app-filereader-dec ng-model="$ctrl.ngModel[item.name]" type="item.type" value="item.value" label="item.label ? item.label : item.name"></div>&ndash;&gt;-->
        <!--</div>-->

        <!--<div ng-switch-when="byte" ng-if="!$ctrl.multipart" flex class="filereader">-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--&lt;!&ndash;<button class="btn-file-input">Upload File</button>&ndash;&gt;-->
            <!--<input ng-model="$ctrl.ngModel[item.name]" nane="{{item.type + '_' + item.name}}" type="file"  app-filereader >-->
        <!--</div>-->

        <!--<div ng-switch-when="bytes" ng-if="$ctrl.multipart" flex class="filereader">-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input ng-model="$ctrl.ngModel[item.name]" nane="{{item.type + '_' + item.name}}" app-filereader type="file"  >-->
        <!--</div>-->

        <!--<div ng-switch-when="byte" ng-if="$ctrl.multipart" flex class="filereader">-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input ng-model="$ctrl.ngModel[item.name]" nane="{{item.type + '_' + item.name}}" app-filereader type="file"  >-->
        <!--</div>-->

        <!--&lt;!&ndash;<div ng-switch-when="bytes" ng-if="$ctrl.multipart" flex class="filereader">&ndash;&gt;-->
        <!--&lt;!&ndash;<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>&ndash;&gt;-->
        <!--&lt;!&ndash;<input ng-model="$ctrl.ngModel[item.name]" nane="{{item.type + '_' + item.name}}" select-ng-files type="file"  >&ndash;&gt;-->
        <!--&lt;!&ndash;</div>&ndash;&gt;-->

        <!--&lt;!&ndash;<div ng-switch-when="byte" ng-if="$ctrl.multipart" flex class="filereader">&ndash;&gt;-->
        <!--&lt;!&ndash;<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>&ndash;&gt;-->
        <!--&lt;!&ndash;<input ng-model="$ctrl.ngModel[item.name]" nane="{{item.type + '_' + item.name}}" select-ng-files type="file"  >&ndash;&gt;-->
        <!--&lt;!&ndash;</div>&ndash;&gt;-->


        <!--<md-input-container ng-switch-when="boolean" flex>-->
            <!--<md-checkbox ng-model="$ctrl.ngModel[item.name]" >{{item.label ? item.label : item.name}}</md-checkbox>-->
        <!--</md-input-container>-->

        <!--<md-input-container ng-switch-when="bool" flex>-->
            <!--<md-checkbox ng-model="$ctrl.ngModel[item.name]">{{item.label ? item.label : item.name}}</md-checkbox>-->
        <!--</md-input-container>-->

        <!--<md-input-container ng-switch-when="select" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<md-select ng-model="$ctrl.ngModel[item.name]">-->
                <!--<md-option ng-value="opt" ng-repeat="opt in item.options">{{ opt }}</md-option>-->
            <!--</md-select>-->
        <!--</md-input-container>-->

        <!--<div class="cmp-block-compact" ng-switch-when="storage" flex>-->
            <!--<h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>-->
            <!--<div class="cmp-block-compact-content">-->
                <!--<cmp-storage-->
                        <!--data="$ctrl.ngModel[item.name]"-->
                        <!--available="item.available"-->
                        <!--workspace-name="$ctrl.workspace"-->
                        <!--cluster-id="$ctrl.clusterId"-->
                        <!--only-data="true"-->
                        <!--form="add"-->
                <!--&gt;</cmp-storage>-->
            <!--</div>-->
        <!--</div>-->

        <!--<div class="cmp-block-compact" ng-switch-when="model" flex>-->
            <!--<label>{{item.label || item.name}}</label>-->
            <!--<cmp-storage-model data="$ctrl.ngModel[item.name]" workspace-name="$ctrl.workspace" flex ></cmp-storage-model>-->
        <!--</div>-->
        <!--<div class="cmp-block-compact" ng-switch-when="dataset" flex>-->
            <!--<label>{{item.label || item.name}}</label>-->
            <!--<cmp-storage-dataset data="$ctrl.ngModel[item.name]" workspace-name="$ctrl.workspace" flex ></cmp-storage-dataset>-->
        <!--</div>-->
        <!--<div class="cmp-block-compact" ng-switch-when="git" flex>-->
            <!--<label>{{item.label || item.name}}</label>-->
            <!--<cmp-storage-git data="$ctrl.ngModel[item.name]" workspace-name="$ctrl.workspace" flex ></cmp-storage-git>-->
        <!--</div>-->

        <!--<div class="cmp-block-compact" ng-switch-when="stream" flex>-->
            <!--<cmp-form-constructor-stream data="$ctrl.ngModel[item.name]"  flex ></cmp-form-constructor-stream>-->
        <!--</div>-->

        <!--<div class="cmp-block-compact" ng-switch-when="streams" flex>-->
            <!--<cmp-form-constructor-streams data="$ctrl.ngModel[item.name]"  flex ></cmp-form-constructor-streams>-->
        <!--</div>-->

        <!--<md-input-container ng-switch-when="string" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="text" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->

        <!--<md-input-container ng-switch-when="int" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->
        <!--<md-input-container ng-switch-when="int8" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->
        <!--<md-input-container ng-switch-when="int16" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->
        <!--<md-input-container ng-switch-when="int32" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->
        <!--<md-input-container ng-switch-when="int64" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->

        <!--<md-input-container ng-switch-when="uint" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="1" min="0" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->
        <!--<md-input-container ng-switch-when="uint8" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="1" min="0" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->
        <!--<md-input-container ng-switch-when="uint16" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="1" min="0" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->

        <!--<md-input-container ng-switch-when="float" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="any" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->
        <!--<md-input-container ng-switch-when="double" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="number" step="any" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->

        <!--<md-input-container ng-switch-when="text" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<textarea type="text" ng-model="$ctrl.ngModel[item.name]"></textarea>-->
        <!--</md-input-container>-->

        <!--<div class="cmp-block-compact" ng-switch-when="group" flex>-->
            <!--<h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>-->
            <!--<div class="cmp-block-compact-content" flex>-->
                <!--<cmp-form-constructor  ng-model="$ctrl.ngModel" api="$ctrl.groupApis[item.name]" workspace="$ctrl.workspace" options="item.elements" multipart="$ctrl.multipart" flex></cmp-form-constructor >-->
            <!--</div>-->
        <!--</div>-->


        <!--<div class="cmp-block-compact" ng-switch-when="list" flex>-->
            <!--<h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>-->
            <!--<div class="cmp-block-compact-content" flex>-->
                <!--<cmp-autolist-single-->
                        <!--class="flex"-->
                        <!--data="$ctrl.ngModel[item.name]"-->
                        <!--form="$ctrl.form"-->
                        <!--type="item.listType"-->
                <!--&gt;</cmp-autolist-single>-->
            <!--</div>-->
        <!--</div>-->

        <!--<div class="cmp-block-compact" ng-switch-when="volume" flex>-->
            <!--<h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>-->
            <!--<div class="cmp-block-compact-content" flex layout="row" layout-align="start">-->
                <!--<cmp-select-project-volume volumes="$ctrl.appVolumes" app-controller="$ctrl.appController" ng-model="$ctrl.ngModel[item.name]"></cmp-select-project-volume>-->
            <!--</div>-->
        <!--</div>-->

        <!--<md-input-container ng-switch-default="" flex>-->
            <!--<label >{{item.label ? item.label : item.name}} ({{item.type}})</label>-->
            <!--<input type="text" ng-model="$ctrl.ngModel[item.name]">-->
        <!--</md-input-container>-->

    <!--</div>-->
<!--</div>-->
<!--&lt;!&ndash;{{$ctrl.ngModel}}&ndash;&gt;-->
<!--&lt;!&ndash;{{$ctrl.opt}}&ndash;&gt;-->
