<span *ngIf="currentUser && !currentUser.public">
    <a class="dropdown-toggle btn" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        {{ currentUser.name || '???' }}
        <span class="caret"></span>
    </a>
    <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown" >
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        <!--<a class="dropdown-item clickabel" (click)="getToken()" *ngIf="!!currentUser.admin">Token</a>-->
        <a class="dropdown-item clickabel" *ngIf="currentUser.admin" [routerLink]="['/settings']">Settings</a>
        <a class="dropdown-item" [routerLink]="'/logout'">Logout</a>
    </div>
</span>

<a *ngIf="!currentUser || currentUser.public" class="btn"  [routerLink]="'/login'">Login</a>

<ng-template #userToken>
    <div class="modal-header">
        <h5 class="modal-title" id="errorPersonAction">User token</h5>
        <button type="button" class="close" (click)="modalRef.hide()" >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="token">{{token.token}}</div>
    </div>
    <div class="modal-footer d-flex flex-row">
        <div class="col"></div>
        <button (click)="changeToken()">change token</button>
    </div>
    <app-loader class="over" *ngIf="loading"></app-loader>

</ng-template>
