import {Component, OnDestroy, Inject, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems, navItemsAdmin } from '../../_nav';
import {AuthenticationService} from '@app/_services/authentication.service';
import {CurrentUser} from '@app/_models'


@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy, OnInit {
    public navItemsBase = navItems;
    public navItemsAdmin = navItemsAdmin;
    public navItems = navItems;
    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement;
    public currentUser: CurrentUser;


    constructor( private authenticationService: AuthenticationService, @Inject(DOCUMENT) _document?: any ) {

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
        });
        this.element = _document.body;
        this.changes.observe(<Element>this.element, {
            attributes: true,
            attributeFilter: ['class']
        });
    }

    ngOnInit(): void {
        const cu = this.getCU();
        this.navItems = (cu && cu.admin) ? this.navItemsAdmin : this.navItemsBase;

        // if (cu.admin) {
        //     this.navItemsBase.push({
        //         name: 'Users',
        //         url: '/users',
        //         icon: 'icon-cam'
        //     });
        // }
    }

    getCU() {
        this.currentUser = this.authenticationService.currentUserValue;
        return this.currentUser;
    }

    ngOnDestroy(): void {
        this.changes.disconnect();
    }
}
