import {Component, Input, OnInit} from '@angular/core';
import {DashboardsService} from '@app/dashboards/dashboards.service';
import {DashboardsBase} from '@app/dashboards/items/dashboards_base';

@Component({
    selector: 'app-dashboards-people-count-block',
    templateUrl: './people-count-block.component.html',
    styleUrls: ['./people-count-block.component.scss']
})

export class PeopleCountBlockComponent extends DashboardsBase implements OnInit {

    @Input() parent: any;
    data: any;
    genderCount: any = {};
    listError: any;
    complite = false;
    groupList = [];
    genderCountDate: any;

    constructor( api: DashboardsService ) {
        super(api);
    }

    ngOnInit() {
        // this.init();
    }

    init() {
        this.getDataIndex('gender-count');
    }

    prepareResponce(r, index, data?) {
        switch (index) {
            case 'gender-count':
                this.genderCountDate = r;
                this.getDataIndex('gender-age-count');
                break;
            case 'gender-age-count':
                this.prepareCount(this.genderCountDate);
                this.data = this.prepare(r);
                this.complite = true;
                break;
        }
    }

    prepareCount(r) {
        this.genderCount['total'] = 0;
        for ( let item of r.classes ) {
            this.genderCount[item.id] = item;
            this.genderCount[item.id]['values'] = {};
            this.genderCount['total'] += item.value;
        }
    }

    prepare(data) {
        for ( let item of data.classes ) {
            this.genderCount[item.group[0].id]['values'][item.group[1].id] = {count: item.value, percent: item.value * 100 / this.genderCount.total, title: item.group[1].title };
        }
        this.groupList = [];
        for ( let item of data.parameters.onlyClasses ) {
            if (item.indexOf('age') == 0) {
                this.groupList.push(item.replace('age-', ''));
            }
        }
    }

}
