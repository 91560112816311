<!--<div class="app-body">-->
  <!--<main class="main d-flex align-items-center">-->
    <!--<div class="container">-->
      <!--<div class="row">-->
        <!--<div class="col-md-8 mx-auto">-->
          <!--<div class="card-group">-->
            <!--<div class="card p-4">-->
              <!--<div class="card-body">-->
                <!--<h2>Login</h2>-->
                <!--<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">-->
                  <!--<div class="form-group">-->
                    <!--<label for="login">Login</label>-->
                    <!--<input type="text" formControlName="login" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.login.errors }" />-->
                    <!--<div *ngIf="submitted && f.login.errors" class="invalid-feedback">-->
                      <!--<div *ngIf="f.login.errors.required">key is required</div>-->
                    <!--</div>-->
                  <!--</div>-->
                  <!--<div class="form-group">-->
                    <!--<label for="password">Password</label>-->
                    <!--<input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />-->
                    <!--<div *ngIf="submitted && f.password.errors" class="invalid-feedback">-->
                      <!--<div *ngIf="f.password.errors.required">key is required</div>-->
                    <!--</div>-->
                  <!--</div>-->
                  <!--<div class="form-group">-->
                    <!--<button [disabled]="loading" class="btn btn-primary">Login</button>-->
                    <!--<img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />-->
                  <!--</div>-->
                  <!--<div class="alert-danger" *ngIf="error">{{error}}</div>-->
                <!--</form>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
  <!--</main>-->
<!--</div>-->


<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card mx-4">
            <div class="card-body p-4">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <h2>Login</h2>
                <div class="form-group">
                  <label for="login">Login</label>
                  <input type="text" formControlName="login" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.login.errors }" />
                  <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
                    <div *ngIf="f.login.errors.required">key is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">key is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <button [disabled]="loading" class="btn btn-primary">Login</button>
                  <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>

              </form>
            </div>
            <!--<div class="card-footer p-4">-->
              <!--<div class="row">-->
                <!--<div class="col">-->
                  <!--<button (click)="oauth('google')" class="btn btn-block btn-facebook" type="button">-->
                    <!--&lt;!&ndash;<svg-icon src="assets/icon/google.svg" [svgStyle]="{ 'width.px':90 }"></svg-icon>&ndash;&gt;-->
                    <!--<svg-icon src="assets/icon/google.svg" class="pr-2" [svgStyle]="{ fill:'#fff' }"></svg-icon>-->
                    <!--<span>google</span></button>-->
                <!--</div>-->
                <!--&lt;!&ndash;<div class="col-6">&ndash;&gt;-->
                  <!--&lt;!&ndash;<button class="btn btn-block btn-twitter" type="button"><span>twitter</span></button>&ndash;&gt;-->
                <!--&lt;!&ndash;</div>&ndash;&gt;-->
              <!--</div>-->
            <!--</div>-->
            <div class="alert-danger p-4" *ngIf="error">{{error}}</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
