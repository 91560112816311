<div *ngIf="!bygender">

	<h5 class="">Traffic With All Age and Gender Distributions </h5>
	<div class="card mb-0" >
		<div class="card-body">
			<div class="row">
				<!--<div class="col-sm-5">-->
				<!--<h4 class="card-title mb-0">Traffic gender age</h4>-->
				<!--&lt;!&ndash;<div class="small text-muted">November 2017</div>&ndash;&gt;-->
				<!--</div>-->
				<div class="col-sm-7 d-none d-md-block">
				</div>
			</div>
			<div class="chart-wrapper" style="min-height:300px;margin-top:40px;" *ngIf="complite">
				<canvas baseChart class="chart"
						[datasets]="graphData"
						[labels]="graphLabel"
						[options]="mainChartOptions"
						[colors]="mainChartColours"
						[legend]="mainChartLegend"
						[chartType]="mainChartType"></canvas>
			</div>
		</div>
	</div>
	<div class="small mb-3">Total traffic based on both genders and their age distributions. Using time and line graph as a key figure to the graph.</div>
</div>

<div *ngIf="bygender">
	<h5>Traffic Separated by Gender</h5>
	<div class="card mb-0" >
		<div class="card-body">
			<!--<div class="row">-->
				<!--<div class="col-sm-5">-->
					<!--<h4 class="card-title mb-0">Traffic gender age</h4>-->
				<!--</div>-->
				<!--<div class="col-sm-7 d-none d-md-block">-->
				<!--</div>-->
			<!--</div>-->
			<div *ngIf="complite">
				<div class="chart-wrapper" style="min-height:300px;margin-top:40px;"  *ngFor="let gender of graphDataBy | keyvalue">
					<canvas baseChart class="chart"
							[datasets]="gender.value"
							[labels]="graphLabel"
							[options]="mainChartOptions"
							[colors]="mainChartColours"
							[legend]="mainChartLegend"
							[chartType]="mainChartType"></canvas>
				</div>
			</div>
		</div>
	</div>
	<div class="small mb-3">Total traffic based on both genders and their age distributions. Two charts are created to separate gender.</div>
</div>

<app-loader *ngIf="loading" class="over"></app-loader>
