import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Model} from './model';
import {PaginateRequest} from './paginate-request';

export class UserModel extends Model {

    login:	string;
    name:	string;
    password:	string;
    service:	string;

    formConfig(): any {
        return {
            login: [this.login, Validators.required],
            name: [this.name],
            password: [this.password, Validators.required],
            service: [this.service]
        };
    }

    toJSON(): any {
        return {
            id: this.id,
            login: this.login,
            name: this.name,
            password: this.password,
            service: this.service
        };
    }

    passwordGenerator () {
        return Math.random().toString(36).slice(2);
    }

}

export class UserModelList extends PaginateRequest<UserModel> {}
