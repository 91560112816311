import * as _ from 'underscore';
import {Subject} from 'rxjs';

 export class PaginateRequest <T> {
   count: number;
   desc: boolean;
   _items: T[];
   limit: number;
   options: any;
   order: string;
   page: number;

    public extAction = new Subject();

   constructor(data) {
      _.each(data, (v,k) => {
         this[k] = v;
      })
   }

   set items(data) {
      debugger
      this._items = data;
   }

   get items() {
      debugger
      return this._items;
   }

 }
