<div class="d-flex justify-content-between mb-3" *ngIf="env == 'delete'">
    <h4 class="col-auto pl-0">Events</h4>
    <button type="button" class="btn btn-primary col-auto"  (click)="openModal(addEventPerson)" >add event</button>
</div>
<div style="position: relative; min-height: 60px;">
    <table class="table table-responsive-sm table-hover table-outline mb-0" *ngIf="list">
        <thead class="thead-light">
        <tr>
            <th>Title</th>
            <th>Start</th>
            <th>end</th>
            <th *ngIf="env"></th>
        </tr>
        </thead>
        <tbody>
        <tr  *ngFor="let item of list.items" (click)="goto(item)" class="clickabel" [ngClass]="{'active': ( currentVideoId && item.id === currentVideoId ) }">
            <td>
                {{item.title}}
            </td>
            <td>
                {{ item.start}}
            </td>
            <td>
                {{ item.end}}
            </td>
            <td *ngIf="env"><button (click)="do(env, item, $event)">{{env}}</button></td>
        </tr>
        </tbody>
    </table>
    <app-pagination *ngIf="list" [page]="list.page" [count]="list.count" [limit]="list.limit"></app-pagination>

    <app-loader *ngIf="loading" class="over"></app-loader>
</div>

<ng-template #errorPersonAction>
    <div class="modal-header">
        <h5 class="modal-title" id="errorPersonAction">Error</h5>
        <button type="button" class="close" (click)="modalRef.hide()" >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger">{{error | json}}</div>
    </div>
</ng-template>


<ng-template #addEventPerson>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add person to event</h5>
        <button type="button" class="close" (click)="modalRef.hide()" >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-events-person [env]="'add'" [extAction]="extAction" [parentItem]="parentItem" [filter]="{not_person_id: parentItem}" [parentType]="parentType" *ngIf="parentType == 'person'"></app-events-person>
        <app-events-person [env]="'add'" [extAction]="extAction" [parentItem]="parentItem" [filter]="{not_for_user: parentItem}" [parentType]="parentType" *ngIf="parentType == 'user'"></app-events-person>
    </div>
</ng-template>
