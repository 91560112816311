import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {VideoFile, VideoFileClasses, VideoFileTimeline, PaginateRequest} from '@app/_models';
import {Subject} from 'rxjs';

interface ClassInfo {
    classes: VideoFileClasses[];
    timeline: VideoFileClasses[];
}


@Injectable({
    providedIn: 'root'
})
export class VideosService {
    apiUrl =  (window as any).externalConfig.apiUrl;
    pref = "";
    _pref = 'video';

    current: any;
    public extAction = new Subject();
    parent: any;

    constructor( private http: HttpClient) { }

    private getUrl(params?: string ) {
        return `${this.apiUrl}${this.pref}${this._pref}` + ( params ? `/${params}` : '');
    }

    getAll(params?: any) {
        var p = this.httpParams(params);
        var gp:any ;
        if ( p ) {
            gp = {params: p};
        }
        return this.http.get<PaginateRequest<VideoFile[]>>(this.getUrl(), gp);
    }

    getById(id: string) {
        return this.http.get<VideoFile>(this.getUrl(`${id}`));
    }

    getVideoIndex(item: VideoFile) {
        return this.http.get<[]>(this.getUrl(`${item.id}/index`));
    }

    getVideoIndexInfo(item: VideoFile, index: string) {
        return this.http.get<ClassInfo>(this.getUrl(`${item.id}/index/${index}`));
    }

    getVideoTimeline(item: VideoFile) {
        return this.http.get<VideoFileTimeline[]>(this.getUrl(`${item.id}/timeline`));
    }

    getVideoClasees(item: VideoFile) {
        return this.http.get<VideoFileClasses[]>(this.getUrl(`${item.id}/classes`));
    }

    update(item: VideoFile) {
        return this.http.put(this.getUrl(`${item.id}`), item);
    }

    delete(id: number) {
        return this.http.delete(this.getUrl(`${id}`));
    }

    httpParams(p?: any) {
        if ( p ) {
            let params = new HttpParams();
            for ( let i in p ){
                params = params.append(i, p[i]);
            }
            return params;
        }

        return null;
    }
}
