<div *ngIf="!current">
    <div class="clearfix mb-3" *ngIf="!action">
        <h4 class="float-left">Groups</h4>
        <button *ngIf="!action" class="btn btn-primary btn-sm ml-3" (click)="goto({id:'create'})">add</button>
    </div>
    <form #f="ngForm" (ngSubmit)="params.display_name = f.value.display_name; getList()" novalidate>
        <div class=" form-row">
            <div class="form-group col-9">
                <input name="display_name" ngModel class="form-control" placeholder="Name">
            </div>
            <div class="form-group col-3">
                <button class="btn btn-default form-control">Search</button>
            </div>
        </div>
    </form>

    <table class="table" *ngIf="list">
        <thead>
        <tr>
            <th>Name</th>
            <th>Organization</th>
            <th *ngIf="action"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of list.items" (click)="goto(item)" class="clickabel">
            <td>{{item.display_name}}</td>
            <td>{{item.organization_name}}</td>
            <td *ngIf="action">
                <button class="btn btn-default" (click)="actionDo(item, $event)">{{action.type}}</button>
            </td>
        </tr>
        </tbody>
    </table>
    <app-pagination *ngIf="list" [link]="!action" (action)="getList($event.data)" [page]="list.page" [count]="list.count" [limit]="list.limit"></app-pagination>
</div>

<div class="row" *ngIf="current">
    <div class="col-9">
        <router-outlet></router-outlet>
    </div>
    <div class="col-3 border-left">
        <h6>Groups</h6>
        <table class="table" *ngIf="list">
            <tbody>
            <tr *ngFor="let item of list.items" (click)="goto(item)" class="clickabel">
                <td>{{item.display_name}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
<app-loader class="over" *ngIf="loading"></app-loader>
