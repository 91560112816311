import { Component, OnInit } from '@angular/core';
import {finalize} from 'rxjs/operators';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {ServingsModel} from '@app/servings/model';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'underscore';

@Component({
    selector: 'app-serving-item-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class ServinsItemDashboardComponent extends CoreBaseItemConroller<ServingsModel> implements OnInit {

    data: ServingsModel;
    api: ServingsService;
    itemData: any;
    configMetrics: any = {};
    config: any;
    params: any = {
        limit: 10,
        page: 1
    };
    filterOpen: boolean = false;

    fOptions: any;
    fConfig: any;
    fComplite = false;
    cConfig: any;
    range: any;


    constructor(
        api: ServingsService,
        aRoute: ActivatedRoute,
        router: Router) {
        super(api, aRoute, router);
    }

    ngOnInit() {
        this.data = this.api.currentItem;
        this.config = this.api.currentItem.configFilter || this.api.config;
        // if ( this.api.config ) {
        //     this.config = this.api.config;
        // } else {
        //     this.getRange(this.data.id);
        // }
        this.getRange(this.data.id);
    }

    getRange(id) {
        this.loading = true;
        delete this.error;
        this.api.getRange(id)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(
            (r: any) => {
                this.setRange(r);
                // this.range = [r.from, r.to];
                // this.fOptions = this.filterOptions();
                // this.fConfig= this.filterConfig();
                // this.config = r;
                // this.api.config = this.config;
            },
            (err) => {
                this.setRange(this.api.getRangeByError());
                // this.error = err;
                // this.config = this.api.getRangeByError();
            }
        );
    }

    setRange(r) {
        this.range = [r.from, r.to];
        this.fOptions = this.filterOptions();
        this.fConfig= this.filterConfig();
        this.config = r;
        this.api.config = this.config;
    }

    filterOptions() {

        let inter = this.api.intervals;
        let metrix = [
            {
                label: 'People timeline',
                value: 'app-dashboards-people-timeline'
            }
        ];

        if(this.data.stream_type == 'detector') {
            metrix = [
                { value: 'app-dashboards-people-count-block', label: 'People count' },
                { value: 'Traffic Timeline', label: 'Traffic Timeline' },
                { value: 'Audience appearance', label: 'Audience appearance' },
            ];
        }

        return [
            {
                name: 'streams',
                title: 'Streams',
                type: 'array',
                value: this.data.activeStreams.map((v) => { return {value: v.key, label: v.key}}),
                default: this.data.activeStreams.map((v) => { return v.key})
            },

            {
                name: 'metrics',
                title: 'Metrics',
                type: 'array',
                value: metrix,
                default: metrix.map((v)=> { return v.value; })
            },

            {
                name: 'dateInterval',
                title: 'Date interval',
                type: 'dateInterval',
                value: this.range,
                default: this.range,
                dependence: 'interval'
            },
            {
                name: 'interval',
                title: 'Interval',
                type: 'string',
                default: inter[inter.length - 1].value  ,
                value: inter,
                valueBase: this.api.intervals
            },
            {
                name: 'apply',
                type: 'button',
                title: 'Apply',
                value: 'apply'
            }
        ]
    }

    filterConfig() {
        return _.extend(
            {},
            this.config
        );
    }

    filterChange(event) {
        switch (event.action) {
            case 'filterAplay':
            case 'filterChange':
                let c = {};
                _.each(this.fOptions, (v: any) => {
                    c[v.name] = event.data[v.name] || v.default;
                });
                this.cConfig = c;
                break;

        }
    }

}
