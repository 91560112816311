import {Component, OnDestroy, OnInit} from '@angular/core';
import {ServingsService} from '@app/servings/servings.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ServingsModel} from '@app/servings/model';
import {CoreBaseItemConroller} from '@app/_core/base/item-controller';
import {CoreBaseService} from '@app/_core/base/service';
import {HttpEventType} from '@angular/common/http';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-servings-item-streams',
    templateUrl: './streams.component.html',
    styleUrls: ['./streams.component.scss']
})
export class ServingsItemStreamsComponent extends CoreBaseItemConroller<ServingsModel> implements OnInit, OnDestroy {

    data: ServingsModel;
    api: ServingsService;

    newVideo: any;
    uploadProgress: any;
    uploading = false;
    uploadingError: any;
    vildeoList: any;

    videoUrl: string;
    aVideo: any;
    ts: number;
    tst: any;

    constructor(
        api: ServingsService,
        aRoute: ActivatedRoute,
        router: Router) {
        super(api, aRoute, router);
    }

    ngOnInit() {
        this.data = this.api.currentItem;
        if ( this.data.type === 'file') {
            this.newVideo = {
                file: null,
            };
        }
        if ( this.data.type === 'videofile') {
            this.getVideoList();
        }

        this.setTs();
        this.tst = setInterval(() => {this.setTs()}, 30000);
    }

    ngOnDestroy(): void {
        clearTimeout(this.tst);
    }

    setTs() {
        this.ts = Date.now();
    }

    getVideoList() {
        this.vildeoList = {};
        this.data.inferenceStreams.forEach((key) => {
            this.api.getVideoFiles(this.data, key.key).subscribe(
                (r: any) => {
                    if(r && r.items){
                        this.vildeoList[key.key] = r.items;
                    }
                },
                (err) => {
                    debugger;
                })
        })

    }

    getLink(img, ts: number) {
        let codec = (img.params && img.params.codec)
            ? img.params.codec
            : ( img.params && img.params.audience_codec ? img.params.audience_codec : 'mjpg' );
        return `/api/v1/serving/${this.data.id}/stream/${img.key}/${codec}?t=${ts}`;
    }

    setUploadFile(files: FileList) {
        this.newVideo.file = files.item(0);
        this.uploadProgress = null;
    }

    uploadFile( event ) {
        this.uploadProgress = null;
        this.uploading = true;
        this.uploadingError = null;
        this.api.uploadVideo(this.data.id, this.newVideo)
            .pipe(finalize(
                () => {this.uploading = false; delete this.uploadProgress;}
            ))
            .subscribe(
            (r) => {

                },
            (err) => {
                // debugger
                this.uploadingError = err;
            }
                // .subscribe(
            // (r) => {
            //     switch (r.type) {
            //         case 'progress':
            //             this.uploadProgress = r.data;
            //             break;
            //         case 'complite':
            //             this.uploading = false;
            //             this.uploadProgress = null;
            //             break;
            //         case 2:
            //         default:
            //             if (r.status !== 200) {}
            //     }
            // },
            // (err) => {
            //     this.uploadingError = err;
            // }
        );
        // debugger
    }

    uploadVideoFile(event, key: string) {
        // debugger
        const file = event.currentTarget.files[0] as File;
        this.loading = true;
        this.uploadingError = null;
        this.api.videoFielUpload(this.data, key, file).pipe(
            finalize(() => {this.loading = false; })
        )
            .subscribe(
                resp => {
                    if (resp.type === HttpEventType.Response) {
                        console.log('Upload complete');
                        this.getVideoList();
                    }
                    if (resp.type === HttpEventType.UploadProgress) {
                        this.uploadProgress = Math.round(100 * resp.loaded / resp.total).toString();
                        console.log('Progress ' + this.uploadProgress + '%');
                    }
                },
                (err) => {
                    this.uploadingError = err;
                });
        event.currentTarget.value = null;
    }

    playVideo(key: string, video: any) {
        ///serving/{serving}/stream/{key}/video/{video}/get
        this.aVideo = video;
        this.videoUrl = this.api.getUrl(`${this.data.id}/stream/${key}/video/${video.id}/get`);
    }

}
