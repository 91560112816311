import { Component, OnInit, OnDestroy } from '@angular/core';
import {EventsService} from '@app/pages/events/events.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Event} from '@app/_models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {VideosService} from '@app/pages/videos/videos.service';


@Component({
  selector: 'app-event-item-video',
  template: `
    <!--<h4>Videos</h4>-->
    <app-videos-event *ngIf="itemId" [parent]="{type: 'events', data: itemId}"></app-videos-event>`,
  // styleUrls: ['./item.component.scss']
})
export class EventsItemVideoComponent implements OnInit, OnDestroy {

  itemId: string;
  paramsSubscription: Subscription;

  constructor( private activatedRoute: ActivatedRoute,  router: Router,  private VideosService: VideosService) {
    this.itemId = activatedRoute.parent.snapshot.params.itemId;
    this.setVidepParentEvent(this.itemId);
    this.paramsSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.itemId = this.activatedRoute.parent.snapshot.params.itemId;
        this.setVidepParentEvent(this.itemId);
      }
    });

  }

  setVidepParentEvent (itemId) {
    this.VideosService.parent = {type: 'events', data: itemId};
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
