import {AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'underscore';
import {HttpParams} from '@angular/common/http';
declare var Dragdealer: any;

class confingParamsHeatmap {
    from: any;
    to: any;

    jpeg_quality: number;
    projection_source: boolean;
    highlight_dots: boolean;
    _t: any;

    constructor (data) {
        // if(data.start && data.duration) {
        //     // this.from = this.getTimeHeatmap(this.from);
        //     // this.to = this.getTimeHeatmap(this.to);
        //     // this.from = new Date(data.start + data.timeInterval[0] * 1000).toISOString();
        //     // this.to =  new Date(data.start + data.timeInterval[1] * 1000).toISOString();
        //     // this.to =  new Date(data.start + (data.duration | 0)).toISOString();
        // }



        if(data.dateInterval) {
            // this.from = new Date(data.dateInterval[0]).toISOString();
            // this.to = new Date(data.dateInterval[1]).toISOString();
            if(_.isArray(data.dateInterval)) {
                this.from = new Date(data.dateInterval[0]).toISOString();
                this.to = new Date(data.dateInterval[1]).toISOString();
            } else {
                this.from = new Date(Date.now() - data.dateInterval).toISOString();
                this.to = new Date(Date.now()).toISOString();
            }

        }

        if(data.from && data.to) {
            this.from = this.getTimeHeatmap(data.from);
            this.to = this.getTimeHeatmap(data.to);
        }

        this.jpeg_quality = data.jpeg_quality;
        this.projection_source = data.projection_source;
        this.highlight_dots = data.highlight_dots;
        this._t = Date.now();

    }

    getTimeHeatmap(t) {
        return Math.floor(t/1000);
    }

    toJSON() {
        let json = {
            _t: this._t,
            from: this.from,
            to: this.to,
        };
        if(this.projection_source) {
            json['projection_source'] = this.projection_source;
        }
        if(this.highlight_dots) {
            json['highlight_dots'] = this.highlight_dots;
        }
        return json;
    }
}


@Component({
    selector: 'app-heatmap',
    templateUrl: './heatmap.component.html',
    styleUrls: ['./heatmap.component.scss']
})
export class HeatmapComponent implements OnInit, AfterViewInit, OnChanges {

    @ViewChild('timeline') timelineEl: ElementRef;
    // @ViewChild('timeline', {static: true}) timelineEl: ElementRef;
    @ViewChild('intervaDrop') intervalEl: ElementRef;
    // @ViewChild('intervaDrop', {static: true}) intervalEl: ElementRef;
    @Input() id: any;
    // @Input() meta: any;
    // @Input() serving: string;
    @Input() config: any;

    @Input() baseUrl: string;
    @Input() type: string = 'heatmap';
    @Input() size: any;
    @Input() hasProjection: boolean = false;


    zone: string;
    hitmapUrl: string;
    errorImageMessage: string;

    start: number;
    end: number;
    from: number;
    to: number;
    interval: number;

    localConfig: any = {};


    timelineWidthPX: number;
    intervalWidthPX: number;
    intervalLeftPX: number;

    persent = 0;

    drug: any;
    delayImg: any;
    delayImgCount = 0;
    delayImgCountFull = 0;
    loading = false;
    viewComplite: boolean = false;

    constructor(){
    }

    setBaseInterval(config) {
        if(!config) {return }

        if(config.dateInterval) {
            if(_.isArray(config.dateInterval)) {
                this.start = new Date(config.dateInterval[0]).getTime();
                this.end = new Date(config.dateInterval[1]).getTime();
            } else {
                this.start = new Date(Date.now() - config.dateInterval).getTime();
                this.end = Date.now();
            }

        }

        if(config.from && config.to) {
            this.start = new Date(config.from).getTime();
            this.end = new Date(config.to).getTime();
        }

        if(config.start && config.duration) {
            this.start = config.start;
            this.end = config.start + config.duration;
        }

        if(config.intervalms) {
            this.interval =  ( config.intervalms == 'all' || config.intervalms > (this.end - this.start ))
                                ? this.end - this.start
                                : config.intervalms;
        }
        else {
            this.interval = this.end - this.start;
        }
        this.setIntervalByPoint();

        if(this.drug) {
            this.drug.reflow();
        }

    }

    ngAfterViewInit(): void {
        this.viewComplite = true;
        // this.setIntervalByPoint();
        if(this.config) {
            this.initPlayerTime();
        }
    }


    initPlayerTime() {
        if(!this.viewComplite) { return; }
        if(this.drug) { return; }

        this.setIntervalByPoint();
        this.drug = new Dragdealer('playerTime_' + this.id, {
            handleClass: 'interval',
            x: this.persent,
            animationCallback: (x, y) => {
                this.persent = x;
                console.log("p " + this.persent);
                this.getFromTo();
            }
        });
    }

    setIntervalByPoint() {
        if(this.timelineEl && this.intervalEl) {
            this.getFromTo();
            const timelineWidthPX = this.timelineEl.nativeElement.offsetWidth;
            const timeLength = this.end - this.start;
            const spx = timelineWidthPX / timeLength ;
            this.intervalWidthPX = spx * this.interval;
            this.intervalWidthPX = this.intervalWidthPX > timelineWidthPX ? timelineWidthPX : this.intervalWidthPX;
            this.intervalEl.nativeElement.style.width = this.intervalWidthPX + 'px';
        }
    }

    getFromTo() {
        const timeLength = this.end - this.start - this.interval;
        this.from = this.start + timeLength * this.persent;
        this.to = this.from + this.interval;
        if (this.delayImg) {clearTimeout(this.delayImg);}
        this.delayImgCount ++;
        this.delayImg = _.delay(() => {
            this.setImg();
        }, 300);
    }

    setImg() {
        if(!this.config) { return; }
        delete this.errorImageMessage;
         this.delayImgCount = 0;
        const c =  new confingParamsHeatmap(_.extend({}, this.config, this.localConfig));
        c.from = this.getTimeHeatmap(this.from);
        c.to = this.getTimeHeatmap(this.to);
        let params = new URLSearchParams(c.toJSON()); // new HttpParams(c);
        this.hitmapUrl = `${this.baseUrl}/${this.type}/jpeg?${params.toString()}`;
        this.loading = true;
    }

    getTimeHeatmap(t) {
        return Math.floor(t/1000);
    }

    getFrame(time) {
        return ((time - this.start) / 1000).toFixed(0);
    }

    step(s: number) {
        const pi = this.interval / (this.end - this.start);
        // const pi = this.interval / (1000 * (this.end - this.start));
        this.persent  = this.persent + s * pi;
        if ( this.persent < 0) { this.persent = 0; }
        if ( this.persent > 100) { this.persent = 100; }
        this.getFromTo();
        this.setIntervalByPoint();
        const v = this.drug.getValue();
        v[0] = this.persent;
        this.drug.setValue(v[0], v[1], v[2]);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setIntervalByPoint();
    }

    loadImg(event) {
        this.loading = false;
        // debugger;
    }

    errorImege(event) {
        this.loading = false;
        this.errorImageMessage = 'Not data';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.config && this.config) {
            if(this.config['highlight_dots']){
                this.localConfig['highlight_dots'] = this.config['highlight_dots']
            }
            if(this.config['projection_source']){
                this.localConfig['projection_source'] = this.config['projection_source']
            }
            this.setBaseInterval(this.config);
            this.initPlayerTime();
        }
    }

    getTime(time) {
        if(!time) { return 0;}
        // debugger
        if(this.config.start && this.config.duration) {
            this.zone = 'UTC';
            return time - this.start;
        } else {
            // this.zone = 'GMT';
            // this.zone = 'UTC';
            this.zone = null;
            return time;
        }
    }

    ngOnInit(): void {

    }

    changeParams(event, target) {
        this.localConfig[target] = event.currentTarget.checked;
        this.setBaseInterval(_.extend({}, this.config, this.localConfig));
    }
}
