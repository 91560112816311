import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ServingsComponent} from './servings/servings.component';
import {ServingsItemComponent} from './servings/item/item.component';
import {ServingsItemFormComponent} from '@app/servings/item/form/form.component';
import {ServingsItemStreamsComponent} from '@app/servings/item/streams/streams.component';
import {DashboardsComponent} from '@app/dashboards/dashboards.component';
import {ServingsItemLogComponent} from '@app/servings/item/log/log.component';
import {ServinsItemDebugComponent} from '@app/servings/item/debug/debug.component';
import {ServingsItemFilesComponent} from '@app/servings/item/files/files.component';
import {ServingsItemPersonsComponent} from './item/persons/persons.component';
import { ServinsItemDashboardComponent} from './item/dashboard/dashboard.component';
import {ServingsItemHeatmapComponent} from '@app/servings/item/heatmap/heatmap.component';
import {ServingsItemHeatmapPageComponent} from '@app/servings/item/heatmap-page/heatmap-page.component';
import {ServingsItemSourceComponent} from '@app/servings/item/source/source.component';

export const routes: Routes = [
    {
        path: '',
        component: ServingsComponent,
    },
    // {
    //     path: 'create',
    //     component: ServingsItemComponent,
    // },
    {
        path: ':itemId',
        component: ServingsItemComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard'
            },
            {
                path: 'form',
                component: ServingsItemFormComponent
            },
            {
                data: {
                    parent: {type: 'serving', param: 'itemId'}
                },
                path: 'dashboard',
                component: ServinsItemDashboardComponent
                // component: DashboardsComponent
            },
            {
                path: 'streams',
                component: ServingsItemStreamsComponent
            },
            {
                path: 'log',
                component: ServingsItemLogComponent
            },
            {
                path: 'report',
                component: ServingsItemPersonsComponent
                // component: ServinsItemDebugComponent
            },
            // {
            //     path: 'persons',
            //     component: ServingsItemPersonsComponent
            // },
            {
                path: 'files',
                component: ServingsItemFilesComponent
            },
            {
                path: 'heatmap',
                data: {type: 'heatmap'},
                component: ServingsItemHeatmapPageComponent
            },
            {
                path: 'source',
                data: {type: 'source'},
                component: ServingsItemSourceComponent
            },
            {
                path: 'trackmap',
                data: {type: 'trackmap'},
                component: ServingsItemHeatmapPageComponent
                // component: ServingsItemSourceComponent
            }
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes)],
    exports: [ RouterModule ]
})
export class AppServingsRoutingModule {}
