import {Model} from '@app/_models/model';
import {PaginateRequest} from '@app/_models/paginate-request';
import {Subject} from 'rxjs';
import * as _ from 'underscore';
import {FormBuilder, Validators} from '@angular/forms';

export class ProjectionPoint {
    x: number;
    y: number;
    obj: any;

    constructor(data: any = {}) {
        this.update(data);
    }

    update(data: any = {}) {
        this.x = data.x ? data.x : 0;
        this.y = data.y ? data.y : 0;
    }

    toJSON() {
        return {
            x: this.x,
            y: this.y
        }
    }
}

export class ProjectionPoints {
    _source: ProjectionPoint;
    _target: ProjectionPoint;
    active: boolean;

    constructor(data: any = {}){
        this.update(data);
    }

    update(data: any = {}) {
        this.source = data.source;
        this.target = data.target;
    }

    set source(data) {
        if(!this._source) {
            this._source = new ProjectionPoint(data);
        } else {
            this._source.update(data);
        }
    }

    get source() {
        return this._source;
    }

    set target(data) {
        if(!this._target) {
            this._target = new ProjectionPoint(data);
        } else {
            this._target.update(data);
        }
    }

    get target() {
        return this._target;
    }

    toJSON() {
        return {
            source: this.source,
            target: this.target
        }
    }
}

export class ProjectionModel extends Model  {
    id: number;
    description: string;
    projections: ProjectionPoints[] = [];
    source: any;
    target: any;

    display_name: string;

    sourceUrl: any;
    targetUrl: any;

    hasChange = false;
    public extAction = new Subject();

    constructor(data: any = {}, list?) {
        super(data);
        this.update(data);
        // if(data.projections) {
        //     _.each(data.projections, v => this.addPoints(v), true);
        // }
    }

    update(data: any = {}) {
        super.update(data);
        if(data.projections) {
            this.projections = [];
            _.each(data.projections, v => this.addPoints(v, true));
        }
        if(!this.display_name && this.id) {
            this.display_name = this.id.toString();
        }
        if(this.extAction) {
            this.extAction.next({action: 'update'});
        }
        if(this.asEdit){
            delete this._form;
            this.form;
        }
    }

    addPoints(data: any = {}, start?: boolean) {
        this.projections.push(new ProjectionPoints(data));
        if(!start) {
            this.extAction.next({action: 'addPint', data: {index: this.projections.length - 1}});
            this.hasChange = true;
        }
    }

    // get Name() {
    //     return this.id;
    // }

    removePoints(i: number) {
        this.projections.splice(i,1);
    }

    formConfig() {
        return {
            display_name: [this.display_name],
            description: [this.description],
            source: [null],
            target: [null],
        };
    }

    toJSON(): any {
        return {
            id: this.id,
            display_name: this.display_name,
            description: this.description,
            projections: this.projections.map( v => { return v.toJSON(); }),
            // source: this.source,
            // target: this.target
        }
    }

    toJsonCreate() {
        return {
            display_name: this.display_name,
            description: this.description,
            source: this.source,
            target: this.target
        }
    }

    setUrl(field, data, start?) {
        this[field + 'Url'] = data;
        this.extAction.next({ action: 'changeImage', data: {place: field}});
        if (!start) {
            this.hasChange = true;
        }
    }

    get canSave(): boolean {
        if(this.hasChange && this.sourceUrl && this.targetUrl) {
            return true;
        }
        return false;
    }

    setActive(i) {
        _.each(this.projections, (v, k) => {
            v.active = false;
            if (k == i) {
                v.active = true;

            }
        });
        this.extAction.next(
            {action: 'setActive', data: {index: i}}
        );
    }
}


export class ProjectionModelList extends PaginateRequest<ProjectionModel> {
    public extAction = new Subject();

    // constructor(data?) {
    //     debugger
    //     super(data);
    // }

    set items(data) {
        // debugger;
        this._items = [];
        _.each(data, v => { this._items.push(new ProjectionModel(v, this)); })
        // this.items = data.map( v => {
        //     debugger
        //     return new ProjectionModel(v, this);
        // });
    }

    get items() {
        return this._items;
    }
}
