export * from './video-file/classes';
export * from './video-file/timeline';

export class VideoFile {
  id: string;
  name: string;
  length: number;
  size: number;
  hasIndex: boolean;
}
