import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Model} from '@app/_models/model';
import * as _ from 'underscore';

class StreamParams extends Model {
    description: string;
    input: string;
    key: string;
    output: string;
    params: any;

    constructor( data? ) {
        super( data || {description: '', input : 'server',  key: 'live', output: 'null'});
        if (data) {
            _.each(data, (v, k) => {
                this._setVal(k, v);
            });
        }
    }

    formConfig() {
        return {
            description: [this.description],
            input: [this.input],
            key: [this.key, Validators.required],
            output: [this.output],
            params: new FormBuilder().array(this.initFormItem('params'))
        };
    }

    toJSON(): any {
        const params = {};
        if ( _.isArray(this.params ) ) {
            _.each(this.params, (k) => {
                if ( k.label !== '') {
                    params[k.label] = k.value;
                }
            });
        }

        return {
            description: this.description,
            input: this.input,
            key: this.key,
            output: this.output,
            params: params
        };
    }
}

export class Stream extends Model {
    id: string;
    title: string;
    meta: any;
    stream_params: StreamParams[];
    model_params: any;
    created: string;
    isNew: boolean;
    stream_type = 'cloud';

    constructor(data?) {
        super(data);
        if (data) {
            _.each(data, (v, k) => {
                this._setVal(k, v);
            });
        }
        if ( !this.stream_params ) {
            this.stream_params = [this.set_stream_params()];
        }
    }

    set_stream_params (val?): any {

        val = val ? val : {description: '', input : 'rtmp://127.0.0.1/live/live',  key: 'live', output: 'null'};
        return new StreamParams(val);

    }

    formConfig() {
        return {
            stream_type: [this.stream_type, Validators.required],
            title: [this.title, Validators.required],
            stream_params: new FormBuilder().array(this.stream_params.map(i => i.getForm() )),
            model_params: new FormBuilder().array(this.initFormItem('model_params'))
        };
    }

    // initFormParamc(item) {
    //     let a = [];
    //     for ( let i in this[item]) {
    //         a.push(this.createItem(i, this[item][i]));
    //     }
    //     // a.push(this.createItem());
    //     return a;
    // }

    toJSON() {
        return {
            id: this.id,
            title: this.title,
            stream_params: this.stream_params.map( i => i.toJSON()),
            stream_type: this.stream_type,
            model_params: this.model_params,
        };
    }

}
