import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserModel, PaginateRequest} from '@app/_models';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    apiUrl =  (window as any).externalConfig.apiUrl;
    pref = 'users';

    constructor( private http: HttpClient ) {}

    getUrl(params?: string ) {
        return `${this.apiUrl}${this.pref}` + ( params ? `/${params}` : '');
    }

    getAll(params?) {
        var p = this.httpParams(params);
        var gp:any ;

        if ( p ) {
            gp = {params: p};
        }
        return this.http.get<PaginateRequest<UserModel>>(this.getUrl(), gp);
    }

    getById(id: string) {
        return this.http.get<UserModel>(this.getUrl(`${id}`));
    }

    delete(id: string ) {
        return this.http.delete(this.getUrl(`${id}`));
    }

    save(item: UserModel) {
        if ( !item.isNew ) {
            return this.http.put<UserModel>(this.getUrl(`${item.login}`), item.toJSON());
        } else  {
            return this.http.post<UserModel>(this.getUrl(), item.toJSON());
        }
    }

    httpParams(p?: any) {
        if ( p ) {
            let params = new HttpParams();
            for ( let i in p ){
                params = params.append(i, p[i]);
            }
            return params;
        }

        return null;
    }
}
